import { Component, OnInit } from "@angular/core";
import { ISolution } from "src/app/models/solution.model";
import { SolutionsOfferedConst } from "../../sibasi/solutions-offered/solutions-offered.const";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    solutionsOffered: ISolution[] = SolutionsOfferedConst;

    constructor() {}

    ngOnInit(): void {}
}

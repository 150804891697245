import { BrowserModule, Meta, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AboutUsComponent } from "./components/sibasi/about-us/about-us.component";
import { ContactUsComponent } from "./components/sibasi/contact-us/contact-us.component";
import { HomeComponent } from "./components/sibasi/home/home.component";
import { LicenseAgreementComponent } from "./components/sibasi/license-agreement/license-agreement.component";
import { PartnersComponent } from "./components/sibasi/partners/partners.component";
import { PrivacyPolicyComponent } from "./components/sibasi/privacy-policy/privacy-policy.component";
import { ServicesOfferedComponent } from "./components/sibasi/services-offered/services-offered.component";
import { SolutionsOfferedDetailsComponent } from "./components/sibasi/solutions-offered-details/solutions-offered-details.component";
import { SolutionsOfferedComponent } from "./components/sibasi/solutions-offered/solutions-offered.component";
import { DataAnalyticsComponent } from "./components/sibasi/solutions/data-analytics/data-analytics.component";
import { DynamicsComponent } from "./components/sibasi/solutions/dynamics/dynamics.component";
import { MonivalComponent } from "./components/sibasi/solutions/monival/monival.component";
import { SharepointComponent } from "./components/sibasi/solutions/sharepoint/sharepoint.component";
import { WebAndMobileDevelopmentComponent } from "./components/sibasi/solutions/web-and-mobile-development/web-and-mobile-development.component";
import { SuccessComponent } from "./components/sibasi/success/success.component";
import { TermsAndConditionsComponent } from "./components/sibasi/terms-and-conditions/terms-and-conditions.component";
import { CalltoactionComponent } from "./components/layouts/call-to-action/calltoaction/calltoaction.component";
import { BigdataComponent } from "./components/layouts/tabs/bigdata/bigdata.component";
import { DeploymentComponent } from "./components/layouts/tabs/deployment/deployment.component";
import { DevelopmentComponent } from "./components/layouts/tabs/development/development.component";
import { TechconsultingComponent } from "./components/layouts/tabs/techconsulting/techconsulting.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { NavbarComponent } from "./components/layouts/navbar/navbar.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { SubscribeComponent } from "./components/layouts/subscribe/subscribe.component";
import { PortfolioComponent } from "./components/sibasi/portfolio/portfolio.component";
import { PortfolioDetailsComponent } from "./components/sibasi/portfolio-details/portfolio-details.component";
import { ContactFormComponent } from "./components/sibasi/contact-form/contact-form.component";
import { SolutionsFaqsComponent } from "./components/sibasi/solutions/solutions-faqs/solutions-faqs.component";

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        FooterComponent,
        SubscribeComponent,
        HomeComponent,
        AboutUsComponent,
        LicenseAgreementComponent,
        PrivacyPolicyComponent,
        ContactUsComponent,
        TermsAndConditionsComponent,
        ServicesOfferedComponent,
        SolutionsOfferedComponent,
        DataAnalyticsComponent,
        MonivalComponent,
        WebAndMobileDevelopmentComponent,
        BigdataComponent,
        DevelopmentComponent,
        CalltoactionComponent,
        DeploymentComponent,
        TechconsultingComponent,
        SuccessComponent,
        SharepointComponent,
        DynamicsComponent,
        PartnersComponent,
        SolutionsOfferedDetailsComponent,
        PortfolioComponent,
        PortfolioDetailsComponent,
        SolutionsFaqsComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        ContactFormComponent,
    ],
    providers: [Title, Meta],
    bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ContactService } from "../../../services/contact.service";
import { MetaTagService } from "../../../services/meta-tag.service";

@Component({
    selector: "app-contact-us",
    templateUrl: "./contact-us.component.html",
    styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
    subscribeForm: FormGroup;
    msg: number;
    showmsg: boolean;
    subMessage: string;
    private metaTagService = inject(MetaTagService);

    constructor(private contactService: ContactService) {}

    ngOnInit(): void {
        this.subscribeForm = new FormGroup({
            email: new FormControl(null, [
                Validators.required,
                Validators.email,
            ]),
        });
        this.updatePageMetaTags();
    }

    onSubmit() {
        var email = {
            email: this.subscribeForm.get("email").value,
        };
        this.contactService.subscribeEmail(email).subscribe(
            (res) => {
                this.showmsg = true;
                this.msg = 1;
                this.subMessage = "Your message was sent successfully";
            },
            (err) => {
                this.showmsg = true;
                this.msg = 2;
                this.subMessage =
                    "Sorry there seems to be an error on our end. You can send us your message at info@sibasi.ltd";
            },
            () => {}
        );
    }

    updatePageMetaTags(): void {
        this.metaTagService.updateMetaTags(
            "Contact Us - Get in Touch with Sibasi",
            "Reach out to Sibasi for any inquiries, support, or partnership opportunities. Our contact page provides all the information you need to connect with our team and discuss your business needs.",
            "contact, get in touch, Sibasi, support, partnership opportunities, business inquiries, contact information"
        );
    }
}

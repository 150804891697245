<div class=""> 
    <div class=""> 
        <h2 class="heading"> Deployment Experts </h2> 
        <p>We are experts at deployment and customization of a number of solutions 
            such as Monival, Office365, SharePoint and OpenEdx 
        </p>
     </div> 
        <div class="row mt-3" style="
        text-align: center;
        "> 
            <div class="col-sm-4 col-md-6"> 
                <img  alt="Node Js" class="ml-2" style="height: 56px;" src="assets/img/Logo_Microsoft_Office_365.svg">
                <img  alt="Visual studio" class="ml-2" style="height: 64px;" src="assets/img/Microsoft_Office_SharePoint_(2018–present).svg">
                <img  alt="Visual studio" class="ml-2" style="height: 64px;" src="assets/img/EdX_Logo.png">

            </div> 
             <!-- <div class="ml-2 col-sm-4 col-md-6"> 
                <img  alt="Android" class="" style="width:64px;height: 64px;" src="assets/img/android-seeklogo.com.svg">
                <img  alt="swift" class="ml-2" style="height: 64px;" src="assets/img/Apple_logo_black.svg">
                <img  alt="python" class="ml-2" style="width:64px;height: 64px;" src="assets/img/Python-logo-notext.svg">
          

            </div> -->
             
        </div> 

 
</div>

import { IService } from "src/app/models/service.model";

export const ServicesOfferedConst: IService[] = [
    {
        title: "The #1 System Integrator",
        description:
            "Sibasi is well-known for delivering premier System Integration services to businesses of all sizes. We have a proven track record of integrating diverse software systems, applications, and platforms, enabling smooth data flow and communication between different departments. Our skilled team meticulously plans and executes the integration process, minimizing disruptions and ensuring data integrity. With our top-notch System Integration solutions, you can streamline processes, improve data accuracy, and make data-driven decisions with confidence.",
    },
    {
        title: "Customization & Implementation",
        description:
            "At Sibasi, we specialize in providing comprehensive customization and implementation services to tailor solutions to your organization's unique needs. Our team of experts works closely with you to understand your requirements and configure the software to align perfectly with your workflows. From software development and integration to system deployment and training, we ensure a seamless and efficient implementation process. With our customization services, you can optimize productivity, enhance user experience, and maximize the value of your software investments.",
    },
    {
        title: "Intranet, EDMS & Business Process Automation (Sharepoint)",
        description:
            "Unlock the full potential of collaboration and communication within your organization with Sibasi's Intranet (SharePoint) solution. SharePoint offers a secure and centralized platform for employees to access information, documents, and resources, fostering a culture of knowledge sharing and teamwork. Our experts design and implement SharePoint-based intranet solutions tailored to your organization's needs, ensuring smooth navigation, efficient content management, and personalized user experiences. With our Intranet services, boost employee engagement, streamline internal communication, and increase overall productivity.",
    },
    {
        title: "Open edX®",
        description:
            "As a leading provider of educational technology solutions, Sibasi offers Open edX® services that enable organizations to create and deliver high-quality online learning experiences. Open edX® is a powerful open-source platform that empowers educational institutions, corporations, and non-profits to build and deliver engaging courses to learners worldwide. Our team of experts assists you in setting up, customizing, and hosting your online learning platform, ensuring seamless integration with your branding and requirements. With Open edX®, expand your reach, enhance learner engagement, and transform education through innovative online learning.",
    },
    {
        title: "Licenses for Microsoft and Other Providers",
        description:
            "Access a wide range of licenses for Microsoft and other providers to meet your software and service needs. Whether you require software licenses, cloud services, or other solutions, we offer flexible licensing options tailored to your organization's requirements.",
    },
    {
        title: "Training through Sibasi Academy",
        description:
            "Enhance your skills and knowledge with training opportunities available through Sibasi Academy. We offer training programs for both corporations and students. Book your training sessions here to gain valuable insights and expertise in various fields.",
    },
    {
        title: "Migration Services",
        description:
            "Ensure a smooth transition of your IT infrastructure with our Migration Services. Whether you're migrating email to Office 365, moving to the cloud, or architecting new environments, we've got you covered. Our experienced team will guide you through the migration process, minimizing downtime and ensuring data integrity. We handle the complexities so you can focus on your business. Trust our experts to make your migration journey seamless and hassle-free.",
    },
    {
        title: "Robotic Process Automation (RPA)",
        description:
            "Automate repetitive and time-consuming tasks with our Robotic Process Automation (RPA) solutions. Our platform empowers you to design, deploy, and manage software robots that can handle a wide range of tasks. Increase efficiency, reduce errors, and free up your workforce to focus on more valuable activities. With RPA, you'll supercharge your business processes. Experience the benefits of enhanced productivity and automation with our RPA solutions.",
    },
    {
        title: "Data Warehousing",
        description:
            "Build a robust data warehousing solution with our Data Warehousing services. We offer expertise in designing data warehouses, performing ETL (Extract, Transform, Load) processes, and utilizing SSAS (SQL Server Analysis Services) for powerful data analysis. Centralize and optimize your data for advanced analytics and reporting. Our Data Warehousing services provide you with the foundation for data-driven decision-making. Experience the benefits of a well-structured and efficient data warehousing solution.",
    },
    {
        title: "Integrated Management System (IMS)",
        description:
            "Streamline your organization's processes with our Integrated Management System (IMS). Our platform offers a unified approach to managing quality, environment, health, safety, and other aspects of your business. Efficiently manage compliance, audits, and documentation. Our IMS solution enhances your organization's overall performance and simplifies complex management tasks. Experience the benefits of an integrated approach to management with our IMS solution.",
    },
    {
        title: "Data Analysis & Business Reporting",
        description:
            "Empower your organization with data-driven decision-making using our Business Intelligence & Reporting solutions. We help you collect, analyze, and visualize data, transforming it into actionable insights. Our reporting tools provide you with comprehensive dashboards and reports that enhance your understanding of business performance. Make informed decisions, identify trends, and drive growth. Collaborate with our experts to design custom BI solutions tailored to your unique needs. Harness the power of data and stay ahead of the competition with our BI & Reporting services.",
    },
    {
        title: "Artificial Intelligence",
        description:
            "Leverage the potential of data and artificial intelligence to drive innovation and gain a competitive edge with our Data & Artificial Intelligence solutions. Our experts combine cutting-edge AI technologies with advanced data analytics to unlock valuable insights from your data. From predictive analytics to machine learning, we offer tailored solutions to meet your business needs. Harness the power of AI to automate tasks, improve decision-making, and optimize operations. Explore the endless possibilities of data-driven AI with our services.",
    },
    {
        title: "Software Architect & DevOps",
        description:
            "Transform your software development and IT operations with our Software Architect & DevOps solutions. We offer a holistic approach to software architecture, design, and deployment. Our DevOps practices streamline your software development lifecycle, enabling faster releases and improved quality. Benefit from architectural best practices, automation, and cloud-native solutions to drive efficiency and scalability. Our experts collaborate with your team to architect, build, and maintain robust software systems. Embrace agile development and DevOps culture with our comprehensive services.",
    },
    {
        title: "Project Management",
        description:
            "Elevate your project management capabilities with our Project Management solutions. We provide the tools, methodologies, and expertise to ensure successful project delivery. From project planning and resource allocation to risk management and reporting, we cover every aspect of project management. Our solutions are designed to improve collaboration, enhance transparency, and optimize project outcomes. Empower your teams to work more efficiently, meet deadlines, and achieve project goals. Experience seamless project execution with our comprehensive Project Management services.",
    },
];

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutUsComponent } from "./components/sibasi/about-us/about-us.component";
import { ContactUsComponent } from "./components/sibasi/contact-us/contact-us.component";
import { HomeComponent } from "./components/sibasi/home/home.component";
import { PartnersComponent } from "./components/sibasi/partners/partners.component";
import { PrivacyPolicyComponent } from "./components/sibasi/privacy-policy/privacy-policy.component";
import { ServicesOfferedComponent } from "./components/sibasi/services-offered/services-offered.component";
import { SolutionsOfferedDetailsComponent } from "./components/sibasi/solutions-offered-details/solutions-offered-details.component";
import { SolutionsOfferedComponent } from "./components/sibasi/solutions-offered/solutions-offered.component";
import { DataAnalyticsComponent } from "./components/sibasi/solutions/data-analytics/data-analytics.component";
import { DynamicsComponent } from "./components/sibasi/solutions/dynamics/dynamics.component";
import { MonivalComponent } from "./components/sibasi/solutions/monival/monival.component";
import { PowerPlatformComponent } from "./components/sibasi/solutions/power-platform/power-platform.component";
import { SharepointComponent } from "./components/sibasi/solutions/sharepoint/sharepoint.component";
import { WebAndMobileDevelopmentComponent } from "./components/sibasi/solutions/web-and-mobile-development/web-and-mobile-development.component";
import { PortfolioComponent } from "./components/sibasi/portfolio/portfolio.component";
import { PortfolioDetailsComponent } from "./components/sibasi/portfolio-details/portfolio-details.component";
import { SuccessComponent } from "./components/sibasi/success/success.component";
import { SolutionsFaqsComponent } from "./components/sibasi/solutions/solutions-faqs/solutions-faqs.component";
import { TermsAndConditionsComponent } from "./components/sibasi/terms-and-conditions/terms-and-conditions.component";

const routes: Routes = [
    { path: "", component: HomeComponent },
    { path: "about", component: AboutUsComponent },
    { path: "contact", component: ContactUsComponent },
    { path: "privacy-policy", component: PrivacyPolicyComponent },
    { path: "terms-and-conditions", component: TermsAndConditionsComponent },
    { path: "services-offered", component: ServicesOfferedComponent },
    { path: "solutions-offered", component: SolutionsOfferedComponent },
    { path: "solutions-faqs/:portfolioId", component: SolutionsFaqsComponent },
    { path: "portfolio", component: PortfolioComponent },
    {
        path: "portfolio-details/:portfolioId",
        component: PortfolioDetailsComponent,
    },
    {
        path: "solution-details/:solutionId",
        component: SolutionsOfferedDetailsComponent,
    },
    {
        path: "licenses",
        component: SolutionsOfferedDetailsComponent,
    },
    { path: "success", component: SuccessComponent },
    { path: "partners", component: PartnersComponent },
    { path: "sharepoint", component: SharepointComponent },
    {
        path: "web-and-mobile-development",
        component: WebAndMobileDevelopmentComponent,
    },
    { path: "power-platform", component: PowerPlatformComponent },
    { path: "data-analytics", component: DataAnalyticsComponent },
    { path: "dynamics", component: DynamicsComponent },
    { path: "monival", component: MonivalComponent },
    { path: "about-us-sibasi-ltd", component: AboutUsComponent },
    { path: "services-sibasi-ltd", component: ServicesOfferedComponent },
    { path: "**", redirectTo: "" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule],
})
export class AppRoutingModule { }

import { IPortfolioFaqs } from "src/app/models/portfolio.model";

export const PortfolioFaqs: IPortfolioFaqs[] = [

    // solutionName: "Sibasi Human Resorce Management Application ",
    {
        groupName: "General",
        questions: [
            {
                question: "How does Sibasi Human Resorce Management Application benefit our organization?",
                answer: " This App leverages Microsoft Power BI to translate Human Resource data into actionable insights. This solution empowers organizations to make informed decisions, optimize workforce strategies, and enhance employee engagement by offering data-driven guidance on workforce management and retention."
            },
            {
                question: "How can we get started with Sibasi Human Resorce Management Application?",
                answer: "To get started , simply reach out to our team for a personalized demo and consultation."
            },

            {
                question: "What are the integration options with data systems?",
                answer: "The app connects to a range of systems and consolidates data from multiple sources. This enables you to have a comprehensive view of your HR data in one centralized dashboard, making it easier to analyze and derive meaningful insights. These sources include but not limited to; 1. Your existing HR systems, such as Oracle, or SAP among others 2. Database connection, if you store data in a database or data warehouse. 2. Flat files e.g., CSV/Excel files with our pre-defined templates, 3. API integration with other software e.g., Dynamics 365, Navision e.t.c."
            },
            {
                question: "How easy it is to do the data integration?",
                answer: "We can provide consulting services to get your data connected to your source system(s), customize the reports, and automate the process in a little as 1-2 weeks."
            },
            {
                question: "How much does Sibasi Human Resorce Management Application cost?",
                answer: " We offer a one-off cost of $1500 -$2000 for full customization and integration of the App into you system and automation of data refresh. If you require ongoing support, maintenance, and enhancements, a monthly fee can be discussed for a support agreement."
            },
            {
                question: "What Power BI license do we need?",
                answer: "You can get started by signing up on our website and following the onboarding guide."
            },
            {
                question: "What do I need to provide to receive the quote for the implementation?",
                answer: "You can contact us. We will identify your data sources, your desired ojectives and outputs ,data refresh frequency preference and we will advice whether you require new report creation or customization of existing dashboards."
            }
        ]
    }
];

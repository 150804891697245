<div
    class="page-title-area item-bg2"
    style="background-image: url(assets/img/data-banner.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Data Analytics</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <a routerLink="/solutions-offered">Solutions</a>
                        </li>
                        <li>Data Analytics</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="image-sliders owl-carousel owl-theme">
                    <div class="project-details-image">
                        <img
                            src="assets/img/power-bi-vector-logo.svg"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">
                    <h3>Data Analytics</h3>
                    <p>
                        Data has become the lifeblood of modern work, allowing
                        organizations to make decisions based on facts rather
                        than hunches, leading to better decisions.
                    </p>
                    <p>
                        At Sibasi we help you make data driven decisions by
                        using modern and sophisticated tools to analyse your
                        data such as Microsoft Power BI.
                    </p>
                    <div class="features-text">
                        <h3>Microsoft Power BI</h3>
                        <p>
                            Power BI is a specialized, easy to use business
                            analytics platform that enables:
                        </p>
                        <ul class="about-list">
                            <li>
                                <i class="flaticon-tick"></i> Fast and easy
                                access to data
                            </li>
                            <li>
                                <i class="flaticon-tick"></i> A live 360º view
                                of the business​
                            </li>
                            <li>
                                <i class="flaticon-tick"></i> Data discovery and
                                exploration​
                            </li>
                            <li>
                                <i class="flaticon-tick"></i> Insights from any
                                device​
                            </li>
                            <li>
                                <i class="flaticon-tick"></i> Collaboration
                                across the organization​
                            </li>
                            <li>
                                <i class="flaticon-tick"></i> Anyone to
                                visualize and analyze data​
                            </li>
                        </ul>
                        <h4>Create</h4>
                        <p>Create interactive reports​ in Power BI Desktop​</p>
                        <h4>Publish</h4>
                        <p>Publish to Power BI Server​</p>
                        <h4>Consume</h4>
                        <p>
                            View and interact in​ Power BI Mobile or web
                            browser​
                        </p>
                    </div>

                    <div class="banner-btn" style="text-align: center">
                        <a class="default-btn" routerLink="/contact"
                            >Request Demo<span></span
                        ></a>
                    </div>
                    <div class="row my-4 solutions-offered">
                        <h3>Solutions built using this technology</h3>
                        <div class="row">
                            <div class="col-auto">
                                <a routerLink="/solution-details/bi_reporting"
                                    >Business intelligence and reporting</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

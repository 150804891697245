import { Component, HostListener, inject } from "@angular/core";
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationStart,
    Router,
} from "@angular/router";
import { Subscription } from "rxjs";
import { Portfolios } from "../portfolio/portfolio.const";
import { IPortfolio } from "src/app/models/portfolio.model";
import { MetaTagService } from "src/app/services/meta-tag.service";

@Component({
    selector: "app-portfolio-details",
    templateUrl: "./portfolio-details.component.html",
    styleUrls: ["./portfolio-details.component.scss"],
})
export class PortfolioDetailsComponent {
    sections = [
        {
            title: "Overview",
            sectionId: "overview",
        },
        {
            title: "Impact",
            sectionId: "economicImpact",
        },
        {
            title: "Pricing",
            sectionId: "pricing",
        },
        {
            title: "Partners",
            sectionId: "partners",
        },
        {
            title: "Testimonials",
            sectionId: "testimonials",
        },
        {
            title: "Resources",
            sectionId: "resources",
        },
        {
            title: "Next Steps",
            sectionId: "nextSteps",
        },
    ];
    selectedOverviewCategory!: any;
    subscriptions = new Subscription();
    portfolio!: IPortfolio;
    portfolios: IPortfolio[] = Portfolios;
    selectedOverViewSubCategory: {
        id: string;
        title: string;
        description: string;
        image: string;
        link?: string;
    } | null = null;

    activeSection: string | null = null;

    @HostListener("window:scroll", ["$event"])
    private currentScrollPosition: { x: number; y: number } = { x: 0, y: 0 };

    onScroll(): void {
        this.updateActiveSection();
    }

    private metaTagService = inject(MetaTagService);
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.getPortfolioIdFromParam();
        this.preventScroll();
    }

    getPortfolioIdFromParam(): void {
        this.subscriptions.add(
            this.activatedRoute.params.subscribe({
                next: (params) => {
                    const portfolioId = params.portfolioId;
                    this.getPortfolio(portfolioId);
                    this.getPortfolioCategoryFromParam();
                },
            })
        );
    }

    preventScroll(): void {
        this.router.events.subscribe((event) => {
            if (
                event instanceof NavigationStart &&
                history.state.preserveScroll
            ) {
                this.currentScrollPosition = {
                    x: window.scrollX,
                    y: window.scrollY,
                };
            }
            if (
                event instanceof NavigationEnd &&
                history.state.preserveScroll
            ) {
                setTimeout(() => {
                    window.scrollTo(
                        this.currentScrollPosition.x,
                        this.currentScrollPosition.y
                    );
                });
            }
        });
    }
    getPortfolioCategoryFromParam(): void {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            const categoryId = params.get("category");
            if (categoryId) {
                this.selectedOverviewCategory =
                    this.portfolio.overview.categories.find(
                        (x) => x.id === categoryId
                    );
                if (this.selectedOverviewCategory) {
                    const initialSelectedSubCategory =
                        this.selectedOverviewCategory.subCategory[0];
                    this.setSelectedSubCategory(initialSelectedSubCategory.id);
                }
            }
        });
    }

    getPortfolio(portfolioId: string): void {
        this.portfolio = this.portfolios.find((x) => x.id === portfolioId);
        this.updatePageMetaTags(this.portfolio);

        const categoryId =
            this.activatedRoute.snapshot.queryParamMap.get("category");
        if (categoryId) {
            this.selectedOverviewCategory =
                this.portfolio.overview.categories.find(
                    (x) => x.id === categoryId
                );
        } else {
            const initialSelectedCategory =
                this.portfolio.overview.categories[0];
            this.setSelectedCategory(initialSelectedCategory.id);
        }
    }

    setSelectedCategory(categoryId: string): void {
        this.selectedOverviewCategory = this.portfolio.overview.categories.find(
            (x) => x.id === categoryId
        );
        const initialSelectedSubCategory =
            this.selectedOverviewCategory.subCategory[0];
        this.setSelectedSubCategory(initialSelectedSubCategory.id);

        this.router.navigate([], {
            queryParams: { category: categoryId },
            queryParamsHandling: "merge",
            state: { preserveScroll: true },
        });
    }

    setSelectedSubCategory(subCategoryId: string): void {
        this.selectedOverViewSubCategory =
            this.selectedOverviewCategory.subCategory.find(
                (x) => x.id === subCategoryId
            );
    }

    scrollToSection(sectionId: string): void {
        const offset: number = 87;
        const section = document.getElementById(sectionId);
        if (section) {
            const offsetTop =
                section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({
                top: offsetTop - offset,
                behavior: "smooth",
            });
        }
    }

    updateActiveSection(): void {
        for (const section of this.sections) {
            const viewPortSection = document.getElementById(section.sectionId);
            if (viewPortSection) {
                const rect = viewPortSection.getBoundingClientRect();
                const viewportHeight =
                    window.innerHeight || document.documentElement.clientHeight;
                const halfwayPoint = viewportHeight / 2;

                if (rect.top <= halfwayPoint && rect.bottom >= halfwayPoint) {
                    this.activeSection = section.sectionId;
                    return;
                }
            }
        }
        this.activeSection = null;
    }

    checkIfSectionIsProvided(sectionId: string): boolean {
        const availableSections = Object.keys(this.portfolio);
        return availableSections.find((x) => x === sectionId) ? true : false;
    }

    openExternalLink(link: string): void {
        window.open(link, "_blank");
    }

    updatePageMetaTags(portfolio: IPortfolio): void {
        this.metaTagService.updateMetaTags(
            `Sibasi Portfolio - ${portfolio.name}`,
            portfolio.shortDescription,
            portfolio.filters.toString()
        );
    }
}

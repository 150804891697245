import { IPortfolio } from "src/app/models/portfolio.model";

export const Portfolios: IPortfolio[] = [
    {
        id: "shapointedms",
        name: "SharePoint DMS",
        image: "dms.jfif",
        shortDescription:
            "Streamline document management, enhances collaboration, and improves information sharing within your organization",
        categories: [
            "Document storage",
            "Version control",
            "Search capabilities",
            "Document versioning and collaboration",
        ],
        filters: ["Microsoft", "SharePoint"],
        overview: {
            title: "Key Features",
            description:
                "Simplify document handling and boost productivity with SharePoint DMS",
            categories: [
                {
                    id: "dmsfeatures",
                    title: "Features",
                    subCategory: [
                        {
                            id: "centralisedRepository",
                            title: "Centralised Repository",
                            description:
                                "Store all your documents in one secure location accessible to your entire team",
                            image: "portfolio_sharepointdms2.png",
                            link: "",
                        },
                        {
                            id: "approvalSigning",
                            title: "Approval & Signing",
                            description:
                                "Integration to applications such as adobe sign and approvals app allows for ease of authorization and signoffs through electronic signatures",
                            image: "adobe-sign.png",
                            link: "",
                        },
                        {
                            id: "metadataContentTypes",
                            title: "SharePoint Metadata & Content Types",
                            description:
                                "Allows users to tag documents and records for easy retrieval.  ",
                            image: "metadata-edms.jpg",
                            link: "",
                        },
                        {
                            id: "workflows",
                            title: "Workflows",
                            description:
                                "Streamline and automate a wide variety of business processes.  ",
                            image: "Processing-automation.png",
                            link: "",
                        },
                        {
                            id: "versioning",
                            title: "Version control",
                            description:
                                "Keep track of changes in your documents.  ",
                            image: "Version-control.png",
                            link: "",
                        },
                    ],
                },
            ],
        },
        pricing: {
            title: "EDMS pricing",
            tiers: [
                {
                    title: "Standard",
                    amount: "$5,000",
                    period: "",
                    info: "Access essential EDMS features for efficient document storage and retrieval.",
                },
                {
                    title: "Premium",
                    amount: "$25,000",
                    period: "",
                    info: "Tailor a custom plan to match your specific document management and integration requirements.",
                },
            ],
        },
        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "fa-solid fas fa-comment-dots",
                    title: "Chat with a SharePoint expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
                {
                    icon: "fa-solid fas fa-bell",
                    title: "Request a call back",
                    description: "Have a SharePoint expert contact you",
                    link: "",
                },
                {
                    icon: "fa-solid fas fa-phone",
                    title: "Call a SharePoint expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },
    {
        id: "shapointintranet",
        name: "SharePoint Intranet",
        image: "landing-page.png",
        shortDescription:
            "Enhance communication, foster collaboration, and facilitate information sharing within your organization",
        categories: [
            "Document management",
            "Team collaboration",
            "Advanced search",
            "Communication sites",
        ],
        filters: ["Microsoft", "SharePoint"],
        overview: {
            title: "Key Features",
            description:
                "Centralize your organization's digital workspace with SharePoint Intranet",
            categories: [
                {
                    id: "teamCollaboration",
                    title: "Team collaboration",
                    subCategory: [
                        {
                            id: "announcement",
                            title: "Announcements and News",
                            description:
                                "Keeping employees informed about company news, updates, and important events",
                            image: "announcements.png",
                            link: "",
                        },
                        {
                            id: "calendarEvents",
                            title: "Calendar of Events",
                            description:
                                "Offers Information on event details, recurring events, reminders, and also integrates with Outlook or other calendar applications",
                            image: "calendar-of-events.png",
                            link: "",
                        },
                        {
                            id: "employeeInteraction",
                            title: "Interactivity through Forms, Polls and Chat",
                            description:
                                "Interaction among employees helps in fostering a positive work culture.",
                            image: "interactions.png",
                            link: "",
                        },
                        {
                            id: "birthdaysWorkAnniversary",
                            title: "Birthdays and Work Anniversary Wishes",
                            description:
                                "Acknowledgement of employee milestones helps in the fostering of a positive work culture.",
                            image: "birthdays.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "workflows",
                    title: "Workflows and Business Process Automation",
                    subCategory: [
                        {
                            id: "businessAutomation",
                            title: "Automate business processes",
                            description:
                                "Various business processes such as Leave Management can also be automated as part of the intranet",
                            image: "automation.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "onboarding",
                    title: "Onboarding",
                    subCategory: [
                        {
                            id: "employeeOnboarding",
                            title: "New employee onboarding",
                            description:
                                "Help New Employees get setup through an onboarding process on the Intranet",
                            image: "employee-onboarding.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "documentRepo",
                    title: "Document Repository",
                    subCategory: [
                        {
                            id: "documentManagement",
                            title: "Document Management",
                            description:
                                "Manage the life cycle of documents in your organization — how they are created, reviewed, and published, and how they are ultimately disposed of or retained.",
                            image: "document-repo.png",
                            link: "",
                        },
                    ],
                },
            ],
        },
        economicImpact: {
            title: "The ROI Benefits of Business-Critical SharePoint",
            description:
                "Discover the cost savings and business advantages enabled by integrating SharePoint into your operations.",
            impact: [
                {
                    value: "$200-$300K",
                    description: "Annual cost savings",
                },
                {
                    value: "50-80%",
                    description: "Reduction in cycle time",
                },
                {
                    value: "60%",
                    description: "Acceleration in deployment speed",
                },
            ],
        },
        pricing: {
            title: "SharePoint Intranet pricing",
            tiers: [
                {
                    title: "Basic",
                    amount: "$1,600",
                    period: "",
                    info: "Ideal for small and growing organizations seeking essential features to enhance internal communication and collaboration among team members",
                },
                {
                    title: "Standard",
                    amount: "$15,000",
                    period: "",
                    info: "Tailored solution designed to address the specific needs of large corporations and enterprises with intricate communication and collaboration requirements",
                },
                {
                    title: "Premium",
                    amount: "$65,000",
                    period: "",
                    info: "Comprehensive solution customized to meet the distinct needs of large corporations and enterprises with sophisticated communication and collaboration demands",
                },
            ],
        },
        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "fa-solid fas fa-comment-dots",
                    title: "Chat with a SharePoint expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
                {
                    icon: "fa-solid fas fa-bell",
                    title: "Request a call back",
                    description: "Have a SharePoint expert contact you",
                    link: "",
                },
                {
                    icon: "fa-solid fas fa-phone",
                    title: "Call a SharePoint expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },

    {
        id: "businesscentral",
        name: "Dynamics 365 Business Central",
        image: "Businesscentral.jpg",
        shortDescription:
            "Transform your business operations, streamline processes, and drive growth with Dynamics 365 Business Central",
        categories: [
            "ERP",
            "Cloud Computing",
            "Financial Management",
            "Supply Chain Management",
            "Customer Relationship Management",
        ],
        filters: ["Microsoft", "Business Central", "Dynamics 365"],
        overview: {
            title: "Empower your business",
            description:
                "Dynamics 365 Business Central offers a comprehensive solution for managing finances, operations, sales, and customer service.",
            categories: [
                {
                    id: "financialManagement",
                    title: "Financial Management",
                    subCategory: [
                        {
                            id: "automateAccounting",
                            title: "Automate accounting processes",
                            description:
                                "Simplify financial management tasks such as general ledger, accounts payable, and accounts receivable with automated workflows and real-time insights.",
                            image: "automate-accounting.jpg",
                            link: "",
                        },
                        {
                            id: "realTimeReporting",
                            title: "Real-time reporting",
                            description:
                                "Gain visibility into your financial performance with customizable dashboards, reports, and analytics that provide actionable insights.",
                            image: "real-time-reporting.jpg",
                            link: "",
                        },
                    ],
                },
                {
                    id: "operationsManagement",
                    title: "Operations Management",
                    subCategory: [
                        {
                            id: "streamlineInventory",
                            title: "Streamline inventory management",
                            description:
                                "Optimize inventory levels, track stock movements, and improve order fulfillment with efficient inventory management tools.",
                            image: "inventory-management.JPG",
                            link: "",
                        },
                        {
                            id: "automateProcurement",
                            title: "Automate procurement processes",
                            description:
                                "Streamline purchasing, vendor management, and procurement processes to reduce costs and improve supply chain efficiency.",
                            image: "vendor.jpg",
                            link: "",
                        },
                    ],
                },
                {
                    id: "customerService",
                    title: "Customer Service",
                    subCategory: [
                        {
                            id: "enhanceCustomerExperience",
                            title: "Enhance customer experience",
                            description:
                                "Deliver personalized service, resolve customer issues quickly, and build lasting relationships with comprehensive customer service tools.",
                            image: "customer.jpg",
                            link: "",
                        },
                        {
                            id: "increaseCustomerEngagement",
                            title: "Increase customer engagement",
                            description:
                                "Empower sales and service teams with a 360-degree view of customer interactions, preferences, and history for better engagement and retention.",
                            image: "customer-engagement.jpg",
                            link: "",
                        },
                    ],
                },
            ],
        },
        economicImpact: {
            title: "The Total Economic Impact™ Of Microsoft Dynamics 365 Business Central",
            description:
                "Learn about the cost savings and business benefits enabled by Dynamics 365 Business Central in this commissioned study.",
            impact: [
                {
                    value: "287%",
                    description: "Return on investment",
                },
                {
                    value: "$32.5M",
                    description: "Benefits present value",
                },
                {
                    value: "<12",
                    description: "Months to payback",
                },
            ],
        },
        pricing: {
            title: "Dynamics 365 Business Central pricing",
            tiers: [
                {
                    title: "Dynamics 365 Business Central Essentials",
                    amount: "$70",
                    period: "Per user/month",
                    info: "Comprehensive business management solution for small and medium-sized businesses",
                },
                {
                    title: "Dynamics 365 Business Central Premium",
                    amount: "$100",
                    period: "Per user/month",
                    info: "Extended functionality including service management and manufacturing",
                },
            ],
        },
        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "fa-solid fas fa-bell",
                    title: "Chat with a Dynamics 365 expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have a Dynamics 365 expert contact you",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call a Dynamics 365 expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },
    {
        id: "eBoard",
        name: "eBoard By Sibasi",
        image: "imgboard.png",
        shortDescription:
            "Unleash the power of efficient boardroom collaboration with our innovative eBoard management system.",
        categories: ["Meetings", "Agendas", "Document Management"],
        filters: ["eBoard"],
        overview: {
            title: "Modernizing Governance, Maximizing Board Impact",
            description:
                " It goes beyond simply replacing paper-based processes. It streamlines communication, collaboration, and decision-making within the boardroom and beyond.",
            categories: [
                {
                    id: "meetingManagement",
                    title: "Meeting Management",
                    subCategory: [
                        {
                            id: "calendar",
                            title: "Calendar View",
                            description:
                                "View upcoming meetings and past events in your Organization",
                            image: "dashboard.png",
                            link: "",
                        },
                        {
                            id: "basicDetails",
                            title: "Meeting Scheduling",
                            description:
                                "Setup your meeting by providing the basic details or relevant information related to it.",
                            image: "createmeeting.png",
                            link: "",
                        },
                        {
                            id: "overview",
                            title: "Meeting Room and Overview",
                            description:
                                "View and Edit the meeting details and publish the meeting after reviewing the room",
                            image: "meetingoverview.png",
                            link: "",
                        },
                        {
                            id: "agendaSetup",
                            title: "Agenda Setup",
                            description:
                                "Build detailed meeting agendas with ease, adding agenda items, supporting materials, and links to external resources.",
                            image: "minutes&agenda.png",
                            link: "",
                        },
                        {
                            id: "minuteSetup",
                            title: "Minute Records",
                            description:
                                "Draft minutes within the platform, capturing essential discussion points, decisions, and allows flexibility to link minutes to specific agenda items.",
                            image: "minutes&agenda.png",
                            link: "",
                        },
                        {
                            id: "roles",
                            title: "Meeting Permission Management",
                            description:
                                "Meeting collaborators and the owner can update roles of the participants executing high level access control.",
                            image: "Screenshot 2024-03-27 122207.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "taskManagement",
                    title: "Task Management",
                    subCategory: [
                        {
                            id: "taskTracking",
                            title: "View all meeting related tasks",
                            description:
                                "Assign tasks outside the meeting, enabling flexibility to assign tasks at any given moment and track and update progress giving detailed reports for easier scoping.",
                            image: "meetingtasks.png",
                            link: "",
                        },
                        {
                            id: "alltasks",
                            title: "View all  Collective Tasks",
                            description:
                                "Assign tasks outside the meeting, enabling flexibility to assign tasks at any given moment and track and update progress giving detailed reports for easier scoping.",
                            image: "alltasks.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "documentManagement",
                    title: "Document Management",
                    subCategory: [
                        {
                            id: "overviewDocs",
                            title: "Document Overview",
                            description:
                                "Upload and share documents of various formats (e.g., PDFs, Word docs, spreadsheets) directly into the platform.",
                            image: "files.png",
                            link: "",
                        },
                        {
                            id: "docPermissions",
                            title: "Document Access Management",
                            description:
                                "Share documents securely with specific board members or groups, granting different access levels as needed (view-only, edit).",
                            image: "filescards.png",
                            link: "",
                        },
                        {
                            id: "annotations",
                            title: "Annotations on Documents",
                            description:
                                "Utilize annotation tools like highlights, text boxes, sticky notes, and drawing tools to directly comment on documents.",
                            image: "annotations.png",
                            link: "",
                        },
                        {
                            id: "boardPacks",
                            title: "Board Pack Management",
                            description:
                                "Compact significant documents into one pack for ease of review",
                            image: "packs.png",
                            link: "",
                        },
                        {
                            id: "esigning",
                            title: "Submit Document for E-Signing",
                            description:
                                "Request for signatures from your members",
                            image: "esign.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "polls",
                    title: "Polls & Surveys",
                    subCategory: [
                        {
                            id: "viewpoll",
                            title: "Create Dynamic Polls & Vote",
                            description:
                                "Engage the team members by requesting them to take part in surveys and voice opinions by voting.",
                            image: "polls.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "reports",
                    title: "Business Reports",
                    subCategory: [
                        {
                            id: "viewpoll",
                            title: "Create & View Business Reports",
                            description:
                                "Generate comprehensive business reports to provide insights and support data-driven decision-making.",
                            image: "reports.png",
                            link: "",
                        },
                    ],
                },
            ],
        },
        // economicImpact: {
        //     title: "The Total Economic Impact™ Of Using eBoard",
        //     description:
        //         "Learn the cost savings and business benefits enabled by eBoard in this commissioned study.",
        //     impact: [
        //         {
        //             value: "346%",
        //             description: "Return on investment",
        //         },
        //         {
        //             value: "$42.65M",
        //             description: "Benefits present value",
        //         },
        //         {
        //             value: "<6",
        //             description: "Months to payback",
        //         },
        //     ],
        // },
        pricing: {
            title: "eBoard Pricing",
            tiers: [
                {
                    title: "Per Seat",
                    amount: "$18",
                    period: "/user/month",
                    info: "Perfect for startups, this plan includes essential collaboration tools, 1GB of storage, support, annual maintenance, and no setup costs—ideal for small teams starting affordably.",
                },
                {
                    title: "Per Organization",
                    amount: "$5,000",
                    period: "Per Year",
                    info: "Best for small to mid-sized teams, offering enhanced collaboration, advanced security, priority support, unlimited users, 50GB storage, and discounted setup. Add-ons include AI, eSigning, and integrations.",
                },
                {
                    title: "Perpetual & On-Premise",
                    amount: "$30,000",
                    period: "one-time",
                    info: "Designed for large organizations needing advanced security, dedicated management, extensive customization (up to 50 hrs), and storage based on client’s server. Add-ons available to meet specific needs.",
                },
            ],
        },
        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "",
                    title: "Request Demo",
                    description: "Request to see eBoard By Sibasi in action!",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have an eBoard expert contact you",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call an eBoard expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },
    {
        id: "microsoftlicenses",
        name: "Microsoft 365 Licenses",
        image: "Microsoft-image.jpg",
        shortDescription: "Flexible Microsoft 365 Licensing for SMBs",
        categories: [
            "Powerapps Canvas App",
            "Dataverse",
            "PowerAutomate",
            "Azure Blob Storage",
            "Rest AP",
        ],
        filters: ["Microsoft"],
        overview: {
            title: "Microsoft 365 Licenses",
            description: "Flexible Microsoft 365 Licensing for SMBs",
            categories: [
                {
                    id: "microsoft365businessbasic",
                    title: "Microsoft 365 Business Basic",
                    subCategory: [
                        {
                            id: "overview",
                            title: "Overview",
                            description:
                                "This license is designed for small and medium-sized businesses and provides access to web versions of Office applications, Microsoft 365 Business Basic provides essential productivity tools such as Outlook, Word, Excel, and PowerPoint.",
                            image: "Business-Basic.JPG",
                            link: "",
                        },
                        {
                            id: "emailandcalendars",
                            title: "Email and calendars",
                            description:
                                "Get business-class email with Outlook, including a 50 GB mailbox per user, and send attachments of up to 150 MB.",
                            image: "Business-Basic.JPG",
                            link: "",
                        },
                        {
                            id: "securecloudstorage",
                            title: "1 TB of secure cloud storage per user",
                            description:
                                "Get 1 TB of OneDrive cloud storage to edit and share documents, photos, and more from anywhere, on all your devices.",
                            image: "Business-Basic.JPG",
                            link: "",
                        },
                        {
                            id: "Microsoft365appsontheweb",
                            title: "Microsoft 365 apps on the web",
                            description:
                                "Use always-up-to-date Word, Excel, PowerPoint, OneNote and Outlook on the web. Simultaneously collaborate with others in the same document in real time, across web, mobile, and different devices—no installation required.",
                            image: "Business-Basic.JPG",
                            link: "",
                        },
                        {
                            id: "Instantmessaging",
                            title: "Instant messaging and online meetings",
                            description:
                                "Chat, meet, and collaborate with Microsoft Teams. Combine messaging, voice calls, video calls, and availability in one app.",
                            image: "Business-Basic.JPG",
                            link: "",
                        },
                        {
                            id: "intranetandteamsites",
                            title: "Intranet and team sites",
                            description:
                                "Inform and engage your organization and connect people to content, knowledge, and processes with team sites through SharePoint. Tell your story with beautiful, easy-to-use sites.",
                            image: "Business-Basic.JPG",
                            link: "",
                        },
                        {
                            id: "Organizeschedulesandmanageappointments",
                            title: "Organize schedules and manage appointments",
                            description:
                                "Bookings includes a web-based booking calendar and integrates with Outlook to give your customers flexibility to book a time that works best for them. Email and SMS text notifications reduce no-shows and enhance customer satisfaction.",
                            image: "Business-Basic.JPG",
                            link: "",
                        },
                    ],
                },
                {
                    id: "businessstandard",
                    title: "Microsoft 365 Business Standard",
                    subCategory: [
                        {
                            id: "businessstandardoverview",
                            title: "Overview",
                            description:
                                "Suitable for businesses that need the full suite of Office applications includes desktop versions of Office applications, allowing users to work offline. This plan is suitable for organizations that require a more extensive suite of productivity tools and a collaboration platform for business of all sizes.",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "Microsoftapps",
                            title: "Microsoft 365 apps",
                            description:
                                "Get monthly updates and the latest features and capabilities with fully installed and always-up-to-date versions of Outlook, Word, Excel, PowerPoint, and OneNote (features vary) for Windows or Mac, and Access and Publisher (PC only).",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "cloudstorageperuser",
                            title: "1 TB of secure cloud storage per user",
                            description:
                                "Edit and share documents, photos, and more from anywhere, on all your devices with 1 TB of OneDrive cloud storage.",
                            image: "Business1.JPEG",
                            link: "",
                        },
                        {
                            id: "emailandcalendars",
                            title: "Email and calendars",
                            description:
                                "Get business-class email with Outlook, including a 50 GB mailbox per user, and send attachments of up to 150 MB.",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "intranetandteamsites",
                            title: "Intranet and team sites",
                            description:
                                "Inform and engage your organization and connect people to content, knowledge, and processes with team sites through SharePoint. Tell your story with beautiful, easy-to-use sites.",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "digital storytelling",
                            title: "Professional digital storytelling",
                            description:
                                "Easily create engaging, interactive, web-based reports, presentations, newsletters, trainings, and more with Sway.",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "Intelligent search and discovery",
                            title: "searchanddiscovery",
                            description:
                                "Discover content and expertise across your organization with intelligent search across all your data sources including SharePoint, Delve, and Microsoft 365 apps.",
                            image: "Business1.JPEG",
                            link: "",
                        },
                        {
                            id: "organizeprojectsandideas",
                            title: "Organize projects and ideas",
                            description:
                                "Think, plan, and create together in collaborative workspaces with Microsoft Loop.",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "connectionandcollaboration",
                            title: "Connection and collaboration",
                            description:
                                "Instantly access everything your teams may need including chat, content, tools, and people with Microsoft Teams.",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "Webandmobileappdevelopment",
                            title: "Web and mobile app development",
                            description:
                                "Quickly build custom business apps for web and mobile with Microsoft Power Apps. Choose from templates or start from scratch. No coding required.",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "Workflow",
                            title: "Workflow automation",
                            description:
                                "Build automated workflows between apps and services to get notifications, synchronize files, collect data, and more with Microsoft Power Automate. No coding required.",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "Worksacrossmultipledevices",
                            title: "Works across multiple devices",
                            description:
                                "Get the fully installed Microsoft 365 apps on multiple PCs, Macs, tablets, and mobile devices (including Windows, iOS, and Android).",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "messagingandonlinemeetings",
                            title: "Instant messaging and online meetings",
                            description:
                                "Communicate using text, voice, or video calls with Microsoft Teams. Host meetings on the web using one-step screen sharing and HD video conferencing.",
                            image: "Business1.jpg",
                            link: "",
                        },
                        {
                            id: "videoeditingtools",
                            title: "Video editing tools",
                            description:
                                "Create and share professional-looking videos with Microsoft Clipchamp.",
                            image: "Business1.jpg",
                            link: "",
                        },
                    ],
                },
                {
                    id: "microsoft365businesspremium",
                    title: "Microsoft 365 Business Premium",
                    subCategory: [
                        {
                            id: "microsoftBusinesspremiumoverview",
                            title: "Overview",
                            description:
                                "This license is targeted at businesses requiring advanced security and device management features. It includes all the features of Business Standard, along with additional security features like Azure Information Protection and Microsoft Intune for device management.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "morewithmicrosoftapps",
                            title: "Do more with Microsoft 365 apps",
                            description:
                                "Get the latest features and capabilities with fully installed and always up-to-date versions of Outlook, Word, Excel, PowerPoint for Windows or Mac, OneNote (features vary), and Access and Publisher (PC only).",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "cloudstorage",
                            title: "Use 1 TB cloud storage per user",
                            description:
                                "Edit and share documents, photos, and other files from anywhere on all your devices with 1 TB of OneDrive cloud storage.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "calendaring",
                            title: "Get email and calendaring",
                            description:
                                "Use enterprise-grade email through Outlook with a 50 GB mailbox per user and send attachments of up to 150 MB.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "instantmessagingandonlinemeetings",
                            title: "Collaborate with instant messaging and online meetings",
                            description:
                                "Get your team on the same page using group chat, online meetings, calling, and web conferencing with Microsoft Teams.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "appointmentscheduling",
                            title: "Streamline appointment scheduling with customers and partners",
                            description:
                                "Simplify how customers schedule and manage appointments with Microsoft Bookings. Provide your customers with an easy-to-navigate webpage where they can find and book appointments around the clock.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "videoeditingtools",
                            title: "Video editing tools",
                            description:
                                "Create and share professional-looking videos with Microsoft Clipchamp.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "defendagainstcyberthreats",
                            title: "Defend against cyberthreats",
                            description:
                                "Activate Microsoft Defender for Office 365 to help guard against viruses, spam, unsafe attachments, suspicious links, and phishing attacks.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "defendagainstcyberthreats",
                            title: "Defend against cyberthreats",
                            description:
                                "Activate Microsoft Defender for Office 365 to help guard against viruses, spam, unsafe attachments, suspicious links, and phishing attacks.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "managemobiledevices",
                            title: "Manage mobile devices, tablets, and computers",
                            description:
                                "Apply security policies to help protect work data on company-owned and employee devices. Keep company data within approved apps. Remove business data from lost or stolen devices with Microsoft Intune.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "secureremoteaccessandprotectidentity",
                            title: "Enable secure remote access and protect identity",
                            description:
                                "Help employees securely access business apps wherever they work with conditional access. Safeguard against password loss or theft with advanced multifactor authentication.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                        {
                            id: "businessdata",
                            title: "Help protect business data",
                            description:
                                "Encrypt emails and discover, classify, and label sensitive data. Help prevent risky or unauthorized use of business data with Microsoft Purview Information Protection. Enable auto-expanding archiving.",
                            image: "Microsoft-Business-Premium.jpg",
                            link: "",
                        },
                    ],
                },
            ],
        },

        pricing: {
            title: "Microsoft 365 pricing",
            tiers: [
                {
                    title: "Microsoft 365 Business Basic",
                    amount: "$6.00",
                    period: "Per user/month",
                    info: "(Annual subscription-auto renews) Max 300 users",
                },
                {
                    title: "Microsoft 365 Business Standard",
                    amount: "$12.50",
                    period: "Per user/month",
                    info: "(Annual subscription-auto renews) Max 300 users",
                },
                {
                    title: "Microsoft 365 Business Premium",
                    amount: "$22.00 ",
                    period: "Per user/month",
                    info: "(Annual subscription-auto renews) Max 300 users",
                },
            ],
        },
        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "fa-solid fas fa-bell",
                    title: "Chat with a Microsoft 365 expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have a Microsoft 365 expert contact you",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call a Microsoft 365 expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },
    {
        id: "powerbi",
        name: "Power BI",
        image: "powerbi-bg.jpg",
        shortDescription:
            "Uncover powerful insights and turn them into impact. Connect to and visualize any data, and seamlessly infuse visuals into the apps you use every day.",
        categories: [
            "Powerapps Canvas App",
            "Dataverse",
            "PowerAutomate",
            "Azure Blob Storage",
            "Rest API",
        ],
        filters: ["Microsoft", "Power BI"],
        overview: {
            title: "Empower Your Data, Enlighten Your Business: Power BI",
            description:
                "Turn your data into visuals with advanced data-analysis tools, AI capabilities, and a user-friendly report-creation tool.",
            categories: [
                {
                    id: "Humanresourcemanagement",
                    title: "Human Resource Report",
                    link: "https://app.powerbi.com/view?r=eyJrIjoiNzgwNGY3MDUtZGVkMS00MzA2LTk2ZDctZmNjMjYyYWUxOTFjIiwidCI6Ijk4MTdlNTcyLThiMzItNGJlOC1hMGUwLTYyODY5MTcyZTJkZiIsImMiOjh9",
                    subCategory: [
                        {
                            id: "WorkforceOverview",
                            title: "Workforce Overview",
                            description:
                                "Reporting on the workforce is one of HR’s essential tasks. Workforce KPIs (Key Performance Indicators) are measurable metrics used to assess the effectiveness of various aspects related to managing and optimizing the workforce. In this report, we track several key metrics, including Headcount, Diversity and Inclusion Metrics (such as gender, ethnicity, and age), and salary range penetration. The salary range penetration metric highlights the percentage of employees whose salaries fall within the established salary range for their respective positions.",
                            image: "Workforce.png",
                            link: "",
                        },
                        {
                            id: "EmployeeAttrition",
                            title: "Employee Attrition",
                            description:
                                "Employee attrition as a metric, provides insights into workforce stability, retention efforts, and potential areas for improvement in talent management strategies.",
                            image: "Employee_attrition.png",
                            link: "",
                        },
                        {
                            id: "EmployeeDevelopment",
                            title: "Employee Development",
                            description:
                                "Effective employee development fosters employee engagement, retention, and overall organizational success.​By systematically tracking employee development, HR can ensure that talent is nurtured, skills gaps are addressed, and the organization remains competitive in a rapidly evolving business landscape.In this report we track; overall training costs over time, training costs per employee, Retention Rates of trained employees as well as effectiveness of attended trainings or development programs.​",
                            image: "Employee_development.png",
                            link: "",
                        },

                        {
                            id: "EmployeeProfile",
                            title: "Employee Profile",
                            description:
                                "Employee profile is instrumental in effective human resource management, providing a centralized repository for vital information of an individual such as contact details, career development and leave management. ​",
                            image: "Employee_profile.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does the Sibasi Human Resource Management Application benefit our organization?",
                                    answer: "This app leverages Microsoft Power BI to translate Human Resource data into actionable insights. It empowers organizations to make informed decisions, optimize workforce strategies, and enhance employee engagement by offering data-driven guidance on workforce management and retention.",
                                },
                                {
                                    question:
                                        "How can we get started with the Sibasi Human Resource Management Application?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },
                                {
                                    question:
                                        "What are the integration options with data systems?",
                                    answer: "The app connects to a range of systems and consolidates data from multiple sources. This enables you to have a comprehensive view of your HR data in one centralized dashboard, making it easier to analyze and derive meaningful insights. Integration options include, but are not limited to: 1. Existing HR systems, such as Oracle or SAP. 2. Database connections, if you store data in a database or data warehouse. 3. Flat files (e.g., CSV/Excel files) using our pre-defined templates. 4. API integration with other software (e.g., Dynamics 365, Navision, etc.).",
                                },
                                {
                                    question:
                                        "How easy is it to integrate the data?",
                                    answer: "We can provide consulting services to connect your data to your source system(s), customize the reports, and automate the process in as little as 1-2 weeks.",
                                },
                                {
                                    question:
                                        "How much does the Sibasi Human Resource Management Application cost?",
                                    answer: "We offer a one-time cost of $1500-$2000 for full customization and integration of the app into your system and automation of data refresh. If you require ongoing support, maintenance, and enhancements, a monthly fee can be discussed for a support agreement.",
                                },
                                {
                                    question:
                                        "What Power BI license do we need?",
                                    answer: "To get started, a Power BI Pro license is ideal. Reach out to us for more information on different licensing options to suit your needs.",
                                },
                                {
                                    question:
                                        "What do I need to provide to receive a quote for implementation?",
                                    answer: "Contact us to identify your data sources, desired objectives and outputs, data refresh frequency preference, and whether you require new report creation or customization of existing dashboards.",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "Financial Reports",
                    title: "Financials",
                    link: "https://app.powerbi.com/view?r=eyJrIjoiMTY0NjRhYjMtNmYzYS00ZjAzLThmZDUtYWUxMjFlOThmZmRiIiwidCI6Ijk4MTdlNTcyLThiMzItNGJlOC1hMGUwLTYyODY5MTcyZTJkZiIsImMiOjh9",
                    subCategory: [
                        {
                            id: "ProfitandLoss",
                            title: "Income Statement",
                            description:
                                "Profit and Loss (P&L) reports are fundamental tools in financial analysis, offering a comprehensive overview of a company's financial performance over a specific period. These reports showcase revenues, expenses, and resulting profits or losses, enabling stakeholders to assess the business's health and efficiency",
                            image: "Incomestatement.png",
                            link: "",
                        },
                        {
                            id: "CashflowStatement",
                            title: "Cashflow Statement",
                            description:
                                "The Cash Flow Statement tracks the movement of cash into and out of a business during a specific period. This statement provides valuable insights into a organization's liquidity, solvency, and ability to generate positive cash flows, aiding in assessing the organizations financial health and stability.",
                            image: "Incomestatement.png",
                            link: "",
                        },
                        {
                            id: "BalanceSheet",
                            title: "Balance Sheet/ Statement of Financial Position",
                            description:
                                "The Statement of Financial Position, also known as the balance sheet, is a key financial statement that provides a snapshot of a company's financial position at a specific point in time. It presents the company's assets, liabilities, and shareholders' equity.This statement helps stakeholders understand the company's financial health, its ability to meet its short-term and long-term obligations, and the portion of assets financed by debt versus equity.",
                            image: "Statement of Financial Position.png",
                            link: "",
                        },

                        {
                            id: "FinancialRatios",
                            title: "Financial Ratios",
                            description:
                                "Financial ratios offer concise and insightful assessments of a organization's financial condition and performance. These ratios serve as early warning signs for potential financial challenges and inform strategic decision-making. ",
                            image: "Incomestatement.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does the Sibasi Financial Reports Application benefit our organization?",
                                    answer: "It provides clear insights into key financial metrics, facilitating informed decision-making and strategic planning.",
                                },
                                {
                                    question:
                                        "How can we get started with the Sibasi Financial Reports Application?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },
                                {
                                    question:
                                        "What are the integration options with data systems?",
                                    answer: "The app connects to a range of systems and consolidates data from multiple sources. This enables you to have a comprehensive view of your data in one centralized dashboard, making it easier to analyze and derive meaningful insights. Integration options include, but are not limited to: 1. Existing systems, such as Oracle or SAP. 2. Database connections, if you store data in a database or data warehouse. 3. Flat files (e.g., CSV/Excel) using our pre-defined templates. 4. API integration with other software (e.g., Dynamics 365, Navision, etc.).",
                                },
                                {
                                    question:
                                        "How easy is it to integrate the data?",
                                    answer: "We offer consulting services to connect your data to your source system(s), customize the reports, and automate the process in as little as 1-2 weeks.",
                                },
                                {
                                    question:
                                        "How much does the Sibasi Financial Reports Application cost?",
                                    answer: "We offer a one-time cost of $3000-$4000 for full customization and integration of the app into your system and automation of data refresh. If you require ongoing support, maintenance, and enhancements, a monthly fee can be discussed for a support agreement.",
                                },
                                {
                                    question:
                                        "What Power BI license do we need?",
                                    answer: "To get started, a Power BI Pro license is ideal. Reach out to us for more information on different licensing options to suit your needs.",
                                },
                                {
                                    question:
                                        "What do I need to provide to receive a quote for implementation?",
                                    answer: "Contact us to identify your data sources, desired objectives and outputs, data refresh frequency preference, and whether you require new report creation or customization of existing dashboards.",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "GrantsReport",
                    title: "Grants Management",
                    link: "https://app.powerbi.com/view?r=eyJrIjoiYzYwMTcxMDEtOTA5ZS00OTQ1LThhZDgtZDRmYTI4MzEwZmU4IiwidCI6Ijk4MTdlNTcyLThiMzItNGJlOC1hMGUwLTYyODY5MTcyZTJkZiIsImMiOjh9",
                    subCategory: [
                        {
                            id: "GrantsOpportunities",
                            title: "Grants Opportunities",
                            description:
                                "This Report allows for effective monitoring of the opportunities pipeline which is crucial for funding success.",
                            image: "Grants Opportunities.png",
                            link: "",
                        },
                        {
                            id: "GrantsReceiptsReceivables",
                            title: "Grants Receipts & Receivables",
                            description:
                                " This report allows users to manage awarded, received and forecasted receivable funds.",
                            image: "Grants Receipts.png",
                            link: "",
                        },
                        {
                            id: "GrantsFundsUtilization",
                            title: "Grants Funds Utilization",
                            description:
                                " This reportallows for monitoring budgets, tracking expenses, and maintaining compliance with Grants terms.",
                            image: "Grants Funds.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does Sibasi Grant Reports Application benefit our organization?",
                                    answer: "It streamlines grant tracking, improves fund allocation transparency, and enhances reporting efficiency, leading to more effective program management and compliance",
                                },
                                {
                                    question:
                                        "How can we get started with Sibasi Grants Reports Application?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },

                                {
                                    question:
                                        "What are the integration options with data systems?",
                                    answer: "The app connects to a range of systems and consolidates data from multiple sources. This enables you to have a comprehensive view of your HR data in one centralized dashboard, making it easier to analyze and derive meaningful insights. These sources include but not limited to; 1. Your existing HR systems, such as Oracle, or SAP among others 2. Database connection, if you store data in a database or data warehouse. 2. Flat files e.g., CSV/Excel files with our pre-defined templates, 3. API integration with other software e.g., Dynamics 365, Navision e.t.c.",
                                },
                                {
                                    question:
                                        "How easy it is to do the data integration?",
                                    answer: "We can provide consulting services to get your data connected to your source system(s), customize the reports, and automate the process in a little as 1-2 weeks.",
                                },

                                {
                                    question:
                                        "What Power BI license do we need?",
                                    answer: "To get started, a Power BI Pro license would be ideal. Reach out to us for more information on different licensing options to suit your needs.",
                                },

                                {
                                    question:
                                        "What do I need to provide to receive the quote for the implementation?",
                                    answer: "You can contact us. We will identify your data sources, your desired ojectives and outputs ,data refresh frequency preference and we will advice whether you require new report creation or customization of existing dashboards.",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "RiskandComplianceReport",
                    title: "Risk and Compliance",
                    link: "https://app.powerbi.com/view?r=eyJrIjoiNDcxZWM0MTEtYmZhMS00ODE0LTg1MzUtMDU4NjE3YzNiNTJiIiwidCI6Ijk4MTdlNTcyLThiMzItNGJlOC1hMGUwLTYyODY5MTcyZTJkZiIsImMiOjh9",
                    subCategory: [
                        {
                            id: "OverallComplianceReport",
                            title: "Overall Compliance Report",
                            description:
                                " This provides a consolidated view of overall compliance status of various facets of the organization.",
                            image: "Risk & Compliance Report.png",
                            link: "",
                        },

                        {
                            id: "RiskActionReport",
                            title: "Risk Action Report",
                            description:
                                "This report provides detailed insights into risk profiles, compliance status, and control measures, facilitating informed decision-making and proactive risk mitigation.",
                            image: "Compliance Action.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does Sibasi Compliance Reports Application benefit our organization?",
                                    answer: "It provides real-time visibility into risk metrics and compliance status, enabling proactive management and informed decision-making.",
                                },
                                {
                                    question:
                                        "How can we get started with Sibasi Financial Reports Application?",
                                    answer: "To get started , simply reach out to our team for a personalized demo and consultation.",
                                },

                                {
                                    question:
                                        "What are the integration options with data systems?",
                                    answer: "The app connects to a range of systems and consolidates data from multiple sources. This enables you to have a comprehensive view of your HR data in one centralized dashboard, making it easier to analyze and derive meaningful insights. These sources include but not limited to; 1. Your existing HR systems, such as Oracle, or SAP among others 2. Database connection, if you store data in a database or data warehouse. 2. Flat files e.g., CSV/Excel files with our pre-defined templates, 3. API integration with other software e.g., Dynamics 365, Navision e.t.c.",
                                },
                                {
                                    question:
                                        "How easy it is to do the data integration?",
                                    answer: "We can provide consulting services to get your data connected to your source system(s), customize the reports, and automate the process in a little as 1-2 weeks.",
                                },
                            ],
                        },
                    ],
                },

                {
                    id: "OEEManufacturingOverview",
                    title: "OEE Manufacturing Overview",
                    link: "https://app.powerbi.com/view?r=eyJrIjoiNzY0ODVlMTItNmE0NS00ZDBhLWIxYTAtNjdhMmY5Y2I2ZDA5IiwidCI6Ijk4MTdlNTcyLThiMzItNGJlOC1hMGUwLTYyODY5MTcyZTJkZiIsImMiOjh9",
                    subCategory: [
                        {
                            id: "OverallComplianceReport",
                            title: "Overall Compliance Report",
                            description:
                                " OEE Overview provides a comprehensive view of equipment effectiveness by combining availability, performance, and quality metrics into actionable insights for improved manufacturing efficiency.",
                            image: "OEE Manufacturing Overall.png",
                            link: "",
                        },

                        {
                            id: "OEEAvailability",
                            title: "OEE Availability ",
                            description:
                                " Provides a view of equipment uptime, highlighting periods of availability for production activities while accounting for downtime and scheduled maintenance events. ",
                            image: "OEE Availability.png",
                            link: "",
                        },

                        {
                            id: "OEEDowntime",
                            title: "Downtime ",
                            description:
                                "  Provides a view of periods during which equipment is not operational, leading to reduced Overall Equipment Effectiveness (OEE).",
                            image: "Downtime.png",
                            link: "",
                        },

                        {
                            id: "OEEQuality",
                            title: "OEE Quality",
                            description:
                                " This report allows users to monitor the percentage of products that meet quality standards compared to total production output, providing insights into production quality performance.",
                            image: "OEEQuality.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does Sibasi Overall Equipment Effectiveness (OEE) Application benefit our organization?",
                                    answer: " It provides insights into production efficiency, downtime analysis, and performance metrics, facilitating informed decision-making and continuous improvement in manufacturing .",
                                },
                                {
                                    question:
                                        "How can we get started with Sibasi Grants Reports Application?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },

                                {
                                    question:
                                        "What are the integration options with data systems?",
                                    answer: "The app connects to a range of systems and consolidates data from multiple sources. This enables you to have a comprehensive view of your HR data in one centralized dashboard, making it easier to analyze and derive meaningful operationsinsights. These sources include but not limited to; 1. Your existing HR systems, such as Oracle, or SAP among others 2. Database connection, if you store data in a database or data warehouse. 2. Flat files e.g., CSV/Excel files with our pre-defined templates, 3. API integration with other software e.g., Dynamics 365, Navision e.t.c.",
                                },
                                {
                                    question:
                                        "How easy it is to do the data integration?",
                                    answer: "We can provide consulting services to get your data connected to your source system(s), customize the reports, and automate the process in a little as 1-2 weeks.",
                                },

                                {
                                    question:
                                        "What Power BI license do we need?",
                                    answer: "To get started, a Power BI Pro license would be ideal. Reach out to us for more information on different licensing options to suit your needs.",
                                },

                                {
                                    question:
                                        "What do I need to provide to receive the quote for the implementation?",
                                    answer: "You can contact us. We will identify your data sources, your desired ojectives and outputs ,data refresh frequency preference and we will advice whether you require new report creation or customization of existing dashboards.",
                                },
                            ],
                        },
                    ],
                },

                {
                    id: "Contracts",
                    title: "Contracts Management",
                    link: "https://app.powerbi.com/view?r=eyJrIjoiMjJkMzBjYjMtZmFjNi00NTc4LWI2NTAtN2U4NjI0YTZlN2Q2IiwidCI6Ijk4MTdlNTcyLThiMzItNGJlOC1hMGUwLTYyODY5MTcyZTJkZiIsImMiOjh9",
                    subCategory: [
                        {
                            id: "ContractsOverview",
                            title: "Contracts Overview",
                            description:
                                "This report provides a concise summary of all contracts, displaying essential details such as status, dates, parties involved, and financial obligations for comprehensive management and decision-making. ",
                            image: "Contracts Overview.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does Sibasi Contracts Application benefit our organization?",
                                    answer: " Contracts application centralizes and visualizes critical contract-related information, including contract status, key dates and obligations. It improves transparency, and enhances decision-making processes related to contract negotiations, renewals, and risk management .",
                                },
                                {
                                    question:
                                        "How can we get started with Sibasi Grants Reports Application?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },

                                {
                                    question:
                                        "What are the integration options with data systems?",
                                    answer: "The app connects to a range of systems and consolidates data from multiple sources. This enables you to have a comprehensive view of your HR data in one centralized dashboard, making it easier to analyze and derive meaningful operationsinsights. These sources include but not limited to; 1. Your existing HR systems, such as Oracle, or SAP among others 2. Database connection, if you store data in a database or data warehouse. 2. Flat files e.g., CSV/Excel files with our pre-defined templates, 3. API integration with other software e.g., Dynamics 365, Navision e.t.c.",
                                },
                                {
                                    question:
                                        "How easy it is to do the data integration?",
                                    answer: "We can provide consulting services to get your data connected to your source system(s), customize the reports, and automate the process in a little as 1-2 weeks.",
                                },

                                {
                                    question:
                                        "What Power BI license do we need?",
                                    answer: "To get started, a Power BI Pro license would be ideal. Reach out to us for more information on different licensing options to suit your needs.",
                                },

                                {
                                    question:
                                        "What do I need to provide to receive the quote for the implementation?",
                                    answer: "You can contact us. We will identify your data sources, your desired ojectives and outputs ,data refresh frequency preference and we will advice whether you require new report creation or customization of existing dashboards.",
                                },
                            ],
                        },
                    ],
                },
            ],
        },

        pricing: {
            title: "Power BI Service pricing",
            tiers: [
                {
                    title: "General pricing",
                    amount: "$3,000",
                    period: "Per report",
                    info: "Unlock the full potential of your data with our tailored Power BI reporting solutions.",
                },
                {
                    title: "Consultation, Training and Customization",
                    amount: "$80",
                    period: "Per hour",
                    info: "Optimize your data analytics strategy, train your team on advanced Power BI features, or customize our reports to fit your specific business needs.",
                },
            ],
        },

        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "",
                    title: "Chat with a Power BI expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have a Power BI expert contact you",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call a Power BI expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },
    {
        id: "d365sales",
        name: "Dynamics 365 Sales",
        image: "sales.png",
        shortDescription:
            "Empower sales teams with automation, contextual insights, and next-generation AI.",
        categories: [],
        filters: ["Microsoft", "Dynamics 365"],
        overview: {
            title: "Empower your sellers and accelerate revenue",
            description:
                "Make selling easier with AI, automation, and real-time insights built into sellers’ flow of work.",
            categories: [
                {
                    id: "close_more_deals",
                    title: "close more deals",
                    subCategory: [
                        {
                            id: "reduce_mundane_tasks",
                            title: "Reduce mundane taks",
                            description:
                                "Generate customer-specific email drafts, email summaries, and opportunity summaries with copilot capabilities.",
                            image: "Accordian-Close-more-deals_01_832X468.avif",
                            link: "",
                        },
                        {
                            id: "prioritize_your_pipeline",
                            title: "Prioritize your pipeline",
                            description:
                                "Focus on the right customers with AI-based scoring models, a prioritized work list, and data visualizations.",
                            image: "DYN_PDP_Sales_Overview_1-2.avif",
                            link: "",
                        },
                        {
                            id: "flow_of_work",
                            title: "Stay in the flow of work",
                            description:
                                "Minimize app-switching with automatic updates in Microsoft 365 apps.",
                            image: "Copilot-for-Sales-832x468.avif",
                            link: "",
                        },
                    ],
                },
                {
                    id: "meet_customer_needs",
                    title: "Meet customer needs",
                    subCategory: [
                        {
                            id: "prep_for_engagements",
                            title: "Prepare for engagements",
                            description:
                                "Use Copilot to generate summaries of customer details, meetings, emails, and relationship history.",
                            image: "DYN_PDP_Sales_Overview_2-1.avif",
                            link: "",
                        },
                        {
                            id: "take_best_action",
                            title: "Take the best action",
                            description:
                                "Get recommendations for engagement activities and notifications about contact activity and upcoming close dates.",
                            image: "Accordian-Meet-customer-needs_02_832X468.avif",
                            link: "",
                        },
                        {
                            id: "improve_interactions",
                            title: "Improve interactions",
                            description:
                                "Get AI-generated meeting summaries, including keyword and conversation analysis, competitor mentions, KPIs, and suggested tasks.",
                            image: "Accordian-Meet-customer-needs_03_832X468.avif",
                            link: "",
                        },
                    ],
                },
                {
                    id: "guide_sales_teams",
                    title: "Guide sales teams",
                    subCategory: [
                        {
                            id: "forecast_sales",
                            title: "Forecast sales",
                            description:
                                "Predict revenue and track performance against goals with forecasts and charts.",
                            image: "DYN_PDP_Sales_Overview_3-1.avif",
                            link: "",
                        },
                        {
                            id: "scale_best_practices",
                            title: "Scale best practices",
                            description:
                                "Provide automated sales guidance by creating sequences of sales actions for your team.",
                            image: "DYN_PDP_Sales_Overview_3-2.avif",
                            link: "",
                        },
                        {
                            id: "coach_with_confidence",
                            title: "Coach with confidence",
                            description:
                                "Get insights and key performance indicators (KPIs) from top performers to discover how others can learn from their success.",
                            image: "DYN_PDP_Sales_Overview_3-3.avif",
                            link: "",
                        },
                    ],
                },
            ],
        },
        // economicImpact: {
        //     title: "",
        //     description: "",
        //     impact: [
        //         {
        //             value: "",
        //             description: "",
        //         },
        //         {
        //             value: "",
        //             description: "",
        //         },
        //         {
        //             value: "",
        //             description: "",
        //         },
        //     ],
        // },
        pricing: {
            title: "Dynamics 365 Sales pricing",
            tiers: [
                {
                    title: "Dynamics 365 Sales Professional",
                    amount: "$65",
                    period: "per user/month",
                    info: "Core sales force automation and Microsoft 365 integration",
                },
                {
                    title: "Dynamics 365 Sales Enterprise",
                    amount: "$95",
                    period: "per user/month",
                    info: "Industry-leading sales force automation with contextual insights, next-generation AI, and advanced customization. Includes Copilot capabilities like natural language insights, record updates, email and meeting assistance, and opportunity summaries",
                },
                {
                    title: "Dynamics 365 Sales Premium",
                    amount: "$135",
                    period: "per user/month",
                    info: "Dynamics 365 Sales Enterprise plus prebuilt customizable intelligence solutions for sellers and managers. Includes Copilot capabilities like natural language insights, record updates, email and meeting assistance, and opportunity summaries",
                },
                // {
                //     title: "Microsoft Relationship Sales",
                //     amount: "$162",
                //     period: "per user/month",
                //     info: "Dynamics 365 Sales Enterprise plus LinkedIn Sales Navigator Enterprise. Requires a 10-seat minimum",
                // },
            ],
        },
        // partners: {
        //     title: "",
        //     partner: [
        //         {
        //             image: "",
        //             name: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        // testimonials: {
        //     title: "",
        //     testimony: [
        //         {
        //             image: "",
        //             name: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        // resources: {
        //     title: "",
        //     resource: [
        //         {
        //             image: "",
        //             type: "",
        //             title: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        nextSteps: {
            title: "Try Dynamics 365 Sales",
            description:
                "Make selling easier to build better customer relationships and close more deals.",
            link: "https://dynamics.microsoft.com/en-us/dynamics-365-free-trial/?tsapp=sales&appName=Sales&culture=en-us&country=us",
            image: "Next_Steps.svg",
            step: [
                {
                    icon: "",
                    title: "Take a guided tour",
                    description:
                        "Accelerate sales and close more deals with Dynamics 365 Sales.",
                    link: "https://guidedtour.microsoft.com/en-us/guidedtour/dynamics/sales-acceleration/1/1",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have a Dynamics 365 expert contact you.",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call a Dynamics 365 expert",
                    description: "Available M-F 8 AM to 6 PM EAT.",
                    link: "",
                },
            ],
        },
    },
    {
        id: "d365po",
        name: "Dynamics 365 Project Operations",
        image: "project-operations.png",
        shortDescription:
            "Get the visibility, collaboration, and agility needed to drive success across your project-centric business.",
        categories: [],
        filters: ["Microsoft", "Dynamics 365"],
        overview: {
            title: "Lead a profitable project-centric business",
            description:
                "Empower your teams to win more contracts, optimize resource utilization, accelerate project delivery, and get business insights from sales to project financials.",
            categories: [
                {
                    id: "deal_mgmt_experience",
                    title: "Improve deal management experiences",
                    subCategory: [
                        {
                            id: "customize_Costing_Pricing",
                            title: "Customize resource-based costing and pricing",
                            description:
                                "Model robust pricing and costing variations on geography and local market economics that influence your global workforce and exchange rates.",
                            image: "capbilities-accordion-c1-b1.png",
                            link: "",
                        },
                        {
                            id: "deal_structures",
                            title: "Use deal structures tailored for project-based organizations",
                            description:
                                "Create fixed-price, time and materials, or retainer-type contracts for your projects using adaptable billing models that meet your customers’ cash-flow requirements.",
                            image: "capabilities_accordion_c1_b2.png",
                            link: "",
                        },
                        {
                            id: "shorten_deal_cycle",
                            title: "Shorten the deal cycle",
                            description:
                                "Use standardized templates to quickly develop a detailed estimate, a schedule, and pricing for the quote",
                            image: "capabilities_accordion_c1_b3.png",
                            link: "",
                        },
                        {
                            id: "unified_sales_dashboard",
                            title: "Manage your pipeline with a unified sales dashboard",
                            description:
                                "Consolidate your customer data into a single view using the built-in interoperation with Dynamics 365 Sales to get actionable insights for personalized customer engagements",
                            image: "capabilities_accordion_c1_b4.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "enhanced_pm_capabilities",
                    title: "Drive success through enhanced project management capabilities",
                    subCategory: [
                        {
                            id: "pm_tools",
                            title: "Give project managers the tools they need to succeed",
                            description:
                                "Improve productivity with embedded Microsoft Project capabilities including dashboards, task management, scheduling, interactive Gantt charts, and work-breakdown structures",
                            image: "capabilities_accordion_c2_b1.png",
                            link: "",
                        },
                        {
                            id: "projects_to_budget",
                            title: "Estimate and track projects to budget",
                            description:
                                "Develop accurate estimates for labor, expenses, and materials, and track budget-to-actual consumption as projects progress.",
                            image: "capbilities-accordion-c2-b2.png",
                            link: "",
                        },
                        {
                            id: "collaborate_across_lifecycle",
                            title: "Collaborate easily across the project lifecycle",
                            description:
                                "Bring your cross-functional teams together around project plans, scope, and budget while capturing conversations, sharing content, and using apps—all in one place",
                            image: "capbilities-accordion-c2-b3.png",
                            link: "",
                        },
                        {
                            id: "adjust_key_insights",
                            title: "Adjust projects based on key insights",
                            description:
                                "Use built-in insights to understand how your projects are trending and drive timely decisions for course correction.",
                            image: "capbilities-accordion-c2-b4.png",
                            link: "",
                        },

                        {
                            id: "forecast_project_trends",
                            title: "Get answers from community portals",
                            description:
                                "Create budget and time snapshots for forecasting in resource and non-stocked deployments to get a clear view of spending and progress targets.",
                            image: "capbilities-accordion-c2-b5.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "optimize_resource_utilization",
                    title: "Optimize resource utilization",
                    subCategory: [
                        {
                            id: "maximize_your_resources",
                            title: "Set up voice channels",
                            description:
                                "Easily distribute workloads across your resources to optimize project delivery.",
                            image: "capabilities_accordion_c3_b1.png",
                            link: "",
                        },
                        {
                            id: "align_people_projects",
                            title: "Align the right people to the right projects",
                            description:
                                "Maintain resource profiles to easily match people’s skillsets with projects. Rate skills using proficiency models that adapt to your unique business needs.",
                            image: "capabilities_accordion_c3_b2.png",
                            link: "",
                        },
                        {
                            id: "intelligent_scheduling",
                            title: "Anticipate resourcing needs with intelligent scheduling",
                            description:
                                "Streamline scheduling with relevant skills-based assignments and up-to-date visibility into resources’ availability using universal resource scheduling.",
                            image: "capabilities_accordion_c3_b3.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "timetracking_expensemgmt",
                    title: "Simplify time tracking and expense management",
                    subCategory: [
                        {
                            id: "submit_onthe_go",
                            title: "Submit time and expenses on the go",
                            description:
                                "Use the mobile app or a browser to itemize expense entries, approve and review expenses, and receive expense-related notifications from anywhere.",
                            image: "",
                            link: "",
                        },
                        {
                            id: "team_member_compliance",
                            title: "Make team member compliance easier",
                            description:
                                "Give your teams easy-to-use time and expense tracking tools to support policy compliance.",
                            image: "capabilities_accordion_c4_b2.png",
                            link: "",
                        },
                        {
                            id: "accurate_reporting",
                            title: "Ensure accurate reporting and expense policy adherence",
                            description:
                                "Use configurable rules and automated workflows for time and expense submissions, approvals, and reporting to comply with required policies.",
                            image: "capabilities_accordion_c4_b3.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "improve_visibility",
                    title: "Improve visibility for greater business impact",
                    subCategory: [
                        {
                            id: "informed_decisions",
                            title: "Visualize key insights for fast, informed decisions",
                            description:
                                "Use built-in dashboards surfacing KPIs such as resource utilization, forecasting, and budgeting to quickly adapt to changing business conditions.",
                            image: "capabilities_accordion_c6_b1.png",
                            link: "",
                        },
                        {
                            id: "eliminate_silos",
                            title: "Democratize data to eliminate silos",
                            description:
                                "Use Microsoft Power BI to easily visualize and share business intelligence with your project teams to help drive better customer outcomes.",
                            image: "capabilities_accordion_c6_b2.png",
                            link: "",
                        },
                        {
                            id: "forecasting_snapshots",
                            title: "Create forecasting snapshots",
                            description:
                                "Generate budget and time snapshots to forecast in resource and non-stocked deployments.",
                            image: "capabilities_accordion_c4_b3.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "streamline_project_financials",
                    title: "Drive business performance through streamlined project financials",
                    subCategory: [
                        {
                            id: "recognize_revenue",
                            title: "Recognize revenue accurately and on time",
                            description:
                                "Support your global teams’ adherence to accounting standards and practices, including revenue recognition compliant with International Financial Reporting Standards (IFRS).",
                            image: "capabilities_accordion_c5_b1.png",
                            link: "",
                        },
                        {
                            id: "improve_cashflow",
                            title: "Improve cash flow by invoicing customers quickly",
                            description:
                                "Manage billing queues and add ad-hoc transactions for more precise invoicing. Add details and group transactions to customize invoices for optimal review and reconciliation.",
                            image: "capabilities_accordion_c5_b2.png",
                            link: "",
                        },
                        {
                            id: "manage_project_accounting",
                            title: "Manage your project accounting with higher accuracy",
                            description:
                                "Set up project cost and revenue profiles for fixed-price or time and materials billing using accounting rules for cost and revenue, accruals, and postings.",
                            image: "capabilities_accordion_c5_b3.png",
                            link: "",
                        },
                        {
                            id: "expand_ERP_system",
                            title: "Expand to a service-centric ERP system",
                            description:
                                "Interoperate with Dynamics 365 Finance or use open APIs with an external enterprise resource planning (ERP) system to extend to accounts payable, accounts receivable, and general ledger capabilities.",
                            image: "capabilities_accordion_c5_b4.png",
                            link: "",
                        },
                    ],
                },
            ],
        },
        // economicImpact: {
        //     title: "",
        //     description: "",
        //     impact: [
        //         {
        //             value: "",
        //             description: "",
        //         },
        //         {
        //             value: "",
        //             description: "",
        //         },
        //         {
        //             value: "",
        //             description: "",
        //         },
        //     ],
        // },
        pricing: {
            title: "Dynamics 365 Project Operations",
            tiers: [
                {
                    title: "Dynamics 365 Project Operations",
                    amount: "$120",
                    period: "per user/month",
                    info: "Get the visibility, collaboration, and agility needed to drive success across your project-centric business.",
                },
            ],
        },
        // partners: {
        //     title: "",
        //     partner: [
        //         {
        //             image: "",
        //             name: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        // testimonials: {
        //     title: "",
        //     testimony: [
        //         {
        //             image: "",
        //             name: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        // resources: {
        //     title: "",
        //     resource: [
        //         {
        //             image: "",
        //             type: "",
        //             title: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        nextSteps: {
            title: "Try Dynamics 365 Customer Service",
            description:
                "Provide exceptional, personalized, seamless service to earn customers for life.",
            link: "https://dynamics.microsoft.com/en-us/dynamics-365-free-trial/?tsapp=customerservice",
            image: "Next_Steps.svg",
            step: [
                {
                    icon: "",
                    title: "Chat with a Dynamics 365 expert",
                    description: "Available M-F 8 AM to 6 PM EAT.",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have a Dynamics 365 expert contact you.",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call a Dynamics 365 expert",
                    description: "Available M-F 8 AM to 6 PM EAT.",
                    link: "",
                },
            ],
        },
    },
    {
        id: "d365ci",
        name: "Dynamics 365 Customer Insights",
        image: "customer-insights.png",
        shortDescription:
            "Create engaging experiences by uniting customer data with real-time journeys and Copilot in Customer Insights",
        categories: [],
        filters: ["Microsoft", "Dynamics 365"],
        overview: {
            title: "Deliver connected customer journeys",
            description:
                "Understand your customers and create truly personalized experiences.",
            categories: [
                {
                    id: "know_your_customers",
                    title: "Know your customers",
                    subCategory: [
                        {
                            id: "view_of_customers",
                            title: "Get a richer view of customers",
                            description:
                                "Unify transactional, demographic, and behavioral data with AI-powered identity management for a holistic view of customers.",
                            image: "287289-DYN-CustomerInsights-1.avif",
                            link: "",
                        },
                        {
                            id: "predict_intent",
                            title: "Enrich data and predict intent",
                            description:
                                "Enrich profiles with diverse data sources, including proprietary intelligence, and unlock predictions with prebuilt AI models.",
                            image: "287289-DYN-CustomerInsights-C1-1.avif",
                            link: "",
                        },
                        {
                            id: "customer_segments",
                            title: "Create customer segments",
                            description:
                                "Build new segments or discover new audiences with AI recommendations that are continually updated with real-time data.",
                            image: "287289-DYN-CustomerInsights-c1-a3.avif",
                            link: "",
                        },
                        {
                            id: "consent_and_privacy",
                            title: "Respect consent and privacy",
                            description:
                                "Add consent data during the unification process using automatic data refresh to ensure that your organization meets privacy and security regulations.",
                            image: "287289-DYN-CustomerInsights-c1-a4.avif",
                            link: "",
                        },
                    ],
                },
                {
                    id: "enage_terms",
                    title: "Engage on their terms",
                    subCategory: [
                        {
                            id: "realtime_journeys",
                            title: "Deliver real-time journeys",
                            description:
                                "Create real-time customer journeys across channels that are contextually relevant and can be optimized with A/B testing.",
                            image: "287289-DYN-CustomerInsights-c2-b1.avif",
                            link: "",
                        },
                        {
                            id: "boost_ai",
                            title: "Boost engagement with AI",
                            description:
                                "Deliver tailored experiences with the right message at the right time using AI-driven channel selection.",
                            image: "287289-DYN-CustomerInsights-c2-b2.avif",
                            link: "",
                        },
                        {
                            id: "share_leads",
                            title: "Nurture and share leads",
                            description:
                                "Hand off scored, qualified leads to sellers automatically and measure pipeline success with prebuilt analytics dashboards.",
                            image: "287289-DYN-CustomerInsights-c2-b3.avif",
                            link: "",
                        },
                        {
                            id: "reach_customers",
                            title: "Reach customers where they are",
                            description:
                                "Engage customers using prebuilt digital channels including text, email, and push notifications, and then seamlessly add custom channels to the journey.",
                            image: "287289-DYN-CustomerInsights-c2-b4.avif",
                            link: "",
                        },
                    ],
                },
                {
                    id: "Boost_your_productivity",
                    title: "Boost your productivity",
                    subCategory: [
                        {
                            id: "get_insights_faster",
                            title: "Get insights faster",
                            description:
                                "Discover insights and quickly understand customer attributes with individual profile summaries using Copilot.",
                            image: "287289-DYN-CustomerInsights-c3_b1.avif",
                            link: "",
                        },
                        {
                            id: "audience_targeting",
                            title: "Simplify audience targeting",
                            description:
                                "Describe your target segments to Copilot and quickly build, review, and modify customer segments.",
                            image: "287289-DYN-CustomerInsights-c3-b2.avif",
                            link: "",
                        },
                        {
                            id: "tailor_content",
                            title: "Tailor content quickly",
                            description:
                                "Generate tailored content and headlines, branded emails, and captivating images for any segment with Copilot.",
                            image: "287289-DYN-CustomerInsights-c3-b3",
                            link: "",
                        },
                        {
                            id: "create_journeys",
                            title: "Create journey easily",
                            description:
                                "Simplify journey creation with Copilot by defining your business goals, target audience, or desired flows.",
                            image: "287289-DYN-CustomerInsights-c3-b4",
                            link: "",
                        },
                    ],
                },
            ],
        },
        // economicImpact: {
        //     title: "",
        //     description: "",
        //     impact: [
        //         {
        //             value: "",
        //             description: "",
        //         },
        //         {
        //             value: "",
        //             description: "",
        //         },
        //         {
        //             value: "",
        //             description: "",
        //         },
        //     ],
        // },
        pricing: {
            title: "Dynamics 365 Customer Insights",
            tiers: [
                {
                    title: "Dynamics 365 Customer Insights",
                    amount: "$1,700",
                    period: "per tenant/month",
                    info: "Unify customer data with personalized journeys. Includes up to 4 environments, 100,000 Unified People, and 10,000 Interacted People.",
                },
            ],
        },
        // partners: {
        //     title: "",
        //     partner: [
        //         {
        //             image: "",
        //             name: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        // testimonials: {
        //     title: "",
        //     testimony: [
        //         {
        //             image: "",
        //             name: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        // resources: {
        //     title: "",
        //     resource: [
        //         {
        //             image: "",
        //             type: "",
        //             title: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        nextSteps: {
            title: "Try Dynamics 365 Customer Insights",
            description:
                "Deliver personalized, responsive, and connected customer journeys using built-in AI and analytics.",
            link: "https://dynamics.microsoft.com/en-us/dynamics-365-free-trial/?tsapp=marketing&culture=en-us&country=us",
            image: "Next_Steps.svg",
            step: [
                {
                    icon: "",
                    title: "Chat with a Dynamics 365 expert",
                    description: "Available M-F 8 AM to 6 PM EAT.",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have a Dynamics 365 expert contact you.",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call a Dynamics 365 expert",
                    description: "Available M-F 8 AM to 6 PM EAT.",
                    link: "",
                },
            ],
        },
    },
    {
        id: "d365cs",
        name: "Dynamics 365 Customer Service",
        image: "customer-service.png",
        shortDescription:
            "Empower agents to resolve issues quickly using generative AI and automation",
        categories: [],
        filters: ["Microsoft", "Dynamics 365"],
        overview: {
            title: "Empower your agents and transform customer service",
            description:
                "Exceed customer expectations by delivering exceptional service on any channel.",
            categories: [
                {
                    id: "effortless_work",
                    title: "Make agents' work effortless",
                    subCategory: [
                        {
                            id: "resolve_with_copilot",
                            title: "Resolve issues faster with Copilot",
                            description:
                                "Save agents time by using Copilot in Dynamics 365 Customer Service to diagnose, troubleshoot issues, and help draft customer responses.",
                            image: "287291-DYN-CustomerService-1.avif",
                            link: "",
                        },
                        {
                            id: "get_real_time_insights",
                            title: "Prioritize your pipeline",
                            description:
                                "Empower agents with sentiment analysis, translations, transcriptions, and AI-recommended knowledge articles.",
                            image: "287291-DYN-CustomerService-grti.avif",
                            link: "",
                        },
                        {
                            id: "connect_with_right_agent",
                            title: "Connect with the right agent",
                            description:
                                "Increase first-call resolution with AI-based routing that classifies issues and assigns them to the best-suited agent.",
                            image: "287291-DYN-CustomerService-cwra.avif",
                            link: "",
                        },
                        {
                            id: "live_in_teams",
                            title: "Collaborate live in Teams",
                            description:
                                "Bring together experts who resolved similar cases, and then collaborate in Microsoft Teams for quicker issue resolution.",
                            image: "287291-DYN-CustomerService-lit.avif",
                            link: "",
                        },
                    ],
                },
                {
                    id: "enable_self-service",
                    title: "Enable self-service",
                    subCategory: [
                        {
                            id: "ai_chatbots",
                            title: "Provide AI-powered chatbots",
                            description:
                                "Resolve customer issues in real time with intelligent, conversational, generative AI-powered bots.",
                            image: "287291-DYN-CustomerService-aibots.avif",
                            link: "",
                        },
                        {
                            id: "conversational_ivr",
                            title: "Deliver conversational IVR",
                            description:
                                "Resolve issues quickly through human-like interactions with generative AI-powered, conversational interactive voice response.",
                            image: "287291-DYN-CustomerService-civr.avif",
                            link: "",
                        },
                        {
                            id: "selfserve_automation",
                            title: "Self-serve with automation",
                            description:
                                "Increase productivity by automating routine tasks as well as complex transactions.",
                            image: "287291-DYN-CustomerService-selfserveautomation.avif",
                            link: "",
                        },
                        {
                            id: "community_portals",
                            title: "Get answers from community portals",
                            description:
                                "Collaborate, share tips, and gather rich insights from other users and experts in personalized community portals.",
                            image: "287291-DYN-CustomerService-comportals.avif",
                            link: "",
                        },
                    ],
                },
                {
                    id: "meet_customers",
                    title: "Meet customers where they are",
                    subCategory: [
                        {
                            id: "voice_channels",
                            title: "Set up voice channels",
                            description:
                                "Quickly set up phone systems for agents to assist customers on a scalable platform built and operated by Microsoft.",
                            image: "287291-DYN-CustomerService-4-1.avif",
                            link: "",
                        },
                        {
                            id: "digital_enagegement",
                            title: "Enhance digital engagement",
                            description:
                                "Provide faster service to customers on their preferred digital channel, including email, text, social network, or virtual assistant.",
                            image: "287291-DYN-CustomerService-4-2.avif",
                            link: "",
                        },
                        {
                            id: "realtime_translation",
                            title: "Provide real-time translation",
                            description:
                                "Effectively communicate with customers in the language of their choice using real-time translation.",
                            image: "287291-DYN-CustomerService-4-3.avif",
                            link: "",
                        },
                        {
                            id: "sentiment_based_routing",
                            title: "Use sentiment-based routing",
                            description:
                                "Based on the customer’s emotions and needs, route the case to the right agent every time.",
                            image: "287291-DYN-CustomerService-4-4.avif",
                            link: "",
                        },
                    ],
                },
                {
                    id: "know_them",
                    title: "Show them you know them",
                    subCategory: [
                        {
                            id: "360_view",
                            title: "Get a 360-degree view of customers",
                            description:
                                "Seamlessly manage customer requests from any channel while handling multiple sessions at the same time.",
                            image: "287291-DYN-CustomerService-5-1.avif",
                            link: "",
                        },
                        {
                            id: "biometrics",
                            title: "Authenticate with biometrics",
                            description:
                                "Use voice and behavioral recognition for a fast and personalized way to verify customer identities.",
                            image: "287291-DYN-CustomerService-5-2.avif",
                            link: "",
                        },
                        {
                            id: "analyze_sentiment",
                            title: "Analyze sentiment",
                            description:
                                "Deliver personalized responses with AI that monitors customer engagement and understands customer intent in real time.",
                            image: "287291-DYN-CustomerService-5-3.avif",
                            link: "",
                        },
                        {
                            id: "customer_journeys",
                            title: "Learn customer journeys",
                            description:
                                "Gain insights through customers’ interactions and journeys to personalize offers and recommendations.",
                            image: "287291-DYN-CustomerService-5-4.avif",
                            link: "",
                        },
                    ],
                },
                {
                    id: "optimize_service_operations",
                    title: "Optimize service operations",
                    subCategory: [
                        {
                            id: "supervisor_insights",
                            title: "Get supervisor insights",
                            description:
                                "Access an omnichannel insights dashboard with AI-driven conversation and channel analytics, and KPIs all in one place.",
                            image: "287291-DYN-CustomerService-supervisorinsights.avif",
                            link: "",
                        },
                        {
                            id: "find_coaching_opportunities",
                            title: "Find coaching opportunities",
                            description:
                                "Detect emerging trends and identify improvements across support topics, agent-customer interactions, and knowledge management.",
                            image: "287291-DYN-CustomerService-coachingOpps.avif",
                            link: "",
                        },
                        {
                            id: "efficiency_with_automation",
                            title: "Drive efficiency with automation",
                            description:
                                "Eliminate manual processes with more than 1,000 prebuilt flows and add custom data connectors to your ecosystem.",
                            image: "287291-DYN-CustomerService-efficiencywithautomation.avif",
                            link: "",
                        },
                        {
                            id: "staffing_levels",
                            title: "Predict and plan staffing levels",
                            description:
                                "Plan for the right level of staffing based on seasonality, predicted number of cases, and conversational volume with the forecast report feature.",
                            image: "287291-DYN-CustomerService-3-4.avif",
                            link: "",
                        },
                        {
                            id: "extensible_platform",
                            title: "Build on an open and extensible platform",
                            description:
                                "Reduce costs by building on existing capabilities and interoperating with other business applications—all on a single platform.",
                            image: "287291-DYN-CustomerService-3-5.avif",
                            link: "",
                        },
                    ],
                },
            ],
        },
        // economicImpact: {
        //     title: "",
        //     description: "",
        //     impact: [
        //         {
        //             value: "",
        //             description: "",
        //         },
        //         {
        //             value: "",
        //             description: "",
        //         },
        //         {
        //             value: "",
        //             description: "",
        //         },
        //     ],
        // },
        pricing: {
            title: "Dynamics 365 Customer Service pricing",
            tiers: [
                {
                    title: "Dynamics 365 Customer Service Professional",
                    amount: "$50",
                    period: "per user/month",
                    info: "Get core customer service capabilities to get started with basic resources for self-service, case management, and knowledge management.",
                },
                {
                    title: "Dynamics 365 Customer Service Enterprise",
                    amount: "$95",
                    period: "per user/month",
                    info: "Get advanced customer service capabilities for a personalized experience, improved agent productivity, and optimized operations.",
                },
            ],
        },
        // partners: {
        //     title: "",
        //     partner: [
        //         {
        //             image: "",
        //             name: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        // testimonials: {
        //     title: "",
        //     testimony: [
        //         {
        //             image: "",
        //             name: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        // resources: {
        //     title: "",
        //     resource: [
        //         {
        //             image: "",
        //             type: "",
        //             title: "",
        //             description: "",
        //             link: "",
        //         },
        //     ],
        // },
        nextSteps: {
            title: "Try Dynamics 365 Customer Service",
            description:
                "Provide exceptional, personalized, seamless service to earn customers for life.",
            link: "https://dynamics.microsoft.com/en-us/dynamics-365-free-trial/?tsapp=customerservice",
            image: "Next_Steps.svg",
            step: [
                {
                    icon: "",
                    title: "Chat with a Dynamics 365 expert",
                    description: "Available M-F 8 AM to 6 PM EAT.",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have a Dynamics 365 expert contact you.",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call a Dynamics 365 expert",
                    description: "Available M-F 8 AM to 6 PM EAT.",
                    link: "",
                },
            ],
        },
    },
    {
        id: "Monival",
        name: "Monival By Sibasi",
        image: "monival.png",
        shortDescription:
            "The ultimate solution for Project Management, Performance Contracts, Appraisal and management of the execution of your Strategic Plans ",
        categories: ["Plan ", "Execute", "Report"],
        filters: ["Monival"],
        overview: {
            title: "Monival, the top Monitoring and Evaluation solution for NGOs",
            description:
                "With real-time reporting, streamlined proposal management, and optimized task tracking, Monival surpasses traditional approaches for enhanced efficiency. Integrating Power BI transforms data into actionable insights, enabling informed decisions.",
            categories: [
                {
                    id: "Plan",
                    title: "Strategic Data Improvement",
                    subCategory: [
                        {
                            id: "StrategicPerformancemanagement",
                            title: "Strategic Performance management",
                            description:
                                "Unlock organizational potential with Strategic Performance Management (SPM). Streamline activities to align with strategic goals, empowering data-driven decisions through key performance indicators. SPM offers real-time monitoring with visual dashboards, fostering continuous improvement. Embrace the Balanced Scorecard for holistic evaluation, ensuring resilience and effective contribution to strategic goals",
                            image: "Office management-cuate.svg",
                            link: "",
                        },
                        {
                            id: "DataDrivenDecisions",
                            title: "Data-Driven Decisions",
                            description:
                                "No more flying blind! SPM equips you with real-time insights through visual dashboards, empowering data-driven choices that propel you towards success.",
                            image: "Office management-rafiki.svg",
                            link: "",
                        },
                        {
                            id: "ContinuousImprovement",
                            title: "Continuous Improvement",
                            description:
                                "Forget stagnation. SPM fosters a culture of continuous improvement, allowing you to adapt and thrive in a dynamic world.​",
                            image: "Office management-cuate.svg",
                            link: "",
                        },
                        {
                            id: "proposal",
                            title: "Revolutionizing Proposal Management",
                            description:
                                "Monival excels in proposal management, streamlining the entire lifecycle from creation to submission and tracking. User-friendly tools simplify the process, ensuring comprehensive and compelling content. The platform's centralized system enhances collaboration, coordination, and coherence among stakeholders. With seamless submission logistics and real-time tracking, Monival fosters accountability, transparency, and success in securing project funding.",
                            image: "Office management-rafiki.svg",
                            link: "",
                        },
                        {
                            id: "ContractsManagement",
                            title: "Unlock Contract Confidence: Take Control with Monival",
                            description:
                                "Monival redefines contract management, providing a comprehensive solution for streamlined creation, tracking, and management throughout the entire lifecycle. The platform enables users to easily monitor milestones, deadlines, and compliance requirements, fostering seamless collaboration and communication. Centralizing contract information ensures organizational organization and commitment fulfillment. Real-time tracking capabilities offer immediate visibility, proactively managing risks and opportunities, contributing to enhanced efficiency and reduced organizational risks. ",
                            image: "Consent-amico.svg",
                            link: "",
                        },
                    ],
                },

                {
                    id: "Evaluation",
                    title: "Task and Time Management",
                    subCategory: [
                        {
                            id: "TimesheetManagement",
                            title: "Timesheet Management",
                            description:
                                "Monival masters timesheet tracking, empowering accurate task & project time monitoring. Its meticulous recording fosters transparency & precision, even analyzing historical data for smarter resource allocation and project planning.",
                            image: "timesheet.jpeg",
                            link: "",
                        },
                        {
                            id: "TaskManagement",
                            title: "Task Management",
                            description:
                                "Monival excels in task management, offering an optimized and streamlined approach to task assignment and tracking. Its robust feature fosters accountability, ensuring a clear understanding of individual responsibilities. Real-time tracking allows for effective progress monitoring, contributing to efficient task completion and team collaboration. By centralizing task-related information, Monival enhances overall efficiency, aligning each team member with the organization's broader objective.",
                            image: "Work in progress-cuate.svg",
                            link: "",
                        },
                        {
                            id: "ActivitiesTracking",
                            title: "Revolutionizing Activities Tracking for Organizational Excellence",
                            description:
                                "Monival's Activities Tracking ensures centralized and streamlined monitoring of diverse activities. The platform offers real-time insights into ongoing initiatives, promoting accountability and a structured workflow. Stakeholders can efficiently monitor progress, facilitating timely interventions and adjustments. This systematic approach enhances efficiency, promotes collaboration, and contributes to successful strategic execution.",
                            image: "Developer activity-rafiki.svg",
                            link: "",
                        },

                        {
                            id: "StructuredWorkflow",
                            title: "Structured Workflow",
                            description:
                                "Monival allows organizations to assign tasks with clarity, promoting accountability and fostering a structured workflow, enhancing efficiency and collaboration for the successful execution of strategic initiatives.",
                            image: "Developer activity-bro.svg",
                            link: "",
                        },
                    ],
                },

                {
                    id: "reporting",
                    title: "Reporting",
                    subCategory: [
                        {
                            id: "preciseDecisionMaking",
                            title: "Empowering Precision Decision-Making using dynamic Reporting",
                            description:
                                "Monival empowers precise decision-making through robust reporting. Detailed reports provide valuable insights for strategic planning. Versatile tools allow customizable, comprehensible data presentation. Real-time reporting fosters agility in responses, enhancing informed decision-making and strategic planning.",
                            image: "Report-bro.svg",
                            link: "",
                        },

                        {
                            id: "CustomizationOptions",
                            title: "Customization Options",
                            description:
                                " The platform's reporting tools offer a versatile range of options, allowing users to tailor reports to meet specific organizational needs.",
                            image: "Online report-amico.svg",
                            link: "",
                        },

                        {
                            id: "Real-TimeAgility",
                            title: "Real-Time Agility",
                            description:
                                " With a real-time reporting feature, decision-makers gain access to the most current data, fostering agility in responses to emerging trends or challenges.",
                            image: "Online report-amico.svg",
                            link: "",
                        },
                    ],
                },
            ],
        },

        pricing: {
            title: "Monival",
            tiers: [
                {
                    title: "Standard",
                    amount: "$5,000",
                    period: "Per tenant/year",
                    info: "Access essential M&E features.",
                },
                {
                    title: "Premium",
                    amount: "$25,000",
                    info: "Access the advanced M&E features for better decision making.",
                    period: "Per tenant/year",
                },
            ],
        },

        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "",
                    title: "Request a Demo",
                    description: "See a system walkthough of Monival",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have a Monival expert contact you",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call a Monival expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },
    {
        id: "propertease",
        name: "PropertEase - Property Management by Sibasi",
        image: "PropertEaseBackground.png",
        shortDescription:
            "PropertEase by Sibasi is a user-friendly property management app that streamlines tasks like Prospecting, Lease management, and maintenance requests. Designed for both residential and commercial properties, it enhances efficiency, automates processes, and improves communication between landlords, property managers, and tenants, ensuring seamless operations.",
        categories: [
            "Powerapps Model-Driven App",
            "Dataverse",
            "PowerAutomate",
            "SharePoint",
        ],
        filters: ["Microsoft", "Power Platform"],
        overview: {
            title: "PropertEase - Simplifying Property Management, One Click at a Time",
            description:
                "PropertEase streamlines every step of property management—from prospecting new tenants, scheduling viewings, and managing offers, to seamless leasing, hassle-free maintenance requests, and thorough inspections. It's an all-in-one solution that empowers property managers with real-time insights, automations, and smooth communication for effortless management from start to finish.",
            categories: [
                {
                    id: "dashboards",
                    title: "Dashboards & Reports",
                    subCategory: [
                        {
                            id: "dashboard_overview",
                            title: "Dashboard Overview",
                            description:
                                "The PropertEase dashboard provides an at-a-glance view of key metrics, including prospects, scheduled viewings, active offers, property listings, maintenance requests, and upcoming inspections. It offers real-time updates and intuitive navigation, making property management more efficient and organized.",
                            image: "Dashboard_pp.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does the PropertEase by Sibasi Benefit your organisation?",
                                    answer: "PropertEase by Sibasi offers significant benefits to your organization by streamlining property management through a centralized system that efficiently handles property details, listings, tenants, and maintenance requests. By automating tracking and reporting tasks, it reduces manual workload and minimizes errors, enhancing overall efficiency. The system also improves communication by facilitating seamless interaction between tenants, owners, and agents, which boosts coordination and response times. Additionally, PropertEase provides comprehensive reporting features that deliver detailed insights and analytics on property performance, financials, and maintenance, supporting informed decision-making. Its scalable design adapts to growing property portfolios and evolving management needs, ensuring it supports your organization's growth effectively.",
                                },
                                {
                                    question:
                                        "How can we get started with PropertEase by Sibasi?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },
                                {
                                    question:
                                        "How easy is it to integrate the data?",
                                    answer: "We can provide consulting services to connect your data to your source system(s), customize the reports, and automate the process in as little as 1-2 weeks.",
                                },
                                {
                                    question:
                                        "How much does PropertEase by Sibasi cost?",
                                    answer: "The application will retail for $35 per user per month for the out of the box functionality that comes with the system. Additional customizations shall be billed on a time and material basis at the cost of $60 per Hour",
                                },
                                {
                                    question:
                                        "What do I need to provide to receive a quote for implementation?",
                                    answer: "To receive a quote for implementing PropertEase by Sibasi, you'll need to provide several key details. Start by outlining the scope of implementation, including the specific features and modules you require, such as property management, tenant tracking, or maintenance requests. It's important to include the number of users who will be accessing the system, as this affects licensing and support costs. Additionally, provide information on the size of your property portfolio, including the number of properties and units you manage, as this can impact the complexity of the implementation. If you have any specific customization or integration needs with existing systems, be sure to mention these as well. Sharing your desired timeline for implementation and any deadlines you need to meet will help in planning the project effectively. A rough estimate of your budget will assist in tailoring the quote to fit your financial constraints. Lastly, provide details about any current property management systems or tools you use, as they may need to be integrated or replaced. With these details, you'll receive a comprehensive and accurate quote that aligns with your organization's needs.",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "leadhub",
                    title: "Leads Hub",
                    subCategory: [
                        {
                            id: "prospects",
                            title: "Prospect",
                            description:
                                "Prospecting in PropertEase helps you manage potential tenants by tracking leads, inquiries, and interest in properties. It streamlines communication, organizes prospects, and provides insights to convert leads into tenants efficiently.",
                            image: "Prospects.png",
                            link: "",
                        },
                        {
                            id: "viewings",
                            title: "Viewings",
                            description:
                                "The viewings feature in PropertEase allows you to schedule and manage property tours with ease. It tracks appointments, sends reminders, and ensures seamless coordination between prospects and property managers for a smooth viewing experience.",
                            image: "Viewingform.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does the PropertEase by Sibasi Benefit your organisation?",
                                    answer: "PropertEase by Sibasi offers significant benefits to your organization by streamlining property management through a centralized system that efficiently handles property details, listings, tenants, and maintenance requests. By automating tracking and reporting tasks, it reduces manual workload and minimizes errors, enhancing overall efficiency. The system also improves communication by facilitating seamless interaction between tenants, owners, and agents, which boosts coordination and response times. Additionally, PropertEase provides comprehensive reporting features that deliver detailed insights and analytics on property performance, financials, and maintenance, supporting informed decision-making. Its scalable design adapts to growing property portfolios and evolving management needs, ensuring it supports your organization's growth effectively.",
                                },
                                {
                                    question:
                                        "How can we get started with PropertEase by Sibasi?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },
                                {
                                    question:
                                        "How easy is it to integrate the data?",
                                    answer: "We can provide consulting services to connect your data to your source system(s), customize the reports, and automate the process in as little as 1-2 weeks.",
                                },
                                {
                                    question:
                                        "How much does PropertEase by Sibasi cost?",
                                    answer: "The application will retail for $35 per user per month for the out of the box functionality that comes with the system. Additional customizations shall be billed on a time and material basis at the cost of $60 per Hour",
                                },
                                {
                                    question:
                                        "What do I need to provide to receive a quote for implementation?",
                                    answer: "To receive a quote for implementing PropertEase by Sibasi, you'll need to provide several key details. Start by outlining the scope of implementation, including the specific features and modules you require, such as property management, tenant tracking, or maintenance requests. It's important to include the number of users who will be accessing the system, as this affects licensing and support costs. Additionally, provide information on the size of your property portfolio, including the number of properties and units you manage, as this can impact the complexity of the implementation. If you have any specific customization or integration needs with existing systems, be sure to mention these as well. Sharing your desired timeline for implementation and any deadlines you need to meet will help in planning the project effectively. A rough estimate of your budget will assist in tailoring the quote to fit your financial constraints. Lastly, provide details about any current property management systems or tools you use, as they may need to be integrated or replaced. With these details, you'll receive a comprehensive and accurate quote that aligns with your organization's needs.",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "saleshub",
                    title: "Sales Hub",
                    subCategory: [
                        {
                            id: "offers",
                            title: "Offers",
                            description:
                                "The offers feature in PropertEase allows you to track and manage tenant offers effortlessly. It provides a clear overview of submitted offers, their status, and helps you make informed decisions quickly, streamlining the leasing process.",
                            image: "OfferForm.png",
                            link: "",
                        },

                        {
                            id: "leases",
                            title: "Leases",
                            description:
                                "The leases feature in PropertEase simplifies lease management by organizing all lease agreements in one place. It tracks key details, renewal dates, and automates reminders, ensuring smooth lease execution and compliance throughout the rental term.",
                            image: "Leases.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does the PropertEase by Sibasi Benefit your organisation?",
                                    answer: "PropertEase by Sibasi offers significant benefits to your organization by streamlining property management through a centralized system that efficiently handles property details, listings, tenants, and maintenance requests. By automating tracking and reporting tasks, it reduces manual workload and minimizes errors, enhancing overall efficiency. The system also improves communication by facilitating seamless interaction between tenants, owners, and agents, which boosts coordination and response times. Additionally, PropertEase provides comprehensive reporting features that deliver detailed insights and analytics on property performance, financials, and maintenance, supporting informed decision-making. Its scalable design adapts to growing property portfolios and evolving management needs, ensuring it supports your organization's growth effectively.",
                                },
                                {
                                    question:
                                        "How can we get started with PropertEase by Sibasi?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },
                                {
                                    question:
                                        "How easy is it to integrate the data?",
                                    answer: "We can provide consulting services to connect your data to your source system(s), customize the reports, and automate the process in as little as 1-2 weeks.",
                                },
                                {
                                    question:
                                        "How much does PropertEase by Sibasi cost?",
                                    answer: "The application will retail for $35 per user per month for the out of the box functionality that comes with the system. Additional customizations shall be billed on a time and material basis at the cost of $60 per Hour",
                                },
                                {
                                    question:
                                        "What do I need to provide to receive a quote for implementation?",
                                    answer: "To receive a quote for implementing PropertEase by Sibasi, you'll need to provide several key details. Start by outlining the scope of implementation, including the specific features and modules you require, such as property management, tenant tracking, or maintenance requests. It's important to include the number of users who will be accessing the system, as this affects licensing and support costs. Additionally, provide information on the size of your property portfolio, including the number of properties and units you manage, as this can impact the complexity of the implementation. If you have any specific customization or integration needs with existing systems, be sure to mention these as well. Sharing your desired timeline for implementation and any deadlines you need to meet will help in planning the project effectively. A rough estimate of your budget will assist in tailoring the quote to fit your financial constraints. Lastly, provide details about any current property management systems or tools you use, as they may need to be integrated or replaced. With these details, you'll receive a comprehensive and accurate quote that aligns with your organization's needs.",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "propertieshub",
                    title: "Properties Hub",
                    subCategory: [
                        {
                            id: "properties",
                            title: "Properties",
                            description:
                                " The Properties module tracks essential details such as property type, location, size, ownership, rental agreements and maintenance schedules. It provides an organized view of property information to streamline management and reporting.",
                            image: "Properties View.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does the PropertEase by Sibasi Benefit your organisation?",
                                    answer: "PropertEase by Sibasi offers significant benefits to your organization by streamlining property management through a centralized system that efficiently handles property details, listings, tenants, and maintenance requests. By automating tracking and reporting tasks, it reduces manual workload and minimizes errors, enhancing overall efficiency. The system also improves communication by facilitating seamless interaction between tenants, owners, and agents, which boosts coordination and response times. Additionally, PropertEase provides comprehensive reporting features that deliver detailed insights and analytics on property performance, financials, and maintenance, supporting informed decision-making. Its scalable design adapts to growing property portfolios and evolving management needs, ensuring it supports your organization's growth effectively.",
                                },
                                {
                                    question:
                                        "How can we get started with PropertEase by Sibasi?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },
                                {
                                    question:
                                        "How easy is it to integrate the data?",
                                    answer: "We can provide consulting services to connect your data to your source system(s), customize the reports, and automate the process in as little as 1-2 weeks.",
                                },
                                {
                                    question:
                                        "How much does PropertEase by Sibasi cost?",
                                    answer: "The application will retail for $35 per user per month for the out of the box functionality that comes with the system. Additional customizations shall be billed on a time and material basis at the cost of $60 per Hour",
                                },
                                {
                                    question:
                                        "What do I need to provide to receive a quote for implementation?",
                                    answer: "To receive a quote for implementing PropertEase by Sibasi, you'll need to provide several key details. Start by outlining the scope of implementation, including the specific features and modules you require, such as property management, tenant tracking, or maintenance requests. It's important to include the number of users who will be accessing the system, as this affects licensing and support costs. Additionally, provide information on the size of your property portfolio, including the number of properties and units you manage, as this can impact the complexity of the implementation. If you have any specific customization or integration needs with existing systems, be sure to mention these as well. Sharing your desired timeline for implementation and any deadlines you need to meet will help in planning the project effectively. A rough estimate of your budget will assist in tailoring the quote to fit your financial constraints. Lastly, provide details about any current property management systems or tools you use, as they may need to be integrated or replaced. With these details, you'll receive a comprehensive and accurate quote that aligns with your organization's needs.",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "contactshub",
                    title: "Contacts Hub",
                    subCategory: [
                        {
                            id: "Tenants",
                            title: "Tenants, Owners, Agents",
                            description:
                                " The Tenants, Owners, and Agents entities track essential details such as renter information, property ownership records, and real estate agent data to streamline property management and communication.",
                            image: "Tenant Form.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does the PropertEase by Sibasi Benefit your organisation?",
                                    answer: "PropertEase by Sibasi offers significant benefits to your organization by streamlining property management through a centralized system that efficiently handles property details, listings, tenants, and maintenance requests. By automating tracking and reporting tasks, it reduces manual workload and minimizes errors, enhancing overall efficiency. The system also improves communication by facilitating seamless interaction between tenants, owners, and agents, which boosts coordination and response times. Additionally, PropertEase provides comprehensive reporting features that deliver detailed insights and analytics on property performance, financials, and maintenance, supporting informed decision-making. Its scalable design adapts to growing property portfolios and evolving management needs, ensuring it supports your organization's growth effectively.",
                                },
                                {
                                    question:
                                        "How can we get started with PropertEase by Sibasi?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },
                                {
                                    question:
                                        "How easy is it to integrate the data?",
                                    answer: "We can provide consulting services to connect your data to your source system(s), customize the reports, and automate the process in as little as 1-2 weeks.",
                                },
                                {
                                    question:
                                        "How much does PropertEase by Sibasi cost?",
                                    answer: "The application will retail for $35 per user per month for the out of the box functionality that comes with the system. Additional customizations shall be billed on a time and material basis at the cost of $60 per Hour",
                                },
                                {
                                    question:
                                        "What do I need to provide to receive a quote for implementation?",
                                    answer: "To receive a quote for implementing PropertEase by Sibasi, you'll need to provide several key details. Start by outlining the scope of implementation, including the specific features and modules you require, such as property management, tenant tracking, or maintenance requests. It's important to include the number of users who will be accessing the system, as this affects licensing and support costs. Additionally, provide information on the size of your property portfolio, including the number of properties and units you manage, as this can impact the complexity of the implementation. If you have any specific customization or integration needs with existing systems, be sure to mention these as well. Sharing your desired timeline for implementation and any deadlines you need to meet will help in planning the project effectively. A rough estimate of your budget will assist in tailoring the quote to fit your financial constraints. Lastly, provide details about any current property management systems or tools you use, as they may need to be integrated or replaced. With these details, you'll receive a comprehensive and accurate quote that aligns with your organization's needs.",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "serviceshub",
                    title: "Services Hub",
                    subCategory: [
                        {
                            id: "maintenance",
                            title: "Maintenance Request",
                            description:
                                "Maintenance Requests in the property management system track and manage reported issues requiring repair or attention. They include details such as the requester's information, issue description, priority level, status updates, and resolution history to ensure timely and effective property upkeep. ",
                            image: "Maintenance.png",
                            link: "",
                        },

                        {
                            id: "inspections",
                            title: "Inspections",
                            description:
                                "Inspections in the property management system record and manage property evaluations for maintenance, compliance, or quality assurance. They include details such as inspection dates, findings, recommendations, and follow-up actions to ensure properties meet required standards and remain in optimal condition.",
                            image: "Inspections.png",
                            link: "",
                        },
                    ],
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does the PropertEase by Sibasi Benefit your organisation?",
                                    answer: "PropertEase by Sibasi offers significant benefits to your organization by streamlining property management through a centralized system that efficiently handles property details, listings, tenants, and maintenance requests. By automating tracking and reporting tasks, it reduces manual workload and minimizes errors, enhancing overall efficiency. The system also improves communication by facilitating seamless interaction between tenants, owners, and agents, which boosts coordination and response times. Additionally, PropertEase provides comprehensive reporting features that deliver detailed insights and analytics on property performance, financials, and maintenance, supporting informed decision-making. Its scalable design adapts to growing property portfolios and evolving management needs, ensuring it supports your organization's growth effectively.",
                                },
                                {
                                    question:
                                        "How can we get started with PropertEase by Sibasi?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },
                                {
                                    question:
                                        "How easy is it to integrate the data?",
                                    answer: "We can provide consulting services to connect your data to your source system(s), customize the reports, and automate the process in as little as 1-2 weeks.",
                                },
                                {
                                    question:
                                        "How much does PropertEase by Sibasi cost?",
                                    answer: "The application will retail for $35 per user per month for the out of the box functionality that comes with the system. Additional customizations shall be billed on a time and material basis at the cost of $60 per Hour",
                                },
                                {
                                    question:
                                        "What do I need to provide to receive a quote for implementation?",
                                    answer: "To receive a quote for implementing PropertEase by Sibasi, you'll need to provide several key details. Start by outlining the scope of implementation, including the specific features and modules you require, such as property management, tenant tracking, or maintenance requests. It's important to include the number of users who will be accessing the system, as this affects licensing and support costs. Additionally, provide information on the size of your property portfolio, including the number of properties and units you manage, as this can impact the complexity of the implementation. If you have any specific customization or integration needs with existing systems, be sure to mention these as well. Sharing your desired timeline for implementation and any deadlines you need to meet will help in planning the project effectively. A rough estimate of your budget will assist in tailoring the quote to fit your financial constraints. Lastly, provide details about any current property management systems or tools you use, as they may need to be integrated or replaced. With these details, you'll receive a comprehensive and accurate quote that aligns with your organization's needs.",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        pricing: {
            title: "PropertEase pricing",
            tiers: [
                {
                    title: "Service pricing",
                    amount: "$35",
                    period: "Per user/month",
                    info: "Get complete PropertEase App, including step-by-step guides and remote expert assistance",
                },
            ],
        },
        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "fa fa-solid fa-bell",
                    title: "Chat with an expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
                {
                    icon: "fa fa-solid fa-phone",
                    title: "Request a call back",
                    description: "Have an expert contact you",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call an expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },
    {
        id: "onboarding",
        name: "Employee Onboarding/Offboarding by Sibasi",
        image: "Homepage.png",
        shortDescription: "Employee Onboarding/Offboarding Application",
        filters: ["Microsoft"],
        categories: [
            "Powerapps Canvas App",
            "Dataverse",
            "PowerAutomate",
            "Azure Blob Storage",
            "Rest AP",
        ],
        overview: {
            title: "Onboarding/Offboarding Application",
            description: "Employee Onboarding/Offboarding Application",
            categories: [
                {
                    id: "onboardingapplication",
                    title: "Employee Onboarding/Offboarding Application",
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does Onboarding/Offboarding by Sibasi benefit our organization?",
                                    answer: "Its streamlines processes, improves employee experience, and enhances departmental collaboration. It ensures compliance, boosts security, automates tasks, and provides insights to optimize workflows, saving time and increasing efficiency.",
                                },
                                {
                                    question:
                                        "How can we get started with Onboarding/Offboarding applicationb Application?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },

                                {
                                    question:
                                        "Can I customize tasks for different departments ?",
                                    answer: "Yes, tasks can be tailored to each department, such as IT, HR, or Operations, ensuring that each team completes their responsibilities during the onboarding or offboarding process.",
                                },
                                {
                                    question:
                                        "What data source is used in this application?",
                                    answer: "We are using SharePoint as the primary data source for the Onboarding/Offboarding Application. This allows for secure and centralized management of employee data, tasks, and documents.",
                                },

                                {
                                    question:
                                        "Can I customize the application to fit my organization’s needs?",
                                    answer: "The application can be customized to meet your organization’s specific requirements, including custom workflows, task lists, and notifications. Contact support for assistance with configuration.",
                                },
                            ],
                        },
                    ],
                    subCategory: [
                        {
                            id: "Onboarding/Offboarding",
                            title: "Application Overview ",
                            description:
                                "Our Onboarding/Offboarding Application is designed to streamline and enhance the employee lifecycle management process. Whether you're welcoming a new team member or managing an employee's departure, our application offers comprehensive tools and insights to ensure a smooth and efficient transition.  ",
                            image: "Employee-Dashboard.png",
                            link: "",
                        },
                    ],
                },
                {
                    id: "employee",
                    title: "Key Features",
                    faqs: [
                        {
                            groupName: "General",
                            questions: [
                                {
                                    question:
                                        "How does Onboarding/Offboarding by Sibasi benefit our organization?",
                                    answer: "Its streamlines processes, improves employee experience, and enhances departmental collaboration. It ensures compliance, boosts security, automates tasks, and provides insights to optimize workflows, saving time and increasing efficiency.",
                                },
                                {
                                    question:
                                        "How can we get started with Onboarding/Offboarding applicationb Application?",
                                    answer: "To get started, simply reach out to our team for a personalized demo and consultation.",
                                },

                                {
                                    question:
                                        "Can I customize tasks for different departments ?",
                                    answer: "Yes, tasks can be tailored to each department, such as IT, HR, or Operations, ensuring that each team completes their responsibilities during the onboarding or offboarding process.",
                                },
                                {
                                    question:
                                        "What data source is used in this application?",
                                    answer: "We are using SharePoint as the primary data source for the Onboarding/Offboarding Application. This allows for secure and centralized management of employee data, tasks, and documents.",
                                },

                                {
                                    question:
                                        "Can I customize the application to fit my organization’s needs?",
                                    answer: "The application can be customized to meet your organization’s specific requirements, including custom workflows, task lists, and notifications. Contact support for assistance with configuration.",
                                },
                            ],
                        },
                    ],
                    subCategory: [
                        {
                            id: "employeeoverview",
                            title: "Employee Overview",
                            description:
                                "View a detailed summary of all employees currently in the onboarding or offboarding phase. Easily track their status and progress. ",
                            image: "Employee-Overview.png",
                            link: "",
                        },
                        {
                            id: "offboardingmetrics",
                            title: "Offboarding Metrics",
                            description:
                                "Show statistics related to offboarding, such as the number of employees offboarded etc",
                            image: "Employee-Overview.png",
                            link: "",
                        },
                        {
                            id: "tasksstatus",
                            title: "Task Status",
                            description:
                                "Monitor the status of onboarding and offboarding tasks in real-time. Identify pending tasks,and completed actions to maintain workflow efficiency",
                            image: "TaskStatus.png",
                            link: "",
                        },
                        {
                            id: "alertsandnotifications",
                            title: "Alerts & Notifications",
                            description:
                                "Receive timely alerts and notifications for important tasks and updates",
                            image: "AssignedTasks.png",
                            link: "",
                        },
                        {
                            id: "roles",
                            title: "Role-Based Insights",
                            description:
                                "Access customized views and data relevant to your role within the organization. Whether you're in HR, IT, or a departmental manager, our application tailor’s information to meet your specific needs.",
                            image: "AssignedTasks.png",
                            link: "",
                        },
                    ],
                },
            ],
        },

        pricing: {
            title: "Onboarding/Offboarding pricing",
            tiers: [
                {
                    title: "Onboarding/Offboarding pricing​",
                    amount: "$3,000",
                    period: "Per app​",
                    info: "Get complete onboarding/offboarding app, including step-by-step guides and remote expert ",
                },
            ],
        },
        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "fas fa-solid fa-bell",
                    title: "Chat with an expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have an expert contact you",
                    link: "",
                },
                {
                    icon: "",
                    title: "Call an expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },
    {
        id: "lawyershub",
        name: "Lawyers Hub - A Legal Practice App by Sibasi",
        image: "LawyersHubBackground.png",
        shortDescription:
            "Lawyers Hub System is a comprehensive digital ecosystem designed to transform how legal professionals work. Imagine a platform where every detail, from client accounts to case files, appointments to billable hours, seamlessly integrates to create a unified, efficient, and intuitive experience..",
        categories: [
            "Powerapps Model-Driven App",
            "Dataverse",
            "PowerAutomate",
            "SharePoint",
        ],
        filters: ["Microsoft", "Power Platform"],
        overview: {
            title: "Lawyers Hub – Streamline Your Practice, Amplify Your Impact",
            description:
                "Lawyers Hub is a robust, all-in-one solution for managing various aspects of legal practice. It is designed to be intuitive and user-friendly, enabling law firms to handle cases, accounts, documents, and billable hours efficiently.",
            categories: [
                {
                    id: "dashboards",
                    title: "Dashboards & Reports",
                    subCategory: [
                        {
                            id: "dashboard_overview",
                            title: "Dashboard Overview",
                            description:
                                "Lawyers Hub dashboards offer an organized view of key performance indicators, active cases, upcoming appointments, and tasks. The dashboards provide insights at a glance, helping users prioritize actions effectively",
                            image: "Dashboard2.png",
                            link: "",
                        },
                    ],
                },

                {
                    id: "accounts",
                    title: "Customer Hub",
                    subCategory: [
                        {
                            id: "accountmodule",
                            title: "Accounts",
                            description:
                                "Account Management centralizes client profiles, including account history, payment status, and other essential information. This ensures accurate records, enhances client relationship management, and simplifies client communication",
                            image: "AccountForm.png",
                            link: "",
                        },
                    ],
                },

                {
                    id: "Activities",
                    title: "Activity Hub",
                    subCategory: [
                        {
                            id: "Calendar",
                            title: "Appointment Management",
                            description:
                                "Appointment Management allows users to schedule, track, and manage appointments such as client meetings and court dates. The feature also enables hours from appointments to populate timesheets automatically, helping maintain accurate billing records",
                            image: "AppointmentCalendarView.png",
                            link: "",
                        },

                        {
                            id: "Task",
                            title: "Task Management",
                            description:
                                "With Task Management, users can assign, track, and manage tasks related to each matter. Completed tasks can automatically populate timesheets, streamlining the tracking of billable hours.",
                            image: "MatterTaskDetail.png",
                            link: "",
                        },

                        {
                            id: "Timesheets",
                            title: "Timesheets",
                            description:
                                "Timesheet Management records billable hours by client, matter, or activity. Automated timesheet entries from completed tasks and appointments reduce errors, streamline billing, and maximize revenue capture",
                            image: "MyTimesheetsApproved.png",
                            link: "",
                        },
                    ],
                },

                {
                    id: "Legal",
                    title: "Legal Hub",
                    subCategory: [
                        {
                            id: "Matters",
                            title: "Matter Management",
                            description:
                                "Matter Management consolidates all case-related information in a single view, including timelines, case details, and team assignments. This feature improves case tracking, collaboration, and organization.",
                            image: "Matter.png",
                            link: "",
                        },

                        {
                            id: "DocumentManagement",
                            title: "Document Management",
                            description:
                                "Document Management stores, organizes, and retrieves case-related documents within a secure repository, integrated with Office 365 for seamless access. This integration promotes collaboration and compliance with data protection standards",
                            image: "MatterDocumentManagement.png",
                            link: "",
                        },

                        {
                            id: "Contracts",
                            title: "Contract Management",
                            description:
                                "Lawyers Hub’s Contract Management feature streamlines the entire contract lifecycle, from drafting and negotiation to approval and renewal. With real-time tracking, customizable status updates, and integrated reminders, it ensures every contract stays on schedule and compliant, enhancing visibility and control over legal agreements.",
                            image: "ContractManagementForm.png",
                            link: "",
                        },
                    ],
                },

                {
                    id: "Finance",
                    title: "Finance Hub",
                    subCategory: [
                        {
                            id: "Expensing",
                            title: "Expenses",
                            description:
                                " Expense Management allows expenses to be categorized by matter or client, with the option to associate expenses directly with invoice lines. This enhances cost transparency, supports accurate billing, and simplifies financial tracking.",
                            image: "MatterExpenseDetailed.png",
                            link: "",
                        },

                        {
                            id: "Invoicing",
                            title: "Invoices",
                            description:
                                "Invoice Management generates, tracks, and manages invoices, ensuring timely and accurate billing. This feature also allows customization of invoice formats to suit specific firm requirements.",
                            image: "LegalInvoice.png",
                            link: "",
                        },

                        {
                            id: "Budgets",
                            title: "Offers",
                            description:
                                "Budgeting helps allocate resources for each case, enabling firms to track expenses in real time and adhere to financial goals. This feature ensures cases remain within scope and budget",
                            image: "Budgeting.png",
                            link: "",
                        },
                    ],
                },

                {
                    id: "settings",
                    title: "Services Hub",
                    subCategory: [
                        {
                            id: "Taxes",
                            title: "Tax Configuration",
                            description:
                                "Tax Configuration allows firms to set up and apply tax rates easily across matters, invoices, and expenses. This feature enables legal practices to customize tax settings according to jurisdictional requirements, ensuring accurate and compliant tax calculations on billable items.",
                            image: "TaxConfiguration.png",
                            link: "",
                        },
                        {
                            id: "Countries",
                            title: "Country Configuration",
                            description:
                                " This feature ensures that legal practices operating in multiple jurisdictions can manage cases and financials accurately and adhere to localized regulations effortlessly",
                            image: "CountryConfiguration.png",
                            link: "",
                        },
                        {
                            id: "BudgetCategories",
                            title: "Budget Categories",
                            description:
                                "Budget Categories in Lawyers Hub allow firms to organize and allocate budgets across different areas, such as legal fees, research, travel expenses, and administrative costs. This feature helps practices track spending accurately, manage resources efficiently, and maintain financial control over each matter, ensuring budgets are adhered to and financial goals are met",
                            image: "BudgetCategories.png",
                            link: "",
                        },
                        {
                            id: "BudgetSubCategories",
                            title: "Budget Subcategories",
                            description:
                                "Budget Subcategories in Lawyers Hub provide a more granular breakdown within each main budget category, allowing firms to specify and monitor detailed expenditures, such as court filing fees under 'Legal Fees' or accommodation costs under 'Travel Expenses'. This level of detail helps practices gain deeper insights into spending patterns, optimize budget allocations, and maintain precise financial tracking for each matter.",
                            image: "BudgetSubCategories.png",
                            link: "",
                        },
                        {
                            id: "PracticeAreas",
                            title: "Practice Areas",
                            description:
                                "Practice Areas in Lawyers Hub allow firms to categorize and organize cases, clients, and resources based on specific areas of legal expertise, such as corporate law, family law, intellectual property, or litigation. This feature helps streamline case management by tailoring workflows, budgeting, and reporting to the unique requirements of each legal discipline, enhancing specialization and efficiency within the firm.",
                            image: "PracticeAreas.png",
                            link: "",
                        },

                        {
                            id: "UserProfiles",
                            title: "User Profiles",
                            description:
                                "In Lawyers Hub, the User Profile feature captures essential details such as the user’s designated practice area, their configured rate per hour for billing, and their reporting hierarchy. This setup ensures accurate billing, role clarity, and seamless allocation of cases within the firm, enhancing both internal organization and efficient resource management.",
                            image: "UserProfiles.png",
                            link: "",
                        },
                    ],
                },
            ],
        },
        pricing: {
            title: "Lawyers Hub pricing",
            tiers: [
                {
                    title: "Subscription",
                    amount: "$16",
                    period: "Per user/month",
                    info: "Ideal for small to medium-sized law firms or solo practitioners looking for an affordable, flexible solution with low upfront costs. Initial setup fee is $ 1,000.",
                },
                {
                    title: "One-off Deployment",
                    amount: "$10,000",
                    period: "One-off",
                    info: "Ideal for law firms that want a straightforward, one-time purchase without recurring subscription fees. Setup feeds included with limited customizations. New features available through paid upgrades",
                },
                {
                    title: "Custom Deployment",
                    amount: "Contact Us",
                    period: "One-off Pricing",
                    info: "Ideal for larger law firms or specialized practices that require a tailored solution to meet unique operational needs.Setup fees included with Ongoing access to developers for updates, integrations, and adjustments based on evolving needs leveraged through an annual technical support contract ",
                },
            ],
        },

        nextSteps: {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "fa fa-solid fa-bell",
                    title: "Chat with a Lawyers Hub expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have a Lawyers Hub expert contact you",
                    link: "",
                },
                {
                    icon: "fa fa-phone",
                    title: "Call a Lawyers Hub expert",
                    description:
                        "Available Mon to Fri 8am to 6pm East African Time",
                    link: "",
                },
            ],
        },
    },
    {
        id: "assetmanagement",
        name: "Asset Management - Asset Management App by Sibasi",
        image: "AssetManagement.png",
        shortDescription: "The Asset Management Application, built using Power Apps and SharePoint, is designed to help organizations effectively track and manage their assets. It provides a centralized platform for tasks such as registering new assets, tracking locations, assigning assets to users, processing maintenance requests, and managing disposals. This comprehensive solution ensures that all activities are accountable and comply with company policies, serving as a one-stop platform for efficient asset management.",
        categories: [
            "Powerapps Model-Canvas App",
            "PowerAutomate",
            "SharePoint"
        ],
        filters: [ "Microsoft", "Power Platform" ], // Available options: "Microsoft", "SharePoint", "Business Central", "Dynamics 365", "eBoard", "Power BI", "Monival"m "Power Platform"
        overview: {
            title: "Monitor Your Assets, Maximize Your Potential.",
            description: "The Asset Management Application simplifies the tracking and management of organizational assets. It provides a centralized platform for essential tasks such as asset registration, location tracking, user assignments, maintenance coordination, and disposal management. By integrating these functions into a single solution, the application enhances accountability, ensures compliance with company policies, and delivers an efficient, comprehensive approach to asset management.",
            categories: [
                {
                    id: "dashboards",
                    title: "Dashboards & Reports",
                    subCategory: [
                        {
                            id: "dashboard_overview",
                            title: "Dashboard Overview",
                            description: "The Reports and Insights feature delivers valuable data on asset performance, availability, maintenance history, and more. Management can see asset conditions and upcoming maintenance needs, helping them make informed decisions about asset replacements, upgrades, or disposals. Customizable dashboards provide a clear view of asset.",
                            image: "AdminDashboard.png",
                            link: ""
                        }
                    ]
                },
    
                {
                    id: "assetslist",
                    title: "Central Asset List",
                    subCategory: [
                        {
                            id: "assets",
                            title: "Assets list",
                            description: "The application provides a detailed, centralized list of all assets, capturing essential data like asset type, condition, purchase date, and location. This organized repository makes it easy to locate and manage assets, helping users keep track of all asset information in one place.",
                            image: "AssetList.png",
                            link: ""
                        }
                    ]
                },
    
                {
                    id: "AssetAssignment",
                    title: "Asset Assignment Tracking",
                    subCategory: [
                        {
                            id: "Assignments",
                            title: "Asset Assignment ",
                            description: "Assign assets to specific employees, ensuring clear accountability and transparency by linking each asset to the individual responsible for it. This feature provides a detailed record of asset assignments, making it easy to track ownership, reduce the risk of misplaced or unaccounted-for assets, and improve overall asset management. It also enables seamless updates when assets are reassigned, creating a reliable audit trail for organizational accountability.",
                            image: "AssetAssignment.png",
                            link: ""
                        },
    
                        {
                            id: "time",
                            title: "Real-Time Assignments",
                            description: "Instantly update asset records as they are assigned or reassigned to employees, ensuring that the system reflects the most accurate and up-to-date information. This feature provides immediate visibility into asset allocation, reduces delays in tracking ownership changes, and enhances operational efficiency. With real-time updates, managers can make informed decisions and maintain a reliable record of asset distribution across the organization.",
                            image: "Assigned.png",
                            link: ""
                        }
    
    
                    ]
                },
    
                {
                    id: "Reserve",
                    title: "Reservations",
                    subCategory: [
                        {
                            id: "ReservationFeature",
                            title: "Reservation",
                            description: "Enable users to seamlessly manage asset reservations with the flexibility to book as needed. This functionality ensures that assets are allocated efficiently, minimizing scheduling conflicts and optimizing resource availability. Users can check real-time asset availability, reserve items for specific periods, and receive automated updates and notifications to keep all stakeholders informed, fostering smooth workflows and maximizing asset utilization.",
                            image: "Reservation.png",
                            link: ""
                        }
    
    
                    ]
                },
    
                {
                    id: "access",
                    title: "Secure Access Control",
                    subCategory: [
                        {
                            id: "control",
                            title: "Admin Access",
                            description: "Provide administrators with full control over all application features, empowering them to manage assets, users, and settings comprehensively. This includes the ability to oversee asset registrations, track assignments, manage reservations, process maintenance requests, and handle disposals. Admins can also configure system settings, enforce policies, generate reports, and monitor real-time updates to ensure efficient asset management across the organization. With complete access, administrators can maintain oversight, resolve issues promptly, and ensure the platform operates smoothly.",
                            image: "AdminDashboard.png",
                            link: ""
                        },
    
                        {
                            id: "manager",
                            title: "Manager Access",
                            description: "Grant managers the ability to submit and manage asset requests within the system. This access allows them to approve or track requests for their teams and monitor the status of ongoing requests. Managers can view detailed information about requested assets, ensuring they can make informed decisions to support their teams’ operational needs. This level of access streamlines the asset request process while maintaining clear accountability and oversight.",
                            image: "Managers.png",
                            link: ""
                        },
    
                        {
                            id: "employee",
                            title: "Employee Access",
                            description: "Provide employees with the ability to view and manage the assets assigned to them. This access allows employees to track the status of their assigned assets, make requests, and reserve assets when needed. They can also request maintenance for their assets. This level of access fosters accountability, enhances communication, and supports employees in effectively managing their assets throughout their lifecycle.",
                            image: "UserDashboard.png",
                            link: ""
                        }
                    ]
                },
    
                {
                    id: "History",
                    title: "Action History",
                    subCategory: [
                        {
                            id: "action",
                            title: "Asset History",
                            description: "All actions taken within the application, including asset assignments, modifications, and other updates, are automatically logged in the Action History. This ensures full accountability and transparency by providing a comprehensive record of all activities. The Action History allows administrators and managers to track changes, review decision-making processes, and maintain a clear audit trail, ensuring that every action is documented for future reference and compliance purposes.",
                            image: "AssetHistory.png",
                            link: ""
                        }
                    ]
                }
            ]
        },
        pricing: {
            title: "Asset Management pricing",
            tiers: [
                {
                    title: "Subscription",
                    amount: "$3,500",
                    period: "Per App",
                    info: "Get complete Asset Management Application, including step-by-step guides and remote expert"
                }
    
            ]
        },
    
        "nextSteps": {
            title: "",
            description: "",
            link: "",
            image: "",
            step: [
                {
                    icon: "fa fa-solid fa-bell",
                    title: "Chat with Asset Management expert",
                    description: "Available Mon to Fri 8am to 6pm East African Time",
                    link: ""
                },
                {
                    icon: "",
                    title: "Request a call back",
                    description: "Have Asset Management expert contact you",
                    link: ""
                },
                {
                    icon: "fa fa-phone",
                    title: "Call Asset Management expert",
                    description: "Available Mon to Fri 8am to 6pm East African Time",
                    link: ""
                }
            ]
        }
    }
];

import { ISolution } from "src/app/models/solution.model";

export const SolutionsOfferedConst: ISolution[] = [
    {
        title: "Monitoring & Evaluation (M&E) - Monival™",
        showRequestDemoBtn: true,
        shortDescription:
            "Sibasi's Monival™ is a powerful M&E solution that helps organizations measure and improve performance. Get actionable insights to drive better decision-making.",
        descriptions: [
            "Monival™, developed by Sibasi, offers an advanced M&E solution that enhances organizational performance. Its comprehensive features enable strategic decision-making, resource optimization, and project success demonstration.",
            "Streamline your project management with Monival's robust Monitoring & Evaluation software. Efficiently track project progress, assess performance, and ensure successful outcomes through an intuitive platform.",
            "Utilize Monival's user-friendly tools to gain actionable insights from data analysis and reporting. Measure outcomes, identify improvement areas, and make informed decisions to maximize project impact effectively.",
            "Monival™ provides a comprehensive suite for Monitoring and Evaluation, designed to enhance organizational performance. Track KPIs, analyze data, and generate insightful reports to support decision-making and resource allocation.",
            "Ensure transparency and accountability with Monival™ by leveraging real-time data and industry-standard compliance features. Empower stakeholders with the insights needed to achieve program goals and optimize resources.",
            "Choose Monival™ for a reliable and efficient M&E solution that drives organizational success, offering powerful tools for data collection, analysis, and reporting to meet your specific needs.",
        ],
        icon: "",
        images: ["monival.png"],
        id: "monival",
        externalLink: "https://monival.com",
        menuItemName: "Monival",
        pricing: [
            {
                tier: "Standard",
                description: "Access essential M&E features.",
                from: true,
                price: "$5,000",
                period: "per year",
                features: [
                    "Projects Management",
                    "Manage Phases, Milestones, Activities, Tasks, Deliverables",
                    "Issues and Tasks Tracking",
                    "Basic Team Management",
                    "Basic Reporting and Visualization",
                    "Beneficiary Management",
                    "Proposal Management",
                    "Manage Performance Contract & Strategic Plans",
                    "Donor Management & Donor Access",
                    "GIS Integration",
                    "Inventory Management",
                    "Basic Support",
                    "Cloud-Hosted Only",
                ],
            },
            {
                tier: "Premium",
                description: "Access advanced M&E features.",
                from: true,
                price: "$25,000",
                period: "per year",
                features: [
                    "All features in Standard plan, plus:",
                    "Data Collection and Field Teams Mobile Apps",
                    "Grants Management",
                    "Advanced Reporting and Visualization",
                    "Advanced Data Analysis",
                    "Volunteers Management",
                    "Employees Timesheets and Leave Management",
                    "Budget Tracking, Expenses, Targets and Actuals",
                    "Manage Performance Contract & Strategic Plans",
                    "M&E and Reporting Frameworks including OKRs, LogFrames, Results Based Management",
                    "Integration Ready (Add-on)",
                    "AI Infused Reports Generation (Add-on)",
                    "Cloud-Hosted or Self-Hosted Option",
                    "Priority Support",
                    "Perpetual licensing available for self hosted plans",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a customized solution to match your unique needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "All features in the other plans, plus:",
                    "Bespoke Data Modeling",
                    "Integration with External Data Sources",
                    "Integration with CRM, ERP, HRM, Accounting Systems",
                    "Dedicated Implementation and Delivery Teams",
                    "Dedicated Support",
                    "Customizable Dashboards and Reports",
                    "Custom GIS Mapping",
                    "Custom Mobile Apps",
                    "Custom Workflows",
                    "E-Signing",
                    "Performance Appraisals",
                    "Portfolio Management",
                    "Stakeholder Management",
                    "Evidence-Based Reporting",
                    "Donor Access",
                    "Contracts & Addendums Management",
                    "Public and Secured API Access Enabled",
                    "AI infused Reports Generation",
                ],
            },
        ],
    },
    {
        title: "EBM Suite - Business Management Software",
        showRequestDemoBtn: true,
        shortDescription:
            "The #1 business management application for small and medium-sized enterprises, featuring POS, invoicing, inventory, customer management, and comprehensive business reporting.",
        descriptions: [
            "Transform your business operations with EBM Suite's all-in-one management software. Streamline sales, inventory, financial tracking, and reporting through a unified platform.",
            "Leverage EBM Suite’s real-time insights and data analytics to make informed decisions, identify growth opportunities, and adapt proactively to market trends.",
            "Developed by Sibasi, EBM Suite offers a user-friendly interface that enhances operational efficiency, boosts profitability, and supports sustained growth.",
            "Manage finance, HR, sales, and customer service efficiently from a single platform with EBM Suite, ensuring seamless integration and optimized performance.",
            "Enhance team collaboration and communication while increasing productivity and reducing operational costs with EBM Suite’s robust features.",
            "Scale your business effortlessly with EBM Suite’s adaptable and scalable management solution, designed to support your growth and operational excellence.",
            "Optimize business processes with EBM Suite's comprehensive solution, integrating finance, operations, and human resources in one powerful platform.",
            "Drive business growth and efficiency with EBM Suite’s advanced resource allocation, cost management, and real-time analytics.",
        ],
        icon: "",
        images: ["ebmsuite.png"],
        id: "ebmsuite",
        externalLink: "https://ebmsuite.com",
        menuItemName: "EBM Suite",
        pricing: [
            {
                tier: "Starter",
                description:
                    "Access essential EBM Suite features to streamline your business operations at no cost.",
                price: "Free",
                period: "",
                features: [
                    "Basic Customer Management",
                    "Invoice & Quote Generation",
                    "Point of Sale (POS)",
                    "Basic Inventory Tracking",
                    "Limited number of transactions per month",
                    "Basic Expense Management",
                    "Basic Community Support",
                ],
            },
            {
                tier: "Premium",
                description:
                    "Unlock advanced business management features with the Premium plan for just $15 per month.",
                price: "$15",
                period: "per month per store",
                features: [
                    "Comprehensive Customer Management",
                    "Unlimited number of transactions",
                    "Custom Branding on Invoicing, Quotes & Receipts",
                    "Advanced Invoicing and Inventory Management",
                    "Advanced Reports and Analytics",
                    "Upload Files e.g., item images and customer files",
                    "Payment Gateway Integration (MPESA, Card)",
                    "Permissions and User Roles management",
                    "Optional Add-ons Available",
                    "Phone and Email Support",
                ],
            },
            {
                tier: "Special Add-ons",
                description:
                    "Based on your business needs, you can choose from a set of extensions and add-ons that provide specialized functionality.",
                price: "Contact Us",
                period: "",
                features: [
                    "Premium tier is required then select from add-ons:",
                    "Hotel Bookings and Reservations",
                    "Online Shop (Website)",
                    "Own Branded Mobile App (Android and iOS)",
                    "Branded SMS and Emails with your branding",
                    "Asset Management",
                    "Equipment Bookings Management",
                    "Point of Sale Hardware & Servicing",
                    "Delivery Management",
                    "Human Resource Management",
                    "Field Team App",
                    "On-Site Training, Setup and Configuration",
                    "Kitchen Display",
                    "Advanced Backend Dashboard",
                    "Multiple Store Management and Reporting",
                    "Integrated Cross-Organizational Analytics",
                    "Connect with Business Advisory Teams, Investors, Suppliers or Distributors",
                    "Suppliers Portal (Vendor management and direct re-ordering)",
                    "Customer Loyalty Program",
                    "Tax Integration",
                    "Integration to your existing systems",
                    "Priority Support and Dedicated Account Manager",
                ],
            },
        ],
    },
    {
        title: "eBoard",
        showRequestDemoBtn: true,
        shortDescription:
            "eBoard is a comprehensive board management system designed to optimize board meetings, enhance communications, and streamline document management with cutting-edge features and robust security.",
        descriptions: [
            "eBoard is a powerful, technology-driven tool that empowers boards of directors, executives, and administrators to streamline governance processes with ease and efficiency.",
            "Key Features:",
            "Meeting Management: Schedule, plan, and organize board meetings effortlessly using intuitive calendars and automated reminders.",
            "Document Management: Centralize all important documents, agendas, minutes, and reports in a secure digital repository for seamless access and collaboration.",
            "Secure Communication: Protect confidential board communications with secure messaging and file-sharing capabilities.",
            "Voting and Approval: Simplify decision-making through electronic voting and approval workflows.",
            "User Access Control: Implement strict access controls with role-based permissions to manage who can view or edit specific documents and features.",
            "Compliance Tracking: Ensure adherence to regulatory compliance and governance standards with integrated tracking and reporting tools.",
            "E-Signing: Easily sign important documents with integrated electronic signature capabilities.",
            "Task Management: Efficiently assign, track, and manage tasks to ensure timely completion and accountability.",
            "Board Pack Management: Compile and distribute board packs containing all necessary documents and information for board members with ease.",
            "Business Reports: Generate detailed business reports to gain insights and support data-driven decision-making.",
            "Committees: Manage various committees within the board, ensuring effective coordination and communication.",
            "Enhance board governance, improve collaboration, and ensure compliance with eBoard’s advanced features and user-friendly interface.",
            "Choose eBoard for a seamless, secure, and efficient board management experience.",
        ],
        icon: "",
        images: ["eboard.png"],
        id: "electronic_board",
        externalLink: "",
        menuItemName: "eBoard",
        pricing: [
            {
                tier: "Per Seat",
                price: "$18",
                period: "/user/month",
                from: true,
                description:
                    "Perfect for startups, this plan includes essential collaboration and document management tools with cost-effective pricing and a user-friendly interface.",
                features: [
                    "Annual subscription: $180/user/year",
                    "1GB storage",
                    "Support and maintenance included",
                    "No setup costs",
                    "Ideal for small teams",
                    "Basic meeting and document management",
                    "Task and action point tracking",
                    "Affordable and easy to scale",
                ],
            },
            {
                tier: "Per Organization",
                price: "$5,000",
                period: "per year",
                from: true,
                description:
                    "Ideal for small to mid-sized organizations, offering enhanced collaboration, advanced security, priority support, and scalable options.",
                features: [
                    "Annual subscription: $5,000/year",
                    "Unlimited users",
                    "50GB storage",
                    "Priority support and maintenance included",
                    "Discounted setup costs ($2,000)",
                    "Advanced meeting and document management",
                    "Committees and Groups Management",
                    "Optional add-ons: Premium AI, eSigning, integrations",
                    "Integrations with popular tools (Zoom, Teams, etc.)",
                ],
            },
            {
                tier: "Perpetual & On-Premise",
                price: "$30,000",
                period: "one-time",
                from: true,
                description:
                    "Designed for large organizations needing extensive customization, advanced security, and flexible on-premise hosting.",
                features: [
                    "One-time cost: $30,000",
                    "Unlimited users",
                    "Storage dependent on client server",
                    "Customization: $4,000 for 50 hours",
                    "Enterprise-level security and compliance",
                    "Dedicated account management",
                    "Optional add-ons tailored to specific needs",
                    "Integration with ERP, CRM, HRM systems",
                    "Self-hosted or cloud-hosted option",
                ],
            },
        ],
    },
    {
        title: "Electronic Document Management System (EDMS)",
        showRequestDemoBtn: true,
        shortDescription:
            "Securely manage and organize your documents with Sibasi's EDMS. Improve efficiency, reduce costs, and enhance compliance. Experience the benefits of a paperless office.",
        descriptions: [
            "Transform your document management with our Electronic Document Management System (EDMS). Move beyond paper-based systems and embrace a digital solution that boosts organizational efficiency and control.",
            "Our EDMS facilitates the seamless creation, storage, organization, and retrieval of documents. Enjoy comprehensive features such as document capture, indexing, version control, and access permissions—all designed to streamline your document handling processes.",
            "Enable smooth collaboration whether your team is on-site or remote. Our system ensures everyone has real-time access to the latest documents, enhancing teamwork and productivity.",
            "Our EDMS is engineered to increase productivity, reduce errors, and improve compliance. Embark on a digital transformation journey with a solution that offers robust document management capabilities.",
            "Securely manage and store electronic documents, reducing the need for physical storage and minimizing paper consumption. Our EDMS ensures your documents are safe and easily accessible.",
            "Efficiently organize, search, and retrieve documents with advanced indexing and retrieval features, designed to enhance your document management workflows. Save time and reduce frustration with our intuitive system.",
            "Boost document accessibility and collaboration within your team with a system that supports efficient sharing and updates. Empower your team with the tools they need to work together seamlessly.",
            "Reduce paper usage and save physical storage space by transitioning to a digital document management solution. Our EDMS helps you go green and stay organized.",
            "Enhance your compliance and security with integrated document retention policies that adhere to regulatory requirements. Keep your organization compliant and your data secure.",
            "Streamline your document workflows to improve overall productivity with our comprehensive EDMS features. Automate repetitive tasks and focus on what matters most.",
            "Simplify and secure document handling with our EDMS, providing reliable storage and access to critical documents from any location. Work confidently knowing your documents are always within reach.",
            "Ensure your organization meets compliance standards and maintains data security with our advanced EDMS features. Protect sensitive information and stay ahead of regulatory changes.",
            "Foster better collaboration and information sharing across teams with a system that supports seamless document access and updates. Enhance communication and drive success with our EDMS.",
        ],

        icon: "",
        images: ["edms.png"],
        id: "edms",
        externalLink: "",
        menuItemName: "EDMS",
        pricing: [
            {
                tier: "Basic Deployment",
                description:
                    "Access essential EDMS features for efficient document storage and retrieval.",
                price: "$5,000",
                period: "",
                from: true,
                features: [
                    "Document Storage",
                    "Basic Search and Retrieval",
                    "Indexing",
                    "Version Control",
                    "Metadata",
                    "Requires Microsoft SharePoint licenses",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard Deployment",
                description:
                    "Access essential EDMS features for efficient document storage and retrieval.",
                price: "$25,000",
                period: "",
                from: true,
                features: [
                    "All Basic Deployment Tier Features, plus:",
                    "Workflows Automation",
                    "Basic OCR (Add-on)",
                    "Standard Document Workflows",
                    "Requires SharePoint licenses",
                    "E-Signing can be integrated (Add-on)",
                    "Audit Trails and Reporting",
                    "Email Integration",
                    "Document Approval Processes",
                    "Standard Support",
                ],
            },
            {
                tier: "Premium Deployment",
                description:
                    "Tailor a custom plan to match your specific document management and integration requirements.",
                price: "$65,000",
                period: "",
                from: true,
                features: [
                    "All Standard Deployment Tier Features, plus:",
                    "Workflows Automation",
                    "Advanced Configuration",
                    "AI Ready for Classification & Tagging",
                    "Document Generation",
                    "Digitization and Data Migration (dependent on content size)",
                    "Integration with Other Business Systems (e.g., CRM, ERP)",
                    "Automated Document Retention Policies",
                    "Advanced Security and Compliance features",
                    "Integration with ERP and CRM Systems",
                    "Requires SharePoint licenses",
                    "Dedicated Support",
                ],
            },
        ],
    },
    {
        title: "Customer Relationship Management (CRM)",
        showRequestDemoBtn: true,
        shortDescription:
            "Build stronger customer relationships with Sibasi's CRM solutions. Manage sales, marketing, and customer support effectively. Increase customer satisfaction and loyalty.",
        descriptions: [
            "Strengthen your relationships with customers using our Customer Relationship Management (CRM) solutions. Our platform empowers you to manage leads, track interactions, and enhance customer satisfaction.",
            "Streamline sales, marketing, and support processes with our CRM. Improve customer engagement and retention with tools designed to build lasting connections and drive business growth.",
            "Experience the benefits of efficient customer management with our CRM solutions. Gain insights into customer behavior and preferences to deliver personalized experiences and boost satisfaction.",
            "Enhance your sales efficiency with effective lead management and pipeline tracking. Increase your revenue and customer loyalty by optimizing your sales processes.",
            "Improve customer service and retention with timely and relevant communications. Our CRM supports a customer-centric approach that drives growth and profitability.",
            "Track sales opportunities, manage customer data, and improve satisfaction with our powerful CRM tools. Make data-driven decisions to optimize customer engagement and enhance your marketing campaigns.",
            "Elevate your marketing campaigns and lead generation efforts with our CRM. Improve your customer service and support to foster better relationships and drive success.",
            "Optimize customer interactions and relationships with our CRM. Experience a robust platform designed to enhance every aspect of customer management, from initial contact to ongoing support.",
        ],
        icon: "",
        images: ["crm.png"],
        id: "crm",
        externalLink: "",
        menuItemName: "CRM",
        pricing: [
            {
                tier: "Basic Deployment",
                description:
                    "Access essential CRM features for managing customer relationships and sales.",
                price: "$5,000",
                from: true,
                period: "",
                features: [
                    "Contact Management",
                    "Basic Sales Pipeline",
                    "Lead and Opportunity Tracking",
                    "Mobile Access",
                    "Requires Microsoft Dynamics 365 licenses",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard Deployment",
                description:
                    "Access essential CRM features for managing customer relationships and sales.",
                price: "$25,000",
                from: true,
                period: "",
                features: [
                    "All Basic Deployment Tier Features, plus:",
                    "Advanced Sales Pipeline",
                    "Customer Service: Track cases, manage service requests",
                    "Customized CRM Workflows",
                    "Requires Microsoft Dynamics 365 licenses",
                    "Advanced Lead Tracking",
                    "Advanced Support",
                ],
            },
            {
                tier: "Premium Deployment",
                description:
                    "Tailor a custom plan to match your specific CRM and integration needs.",
                price: "$65,000",
                from: true,
                period: "",
                features: [
                    "All Standard Deployment Tier Features, plus:",
                    "AI-powered insights",
                    "Unified platform",
                    "Integration with Call Center Systems",
                    "Field Service: Schedule and dispatch field agents, manage work orders",
                    "Integration with other systems including ERP, CRM, HRM",
                    "Customer Insights: Analyze customer data, predict behavior",
                    "Custom Portals (public or internal)",
                    "Custom Reports and dashboards",
                    "Dedicated Support",
                    "Custom Sales Automation",
                ],
            },
        ],
    },
    {
        title: "ERP - Dynamics Business Central",
        showRequestDemoBtn: true,
        shortDescription:
            "Optimize your business operations with our ERP (Dynamics Business Central) solution. Get real-time insights, improve financial management, and streamline processes.",
        descriptions: [
            "Streamline and optimize your business operations with our ERP - Business Central solution. This platform offers integrated tools for finance, sales, supply chain, and more, providing a comprehensive approach to managing your business.",
            "Gain real-time insights into your business performance with ERP - Business Central. Automate processes and improve decision-making with a solution designed to drive growth and enhance operational efficiency.",
            "Experience the benefits of a tailored ERP solution that addresses your specific business needs. With Business Central, integrate core functions like finance, sales, and supply chain for a unified management experience.",
            "Business Central provides a complete ERP solution that seamlessly manages core business processes. Enhance operational efficiency and reduce costs by integrating finance, sales, operations, and customer service.",
            "Optimize inventory management and streamline operations with Business Central. Improve efficiency and control while adapting to changing business needs with our flexible ERP solution.",
            "Gain real-time visibility into your business performance and make informed decisions with advanced analytics. Business Central offers the tools to enhance decision-making and drive success.",
            "Transform your business operations with Business Central. Integrate key functions for greater efficiency and control, and scale your operations effectively to meet evolving demands.",
            "Choose Business Central for a unified approach to managing finance, supply chain, and operations. Improve your business processes and reduce operational costs with our comprehensive ERP solution.",
        ],
        icon: "",
        images: ["erp.png"],
        id: "erp_business_central",
        externalLink: "",
        menuItemName: "ERP - Business Central",
        pricing: [
            {
                tier: "Basic Deployment",
                description:
                    "Access essential ERP features for managing your business operations.",
                price: "$6,500",
                period: "",
                from: true,
                features: [
                    "Financial Management",
                    "Sales and Customer Management",
                    "Very basic customizations",
                    "Dynamics 365 Business Central Cloud only",
                    "Out of box reports",
                    "Licenses sold separately",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard Deployment",
                description:
                    "Advanced ERP features for managing your business operations with customizations.",
                price: "$12,500",
                period: "",
                from: true,
                features: [
                    "All Basic Tier Features, plus:",
                    "Enhanced customization",
                    "Assets Management",
                    "Human Resource Management (including Payroll, Leave and Team Management)",
                    "Advanced Reports and Analytics",
                    "Dynamics 365 Business Central On-Prem or Cloud",
                    "Licenses sold separately",
                    "Standard Support",
                ],
            },
            {
                tier: "Premium Deployment",
                description:
                    "Tailor a custom solution to match your specific ERP and business management needs.",
                price: "$80,000",
                period: "",
                from: true,
                features: [
                    "Custom Workflow Automation",
                    "Custom Portals (public or internal)",
                    "Integration with other systems including CRM, ETIMS, HRM, Accounting Systems",
                    "Integration with Banks",
                    "Inventory management",
                    "Projects Management",
                    "Supply Chain Management",
                    "Operations Management",
                    "Dynamics 365 Business Central On-Prem or Cloud",
                    "Licenses sold separately",
                    "Dedicated Support",
                ],
            },
        ],
    },
    {
        title: "Business Intelligence & Reporting",
        showRequestDemoBtn: true,
        shortDescription:
            "Unlock valuable insights and streamline reporting with our Business Intelligence & Reporting solutions. Make data-driven decisions to drive growth.",
        descriptions: [
            "Empower your organization with data-driven decision-making using our Business Intelligence & Reporting solutions. Collect, analyze, and visualize data to transform it into actionable insights and drive business growth.",
            "Our comprehensive reporting tools provide dashboards and reports that enhance your understanding of business performance. Identify trends, make informed decisions, and drive strategic initiatives with ease.",
            "Collaborate with our experts to design custom BI solutions tailored to your unique needs. Harness the power of data to stay ahead of the competition and optimize your business strategies.",
            "Unlock actionable insights and drive strategic decision-making with Sibasi's Business Intelligence & Reporting tools. Visualize data trends and performance metrics to gain clear business insights.",
            "Empower your teams with self-service analytics and reporting capabilities. Improve operational efficiency, identify growth opportunities, and make data-driven decisions to enhance business performance.",
            "Transform your data into actionable insights with our Business Intelligence & Reporting solution. Discover trends, patterns, and opportunities to optimize your strategies and share valuable insights with stakeholders.",
            "Create interactive dashboards and detailed reports that provide clear and actionable insights. Use our BI solutions to make informed decisions and drive business growth effectively.",
        ],
        icon: "",
        images: ["business-intelligence.png"],
        id: "bi_reporting",
        externalLink: "",
        menuItemName: "Business Intelligence",
        pricing: [
            {
                tier: "Basic Deployment",
                description:
                    "Access essential Business Intelligence & Reporting features for data analysis and reporting.",
                price: "$2,000",
                from: true,
                period: "",
                features: [
                    "Basic Power BI setup",
                    "Reports developed for a single use case",
                    "Integration with core data sources (e.g., Excel, SQL Server)",
                    "Data Visualization",
                    "Basic user training and documentation",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard Deployment",
                description:
                    "Access essential Business Intelligence & Reporting features for data analysis and reporting.",
                price: "$8,000",
                from: true,
                period: "",
                features: [
                    "All Basic Tier features, plus:",
                    "Advanced Data Visualization",
                    "Advanced Data Modelling",
                    "Interactive dashboards and advanced analytics",
                    "Integration with multiple data sources (e.g., cloud services, databases)",
                    "AI ready deployment",
                    "Custom report development",
                    "Standard Support",
                ],
            },
            {
                tier: "Premium Deployment",
                description:
                    "Tailor a custom plan to match your specific business intelligence and reporting requirements.",
                price: "$72,000",
                from: true,
                period: "",
                features: [
                    "All Premium Tier features, plus:",
                    "Custom Data Dashboards and Reporting",
                    "Advanced analytics and AI integration (e.g., machine learning models, natural language queries)",
                    "Integration into comprehensive data warehousing and ETL (Extract, Transform, Load) processes",
                    "Enterprise-level custom report development and dashboard design",
                    "Extensive user training and change management",
                    "Dedicated Support",
                ],
            },
        ],
    },
    {
        title: "Intranet Solution: Empower Internal Communication",
        showRequestDemoBtn: true,
        shortDescription:
            "Sibasi's Intranet solution provides a robust platform designed to enhance internal communication, collaboration, and information sharing within your organization. Foster a connected workplace and improve employee engagement.",
        descriptions: [
            "Our Intranet Solution empowers employees and teams by providing a centralized hub for information access and collaboration. Enhance internal communication, document management, and task tracking all in one place.",
            "Facilitate seamless communication across departments with secure messaging, discussion forums, and announcements. Improve internal connectivity and keep everyone informed and engaged.",
            "Streamline document sharing and version control with a secure digital repository for important files, policies, and procedures. Ensure that your documents are accessible and up-to-date.",
            "Access contact information and organizational charts effortlessly with our employee directory. Foster teamwork and transparency within your organization.",
            "Assign, track, and manage tasks, projects, and deadlines efficiently to boost productivity and accountability. Stay organized and ensure timely project completion.",
            "Tailor dashboards to individual preferences with customizable options. Display relevant updates, news, and key performance indicators to keep employees informed and engaged.",
            "Ensure data security with role-based permissions, authentication, and data encryption. Protect sensitive information and control access to your company's resources.",
            "Foster a connected workplace with Sibasi's customizable Intranet Solution. Centralize information sharing and access, and create a cohesive corporate culture.",
            "Enhance internal communication and collaboration across your organization. Improve employee engagement and streamline organizational processes with interactive features.",
            "Boost productivity and create a cohesive corporate culture with our Intranet Solution. Centralize company resources and streamline processes for a more efficient workplace.",
        ],
        icon: "",
        images: ["sharepoint.png"],
        id: "intranet_solution",
        externalLink: "",
        menuItemName: "Intranet",
        pricing: [
            {
                tier: "Basic Deployment",
                description:
                    "Small businesses or departments with basic intranet needs.",
                price: "$1,600",
                period: "",
                from: true,
                features: [
                    "Company News & Announcements",
                    "Basic Document Library",
                    "Team Sites (up to 5)",
                    "Basic Branding",
                    "Enhanced Communication and Collaboration Tools",
                    "Cloud-Hosted Only (Microsoft SharePoint Online)",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard Deployment",
                description:
                    "Essential features for small and growing organizations looking to improve internal communication and collaboration among the team.",
                price: "$15,000",
                period: "",
                from: true,
                features: [
                    "All Basic Tier features, plus:",
                    "Internal Communication Tools and Document Sharing",
                    "Advanced Branding",
                    "Task Management",
                    "Workflow automation with Power Automate (formerly Flow)",
                    "Document Version Control",
                    "Standard Customization",
                    "Cloud-Hosted Only (Microsoft SharePoint Online)",
                    "Standard Support",
                ],
            },
            {
                tier: "Premium Deployment",
                description:
                    "Tailor a solution to meet the unique needs of large corporations and enterprises with complex communication and collaboration requirements.",
                price: "$65,000",
                period: "",
                from: true,
                features: [
                    "Customized Features and Integration Options",
                    "Intranet and Extranet Capabilities",
                    "Enterprise content management, advanced search, and analytics",
                    "Comprehensive branding and UI/UX design",
                    "Integration with external systems and databases",
                    "Integration with enterprise content management (ECM) system",
                    "Advanced Task Management",
                    "Premium reporting",
                    "Document Generation capabilities",
                    "Dedicated Account Manager",
                    "Enhanced Security and Access Control",
                    "Cloud-Hosted (Microsoft SharePoint Online) or Self-Hosted (SharePoint Server)",
                    "Enterprise-level Support and Training",
                ],
            },
        ],
    },
    {
        title: "E-Learning Platform",
        showRequestDemoBtn: true,
        shortDescription:
            "Revolutionize your educational experience with the best, open source and widely used E-Learning platforms provided by Sibasi.",
        descriptions: [
            "Embrace the future of education with our innovative E-Learning platform. Transition from traditional classrooms to a dynamic, interactive learning environment that offers flexibility and engagement.",
            "Our E-Learning platform allows students and educators to collaborate, share ideas, and participate in interactive lessons from any location—be it in a physical classroom or remotely.",
            "Create, access, and share educational materials effortlessly with our platform. Facilitate real-time interactions, foster creativity, and elevate the learning experience with cutting-edge technology.",
            "Empower your institution with our E-Learning Platform, designed to make learning engaging, accessible, and effective. Join us in revolutionizing the education landscape and shaping the future of learning.",
            "Transform learning experiences with Sibasi's E-Learning Platform. Deliver engaging and interactive training programs that enhance learning outcomes for students and employees alike.",
            "Track learner progress and performance with advanced analytics. Our platform provides valuable insights to improve educational strategies and outcomes.",
            "Customize learning content to align with your organizational goals and objectives. Tailor training programs to meet specific needs and drive continuous development within your organization.",
            "Choose Sibasi's E-Learning Platform for a modern and effective learning experience. Support ongoing education and skill development with our comprehensive online learning solutions.",
            "Our E-Learning Platform is a powerful tool for delivering online education and training through Massive Open Online Courses (MOOCs). Leverage the power of MOOCs to reach and educate a broader audience.",
        ],
        icon: "",
        images: ["elearning.png"],
        id: "elearning",
        externalLink: "",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential E-Learning Platform features for online education and training.",
                price: "$3,600",
                from: true,
                period: "",
                features: [
                    "Basic setup with no customization of the interfaces or other Open edX™ modules",
                    "1 month handholding included",
                    "Training on course setup",
                    "Setup database backups",
                ],
            },
            {
                tier: "Standard",
                description:
                    "Upgrade to our Standard plan for advanced e-learning capabilities and engagement.",
                from: true,
                price: "$7,000",
                period: "",
                features: [
                    "Everything in Basic Setup plus",
                    "Custom branding of the interface, certificates setup, integration with supported Authentication providers",
                    "3 months support and maintenance",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to suit your specific e-learning and training requirements.",
                price: "Contact Us",
                period: "",
                features: [
                    "Everything in Standard plus",
                    "Custom integration to existing systems",
                    "Development of custom modules",
                    "Mobile apps development and deployment",
                    "eCommerce & Analytics",
                ],
            },
        ],
    },
    {
        title: "HR System (Human Resources)",
        showRequestDemoBtn: true,
        shortDescription:
            "Simplify HR processes with Sibasi's Human Resources System. Manage employee data, payroll, benefits, and talent acquisition efficiently.",
        descriptions: [
            "Streamline HR processes with our comprehensive Human Resources (HR) System. Simplify recruitment, onboarding, performance evaluation, and payroll management with our intuitive and user-friendly solution.",
            "Enhance employee satisfaction and productivity through self-service portals and automated workflows. Empower your HR team to focus on strategic initiatives and employee development while improving overall efficiency.",
            "Our HR System by Sibasi provides a unified platform to manage the entire employee lifecycle. From attracting top talent to fostering career growth, our solution optimizes all aspects of workforce management.",
            "Optimize HR management with Sibasi's comprehensive HR System. Integrate and streamline recruitment, performance management, and employee administration for a cohesive HR experience.",
            "Empower your HR team with tools for efficient payroll processing and benefits management. Ensure smooth operation of all HR functions and improve administrative efficiency.",
            "Ensure compliance with labor laws and regulations with our HR System. Stay up-to-date with legal requirements and avoid compliance issues through robust system features.",
            "Enhance employee satisfaction and retention with streamlined HR processes. Improve your HR practices to create a positive work environment and support employee engagement.",
        ],
        icon: "",
        images: ["hr.png"],
        id: "hr",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential HR System features for personnel management.",
                price: "$5,000",
                period: "",
                from: true,
                features: [
                    "Employee Data Management",
                    "Attendance Tracking",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard",
                description:
                    "Upgrade to our Standard plan for comprehensive HR management and reporting.",
                price: "$15,000",
                period: "",
                from: true,
                features: [
                    "Performance Appraisal",
                    "Advanced Reporting",
                    "Priority Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific HR and talent management needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "Custom HR Workflows",
                    "Integration with Payroll Systems",
                    "Dedicated Support",
                ],
            },
        ],
    },
    {
        title: "Contracts Management",
        showRequestDemoBtn: true,
        shortDescription:
            "Streamline contract management with Sibasi's Contract Management System. Ensure compliance, reduce risks, and improve efficiency.",
        descriptions: [
            "Effectively manage contracts with our advanced Contracts Management system. Streamline contract creation, negotiation, renewal, and compliance tracking across the entire contract lifecycle.",
            "Automate approval workflows and ensure contract compliance with real-time visibility into contract performance and obligations. Minimize risks and maintain control over your contractual agreements.",
            "Optimize collaboration with vendors and stakeholders using Sibasi's Contracts Management solution. Enhance communication, mitigate disputes, and build strong, lasting business relationships.",
            "Simplify contract lifecycle management with Sibasi's intuitive solutions. Centralize contract repositories and streamline the creation and approval processes for greater efficiency.",
            "Monitor contract performance and compliance with automated alerts and notifications. Stay informed about key milestones and obligations to ensure timely action and adherence to agreements.",
            "Reduce risks and improve vendor relationships through effective contract management. Utilize advanced tools to enhance transparency, accountability, and overall contract performance.",
        ],
        icon: "",
        images: ["contact management.png"],
        id: "contractsmanagement",
        externalLink: "",
        // menuItemName: "Contract Management",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential Contracts Management features for contract tracking and management.",
                price: "$8,000",
                period: "",
                from: true,
                features: [
                    "Contract Repository",
                    "Basic Document Management",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard",
                description:
                    "Upgrade to our Standard plan for advanced contract management and compliance.",
                price: "$30,000",
                period: "",
                from: true,
                features: [
                    "Contract Approval Workflow",
                    "Advanced Compliance Monitoring",
                    "Priority Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific contract management requirements.",
                price: "Contact Us",
                period: "",
                features: [
                    "Custom Reporting",
                    "Integration with ERP Systems",
                    "Dedicated Support",
                ],
            },
        ],
    },
    {
        title: "Audit Management System",
        showRequestDemoBtn: true,
        shortDescription:
            "Simplify audit processes and enhance compliance with Sibasi's Audit Management System. Improve audit efficiency and effectiveness.",
        descriptions: [
            "Achieve compliance and transparency with our comprehensive Audit software. Enhance your organization's control environment and ensure adherence to regulatory requirements.",
            "Efficiently plan, execute, and report on audits with our solution. Streamline audit processes, reduce manual effort, and improve accuracy for a robust control environment.",
            "Track findings, monitor corrective actions, and generate insightful reports for stakeholders. Empower management with real-time visibility into audit outcomes and areas for improvement.",
            "Optimize business processes and drive continuous improvement with Sibasi's reliable Audit solution. Strengthen risk management, identify operational inefficiencies, and implement strategic enhancements.",
            "Ensure audit readiness and streamline planning, execution, and reporting processes with Sibasi's Audit Management System. Maintain efficiency and effectiveness with integrated workflows.",
            "Monitor audit findings and corrective actions through real-time dashboards. Enhance compliance with regulatory requirements and internal policies while improving overall audit performance.",
        ],
        icon: "",
        images: ["audit management.png"],
        id: "audit",
        externalLink: "",
        // menuItemName: "Audit",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential Audit features for audit planning and execution.",
                price: "$9,000",
                period: "",
                from: true,
                features: [
                    "Audit Planning",
                    "Basic Reporting",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard",
                description:
                    "Upgrade to our Standard plan for advanced audit management and reporting.",
                price: "$45,000",
                period: "",
                from: true,
                features: [
                    "Audit Workflow Automation",
                    "Advanced Analytics",
                    "Priority Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific audit and compliance needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "Custom Audit Templates",
                    "Integration with Accounting Software",
                    "Dedicated Support",
                ],
            },
        ],
    },
    {
        title: "Shareholder Management System",
        showRequestDemoBtn: true,
        shortDescription:
            "Efficiently manage and engage with your shareholders using our comprehensive Shareholder Management System, designed to streamline communication, compliance, and reporting.",
        descriptions: [
            "Our Shareholder Management System provides a centralized platform for tracking shareholder information, managing communications, and ensuring regulatory compliance.",
            "Automate shareholder reporting and notifications to enhance transparency and efficiency, reducing administrative overhead and minimizing errors.",
            "Leverage advanced analytics and reporting tools to gain insights into shareholder behavior and investment trends, empowering data-driven decision-making.",
            "Seamlessly integrate with existing financial systems to synchronize shareholder data and streamline operations, enhancing overall efficiency.",
            "Access customizable dashboards and real-time updates to stay informed about shareholder activities and corporate performance.",
            "Ensure compliance with industry regulations through automated compliance checks and audit trails, reducing legal and operational risks.",
            "Enhance shareholder engagement with personalized communication channels and targeted messaging, fostering stronger relationships and increasing satisfaction.",
        ],
        icon: "",
        images: ["qms.png"],
        id: "shareholder_management_system",
        externalLink: "",
        // menuItemName: "Audit",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential Shareholder Management features.",
                price: "$200",
                period: "per month",
                from: true,
                features: [
                    "Up to 1,000 shareholders",
                    "Basic reporting tools",
                    "Email notifications",
                    "Standard support",
                ],
            },
            {
                tier: "Standard",
                description:
                    "Upgrade to our Standard plan for advanced Shareholder Management and Reporting.",
                price: "$5,000",
                period: "per year",
                from: true,
                features: [
                    "Up to 5,000 shareholders",
                    "Advanced reporting and analytics",
                    "Customizable notifications",
                    "Power platform licenses sold separately",
                    "Integration with financial systems",
                    "Priority support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific Shareholder Management needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "Unlimited shareholders",
                    "Premium analytics and custom reporting",
                    "Dedicated account manager",
                    "Dedicated support",
                    "On Premise or Cloud deployment",
                    "Custom integration and compliance solutions",
                ],
            },
        ],
    },
    {
        title: "QMS - Quality Management System",
        showRequestDemoBtn: true,
        shortDescription:
            "Ensure quality and compliance with our powerful Quality Management System. Enhance product quality, customer satisfaction, and regulatory compliance.",
        descriptions: [
            "Ensure quality and compliance with our powerful Quality Management System (QMS). Elevate product and service quality to meet industry standards and exceed customer expectations.",
            "Streamline quality control processes, manage non-conformances, and drive continuous improvement. Centralize quality data to identify trends and implement corrective actions for enhanced operational efficiency.",
            "Achieve operational excellence with Sibasi's QMS. Leverage data-driven insights to enhance product innovation, reduce defects, and maintain regulatory compliance, ensuring your organization stays competitive.",
            "Drive continuous improvement and ensure product quality and safety with our QMS. Standardize processes and streamline compliance with industry standards for consistent quality assurance.",
            "Track and analyze quality metrics to identify areas for improvement. Enhance customer satisfaction and loyalty through robust quality management practices that deliver reliable and high-quality products and services.",
        ],
        icon: "",
        images: ["qms.png"],
        id: "qms",
        externalLink: "",
        // menuItemName: "Quality Management System",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential QMS features for quality control and compliance.",
                price: "$25,000",
                period: "",
                from: true,
                features: [
                    "Document Control",
                    "Basic Quality Reporting",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard",
                description:
                    "Upgrade to our Standard plan for advanced quality management and compliance tracking.",
                price: "$78,000",
                period: "",
                from: true,
                features: [
                    "Audit Management",
                    "Advanced Quality Analytics (Integration with AI)",
                    "Priority Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific quality management and compliance needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "Custom Quality Workflows",
                    "Integration with Manufacturing Systems",
                    "Dedicated Support",
                ],
            },
        ],
    },
    {
        title: "Library System",
        showRequestDemoBtn: true,
        shortDescription:
            "Modernize library management with our intuitive Library System. Improve access to information, streamline processes, and enhance user experience.",
        descriptions: [
            "Modernize library management with our intuitive Library System. Transition from traditional bookkeeping to a digital library experience, enhancing efficiency and accessibility.",
            "Effortlessly catalog books, manage lending, and facilitate online reservations. Empower patrons to explore a wide range of resources with ease and efficiency.",
            "Provide easy access to digital resources and personalized recommendations based on reading history and preferences. Enhance user satisfaction by tailoring services to individual needs.",
            "Promote literacy and efficiency with Sibasi's Library System, designed for educational institutions and organizations. Foster a culture of learning, collaboration, and optimized resource management.",
            "Organize and manage library resources efficiently with Sibasi's user-friendly tools. Streamline cataloging, tracking, and access to ensure smooth library operations.",
            "Customize library services to enhance the user experience. Improve operational efficiency and optimize resource allocation with our advanced library management features.",
        ],
        icon: "",
        images: ["library management.png"],
        id: "library",
        externalLink: "",
        // menuItemName: "Library System",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential Library System features for cataloging and managing your library resources.",
                price: "$14,000",
                period: "",
                from: true,
                features: [
                    "Catalog Management",
                    "Basic Circulation Services",
                    "Basic Support",
                ],
            },
            {
                tier: "Standard",
                description:
                    "Upgrade to our Standard plan for advanced library management capabilities and user services.",
                price: "$65,000",
                period: "",
                from: true,
                features: [
                    "Interlibrary Loan Management",
                    "Advanced Reporting",
                    "Priority Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific library management needs and integrations.",
                price: "Contact Us",
                period: "",
                features: [
                    "Custom User Portals",
                    "Integration with Library Consortia",
                    "Dedicated Support",
                ],
            },
        ],
    },
    {
        title: "Expense Claims & Imprest Processing System",
        showRequestDemoBtn: true,
        shortDescription:
            "Automate business workflows for expense claiming and imprest processing. Simplify expenses claims and imprest processing with Sibasi's streamlined solutions. Enhance efficiency and accuracy in financial management.",
        descriptions: [
            "Effortlessly manage expense claims with our streamlined Expenses Claims system. Revolutionize your organization's approach to expense reimbursement with a modern, efficient solution.",
            "Simplify the entire claim process, from submission to approval and reimbursement. Eliminate manual paperwork, reduce processing times, and improve overall efficiency for both employees and managers.",
            "Gain valuable insights into spending patterns and ensure compliance with expense policies. Utilize data analytics to identify cost-saving opportunities and reduce administrative overhead.",
            "Enhance financial control and boost employee satisfaction with Sibasi's Expenses Claims solution. Provide a user-friendly platform that promotes transparency, accuracy, and timely reimbursement.",
            "Automate expense submission, approval, and reimbursement processes. Reduce processing time and administrative burden for finance teams, while ensuring accuracy and compliance with expense policies.",
            "Improve visibility and control over corporate spending. Streamline expense claims and imprest processing to enhance financial management and operational efficiency.",
        ],
        icon: "",
        images: ["expense claim.png"],
        id: "expensesclaims",
        externalLink: "",
        // menuItemName: "Expense Claims",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential features for managing expenses claims and imprest processing.",
                price: "$3,500",
                period: "",
                from: true,
                features: [
                    "Expense Submission",
                    "Basic Approval Workflow",
                    "Basic Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific expense management and financial integrations.",
                price: "Contact Us",
                period: "",
                features: [
                    "Custom Expense Policies",
                    "Integration with Accounting Software",
                    "Dedicated Support",
                    "Advanced Approval Workflow",
                    "Expense Analytics",
                ],
            },
        ],
    },
    {
        title: "Asset Management",
        showRequestDemoBtn: true,
        shortDescription:
            "Optimize asset utilization and tracking with our Asset Management system.",
        descriptions: [
            "Optimize asset utilization and tracking with our comprehensive Asset Management system. Manage assets effectively across their entire lifecycle, from initial acquisition to disposal.",
            "Streamline asset management throughout their lifecycle by scheduling maintenance, managing depreciation, and ensuring efficient utilization. Enhance overall operational efficiency and asset performance.",
            "Monitor asset performance with real-time data, schedule maintenance proactively, and make informed decisions to maximize ROI. Identify underperforming assets, optimize resource allocation, and reduce downtime.",
            "Empower your organization with Sibasi's Asset Management system. Enhance efficiency, extend the lifespan of critical assets, and improve resource management while reducing downtime.",
            "Track and manage assets from acquisition to disposal with our robust solutions. Ensure compliance with regulatory requirements and internal policies while optimizing asset lifecycle management.",
            "Maximize asset utilization and minimize downtime through proactive maintenance. Improve financial accountability and overall asset performance with our integrated management features.",
        ],
        icon: "",
        images: ["asset management.png"],
        id: "assetmanagement",
        externalLink: "",
        // menuItemName: "Asset Management",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential Asset Management features for tracking and maintaining your assets.",
                price: "$4,500",
                period: "",
                from: true,
                features: [
                    "Asset Tracking",
                    "Basic Maintenance Management",
                    "Basic Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific asset management and integration needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "Custom Asset Workflows",
                    "Integration with CMMS, ERP Systems",
                    "Dedicated Support",
                    "Advanced Maintenance Scheduling",
                    "Asset Analytics",
                ],
            },
        ],
    },
    {
        title: "Memo Management",
        showRequestDemoBtn: true,
        shortDescription:
            "Streamline memo creation, distribution, and tracking with Sibasi's Memo Management system. Enhance communication efficiency and improve service level agreements (SLAs) across your organization.",
        descriptions: [
            "Streamline memo creation and distribution with our innovative Memo Management solution. Transform manual memo processes into a seamless and efficient workflow, enhancing internal communication.",
            "Create customizable memo templates, track status, and manage approvals with ease. Empower teams to collaborate effectively and keep stakeholders informed with consistent, well-structured memos.",
            "Enhance internal communication and ensure timely delivery of important messages. Facilitate cross-functional collaboration and enable quick decision-making with instant access to memos.",
            "Simplify memo workflows and eliminate manual inefficiencies with Sibasi's Memo Management solution. Minimize errors, reduce delays, and boost communication across your organization for improved productivity.",
            "Automate memo workflows and ensure timely communication with our Memo Management system. Streamline memo creation, distribution, and tracking to enhance organizational efficiency.",
            "Centralize memo archives for easy retrieval and reference. Improve collaboration and information sharing across departments with streamlined memo processes.",
        ],
        icon: "",
        images: ["memo management.png"],
        id: "memomanagement",
        externalLink: "",
        // menuItemName: "Memo Management",
        menuItemName: "",
        pricing: [
            {
                tier: "Standard",
                description:
                    "Access essential Memo Management features for creating and sharing internal memos.",
                price: "$16,000",
                period: "",
                from: true,
                features: [
                    "Memo Creation and Distribution",
                    "Memo Archiving",
                    "Basic Support",
                    "Memo Approval",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific memo management and integration needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "Custom Memo Workflows",
                    "Integration with Email and Chat Systems",
                    "Dedicated Support",
                    "Real-time Collaboration",
                    "Advanced Memo Search",
                    "Priority Support",
                    "Integration to ERP",
                ],
            },
        ],
    },
    {
        title: "IT Helpdesk",
        showRequestDemoBtn: true,
        shortDescription:
            "Resolve IT issues efficiently and keep your business running smoothly with our IT Helpdesk services.",
        descriptions: [
            "Empower your organization with our IT Helpdesk services, designed to deliver timely and effective support for all IT-related issues. From troubleshooting technical problems and setting up new equipment to providing software assistance, we handle it all.",
            "Our experienced IT professionals offer around-the-clock support to ensure minimal downtime and maximum productivity. With our helpdesk, you can focus on core business activities while we manage your technology challenges.",
            "Choose from a range of support options, including phone, email, and remote assistance, tailored to your organization's needs. We provide fast, reliable solutions to keep your IT infrastructure running smoothly.",
            "Join many successful businesses that have benefited from our IT Helpdesk services. Experience a proactive and responsive approach to IT support and let us be your trusted partner in technology.",
            "Ensure seamless IT support with Sibasi's IT Helpdesk solutions. We provide timely resolution to IT issues and minimize downtime for enhanced operational efficiency.",
            "Enhance user satisfaction with responsive and efficient IT services. Track and prioritize support tickets effectively to allocate resources and support organizational growth.",
        ],
        icon: "",
        images: ["helpdesk.png"],
        id: "ithelpdesk",
        externalLink: "",
        // menuItemName: "IT Helpdesk",
        menuItemName: "",
        pricing: [
            {
                tier: "Basic",
                description:
                    "Access essential IT Helpdesk features for efficient IT issue resolution.",
                price: "$4,500",
                period: "",
                from: true,
                features: [
                    "IT Issue Tracking",
                    "Basic Troubleshooting",
                    "Basic Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific IT helpdesk and integration needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "Customized Knowledge Base",
                    "Integration with Monitoring Tools",
                    "Dedicated Support",
                    "Advanced Troubleshooting",
                    "Remote Assistance",
                    "Priority Support",
                ],
            },
        ],
    },
    {
        title: "Lawyers Hub System",
        showRequestDemoBtn: true,
        shortDescription:
            "Empower your legal practice with our Lawyers Hub System. Streamline case management, client communications, and compliance effortlessly.",
        descriptions: [
            "Empower your legal practice with our Lawyers Hub System. Tailored for legal professionals, our platform offers comprehensive tools for case management, client communication, and secure document storage.",
            "Simplify case tracking, streamline client communication, and securely manage legal documents with our system. Enhance your efficiency and client service with our user-friendly platform.",
            "Experience the advantages of a system specifically designed for legal professionals. Transform your legal practice management with Sibasi's Lawyers Hub System.",
            "Streamline case management, client communication, and document handling with our advanced platform. Ensure compliance with legal regulations and ethical standards.",
            "Enhance collaboration among legal teams, drive efficiency, and improve client service with our integrated solution. Boost productivity and profitability in your legal operations.",
        ],
        icon: "",
        images: ["lawyer management.png"],
        id: "lawyers_hub_system",
        externalLink: "",
        // menuItemName: "Lawyers Hub System",
        menuItemName: "",
        pricing: [
            {
                tier: "Standard",
                description:
                    "Access essential Lawyers Hub System features for legal case management.",
                price: "$25,000",
                period: "",
                from: true,
                features: [
                    "Case Management",
                    "Basic Document Management",
                    "Basic Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific legal practice and integration needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "Custom Case Workflows",
                    "Integration with Legal Research Tools",
                    "Dedicated Support",
                    "Advanced Collaboration Tools",
                    "Advanced Reporting",
                    "Priority Support",
                ],
            },
        ],
    },
    {
        title: "Projects Operations",
        showRequestDemoBtn: true,
        shortDescription:
            "Efficiently manage your projects and operations with Microsoft Dynamics 365 Projects Operations implemented by Sibasi Ltd experts.",
        descriptions: [
            "Efficiently manage your projects and operations with our comprehensive Projects Operations solution. Whether handling small tasks or complex projects, our platform helps you plan, track, and collaborate effectively.",
            "Gain visibility into project progress, allocate resources efficiently, and deliver projects on time and within budget. Enhance your project management capabilities with our integrated solution.",
            "Experience streamlined project and operations management with Sibasi's dedicated solution. Drive project success by managing resources, timelines, and budgets effectively.",
            "Track project progress and milestones with real-time updates. Mitigate risks and ensure compliance with streamlined workflows for improved project transparency and stakeholder communication.",
            "Improve project outcomes and overall efficiency with our advanced Projects Operations solution. Enhance resource management, risk mitigation, and project visibility for optimal results.",
        ],
        icon: "",
        images: ["project operations.png"],
        id: "projects_operations",
        externalLink: "",
        // menuItemName: "Projects Operations",
        menuItemName: "",
        pricing: [
            {
                tier: "Standard",
                description:
                    "Access essential Projects Operations features for project management and collaboration.",
                price: "$80,000",
                period: "",
                from: true,
                features: [
                    "Project Planning",
                    "Basic Task Management",
                    "Basic Support",
                    "Resource Scheduling",
                    "Advanced Project Analytics",
                    "Priority Support",
                ],
            },
            {
                tier: "Custom",
                description:
                    "Tailor a custom plan to match your specific project management and integration requirements.",
                price: "Contact Us",
                period: "",
                features: [
                    "Everything in standard &",
                    "Custom Project Workflows",
                    "Integration with CRM and ERP Systems",
                    "Dedicated Support",
                ],
            },
        ],
    },
    {
        title: "Enterprise Risk Management (ERM)",
        showRequestDemoBtn: true,
        shortDescription:
            "Sibasi's Enterprise Risk Management (ERM) is a system that enables you to have a comprehensive strategy that helps your organization to identify, assess, and mitigate risks to protect your assets, reputation, and ensure your long-term success.",
        descriptions: [
            "Our Enterprise Risk Management solution empowers businesses to proactively manage risks and make informed decisions, ensuring a secure and sustainable future.",
            "Key Features:",
            "Risk Identification: Identify potential risks across various business functions and processes with in-depth risk assessments.",
            "Risk Assessment: Evaluate the impact and likelihood of identified risks to prioritize and focus on critical areas.",
            "Mitigation Strategies: Develop and implement effective risk mitigation strategies and action plans.",
            "Compliance Monitoring: Ensure adherence to industry regulations and internal policies to avoid legal and financial repercussions.",
            "Data Analytics: Utilize advanced analytics to gain insights into risk trends and make data-driven decisions.",
            "Reporting and Dashboards: Access comprehensive reports and customizable dashboards to track risk management progress.",
            "Security and Access Control: Protect sensitive risk data with robust security measures, role-based permissions, and encryption.",
            "Mitigate risks effectively with Sibasi's Enterprise Risk Management solutions. Identify, assess, and prioritize risks to safeguard organizational assets and reputation, implement mitigation strategies, and ensure compliance with regulatory requirements. Enhance decision-making with comprehensive risk insights.",
        ],
        icon: "",
        images: ["risk management.png"],
        id: "enterprise_risk_management",
        externalLink: "",
        menuItemName: "",
        pricing: [
            {
                tier: "Standard",
                description:
                    "Ideal for small and medium-sized organizations seeking a structured approach to risk management.",
                price: "$35,000",
                period: "",
                from: true,
                features: [
                    "Risk Identification and Assessment",
                    "Mitigation Strategies",
                    "Compliance Monitoring",
                    "Basic Data Analytics",
                    "Standard Reporting",
                    "Standard Security Measures",
                ],
            },
            {
                tier: "Enterprise",
                description:
                    "Tailored for large corporations and enterprises with complex and sophisticated risk management needs.",
                price: "Contact Us",
                period: "",
                features: [
                    "Customized Risk Management Features",
                    "Advanced Data Analytics",
                    "Comprehensive Reporting and Dashboards",
                    "Enhanced Security and Access Control",
                    "Dedicated ERM Specialist",
                    "Support and Training",
                ],
            },
        ],
    },
    {
        title: "Microsoft License Solutions",
        shortDescription:
            "Our Microsoft License Solutions offer comprehensive licensing strategies to optimize your software investments, ensure compliance, and enhance productivity across your organization.",
        descriptions: [
            "<b>**Get your Microsoft licenses with over 10% off**</b>, ensuring optimized software investments, compliance, and productivity.",
            "Microsoft 365 License: A comprehensive suite designed for productivity and seamless collaboration across your organization.",
            "Dynamics 365 Business Central: A unified business management solution tailored for small and medium-sized enterprises, streamlining operations and enhancing efficiency.",
            "Power BI License: A powerful business analytics tool for data visualization, offering actionable insights and driving data-driven decisions.",
            "Windows Server License: An enterprise-level operating system platform for managing applications and infrastructure.",
            "Azure License: A versatile cloud services platform for building, deploying, and managing applications with flexibility and scalability.",
            "SQL Server License: A robust database management system for efficient data storage, analysis, and management.",
            "SharePoint License: A secure platform that enhances collaboration, content management, and overall productivity within your organization.",
            "Exchange License: A comprehensive email and collaboration system designed to meet your productivity and communication needs.",
        ],
        icon: "",
        images: ["licensing.png"],
        id: "licenses",
        externalLink: "https://siba.si/license",
        menuItemName: "",
        externalLinkText: "Request License",
        showRequestDemoBtn: false,
    },
];

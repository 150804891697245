<div
    class="page-title-area item-bg2"
    style="background-image: url(assets/img/microsoft-keyboard.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Dynamics 365</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <a routerLink="/solutions-offered">Solutions</a>
                        </li>
                        <li>Dynamics 365</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="image-sliders owl-carousel owl-theme">
                    <div class="project-details-image">
                        <img
                            src="assets/img/solutions/dynamics-365-300x113.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="projects-details-desc">
                <h3>Dynamics 365</h3>
                <p>
                    Dynamics 365 is a set of intelligent business applications
                    that helps you run your entire business and deliver greater
                    results through predictive, AI-driven insights.
                </p>
                <p>
                    We implement Dynamics 365 CRM which is a Customer
                    Relationship Management Tool that brings together all
                    information from different departments throughout the
                    company to give one, holistic view of each customer in real
                    time.
                </p>
                <p>
                    We also implement Dynamics 365 ERP (Enterprise Resource
                    Planning) which is a business process management software
                    that manages and integrates a company’s financials, supply
                    chain, operations, reporting, manufacturing, and human
                    resource activities.
                </p>
                <p>
                    These solutions strengthen customer engagement processes and
                    improves collaborative sales as well. Besides this, it helps
                    heighten security, increases mobility, enables smart
                    investment infrastructure, powers innovation and improves
                    accessibility of information.
                </p>
                <div class="banner-btn" style="text-align: center">
                    <a class="default-btn" routerLink="/contact"
                        >Request Demo<span></span
                    ></a>
                </div>
                <div class="row mt-4 solutions-offered">
                    <h3>Solutions built using this technology</h3>
                    <div class="row">
                        <div class="col-auto">
                            <a routerLink="/solution-details/crm">CRM</a>
                        </div>
                        <div class="col-auto">
                            <a
                                routerLink="/solution-details/erp_business_central"
                                >ERP</a
                            >
                        </div>
                        <div class="col-auto">
                            <a
                                routerLink="/solution-details/projects_operations"
                                >Project Operations</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div
    class="page-title-area item-bg2"
    style="background-image: url(assets/img/bg_monival_graph.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Monival™</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <a routerLink="/solutions-offered">Solutions</a>
                        </li>
                        <li>Monival™</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="image-sliders owl-carousel owl-theme">
                    <div class="project-details-image">
                        <img
                            src="assets/img/Monival_Logo-300x85.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="projects-details-desc">
                <h3>Monival</h3>
                <p>
                    Monival is a Monitoring and Evaluation solution for
                    Projects, Strategic Plans and Performance Management that
                    has been designed by M&E experts for Non-Governmental
                    Organizations (NGOs), Governments, Public Institutions and
                    Businesses.
                </p>
                <p>
                    The need for Monitoring and Evaluation for Project Plans and
                    Strategic Plans has become of core importance for impact and
                    success.
                </p>
                <p>
                    Monival helps in the collaboration of all the stakeholders
                    from the executive, to the project team, the field team,
                    contractors, beneficiaries and even the project sponsors and
                    donors.
                </p>
                <p>
                    Monival has been designed to be an extensible system,
                    supporting user defined frameworks and integration with
                    existing systems.
                </p>
                <div class="features-text">
                    <h4>Plan</h4>
                    <p>
                        Monival helps you to plan effectively; from the ideation
                        and proposal stage to preparing your results,
                        activities, indicators, budgets and targets
                    </p>
                </div>
                <div class="features-text">
                    <h4>Execute</h4>
                    <p>
                        Whether you are executing a Project, Program or even a
                        Strategic Plan, Monival provides features to help you
                        with execution including capturing actuals and
                        collaboration between your stakeholders
                    </p>
                </div>
                <div class="features-text">
                    <h4>Report</h4>
                    <p>
                        A good Monitoring and Evaluation system provides reports
                        to all stakeholders including the Project Team,
                        Executing, Supporting teams, Contractors, Sponsors and
                        even Donors, that is Monival!
                    </p>
                </div>
                <div class="row">
                    <div class="banner-btn col-auto" style="text-align: center">
                        <a class="default-btn" routerLink="/contact"
                            >Request Demo <span></span
                        ></a>
                    </div>
                    <div
                        class="col-auto"
                        style="text-align: center; margin-top: 20px"
                    >
                        <a
                            class="productive-btn-one"
                            href="assets/pdf/Monival Brochure by Sibasi Ltd.pdf"
                            target="_blank"
                            >View brochure <span></span
                        ></a>
                    </div>
                    <div class="banner-btn col-auto" style="text-align: center">
                        <a
                            class="default-btn"
                            routerLink="/solution-details/monival"
                            >More Details<span></span
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

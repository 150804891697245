import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calltoaction',
  templateUrl: './calltoaction.component.html',
  styleUrls: ['./calltoaction.component.scss']
})
export class CalltoactionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

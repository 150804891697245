<section class="subscribe-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="subscribe-content">
                    <h2>Want to hear from us?</h2>
                    <span class="sub-title">Send us your email and we will keep you posted on the latest solutions and services we offer.</span>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <form class="newsletter-form" [formGroup]='subscribeForm' (ngSubmit)="onSubmit()">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" formControlName="email">
                    <button type="submit">send</button>
                </form>
            </div>
        </div>
    </div>
</section>
<div class=" "> 
    <div class=""> 
        <h2 class="heading"> Share our experience </h2> 
        <p>We offer consulting services for cloud computing, data science, data warehousing and data analytics
        </p>
     </div> 
        <div class="row mt-3" style="
        text-align: center;
        "> 
            <div class="col-sm-4 col-md-6"> 
                <img  alt="Node Js" class="ml-2" style="height: 64px;" src="assets/img/Microsoft_Azure_Logo.svg">
                <img  alt="Visual studio" class="ml-2" style="height: 64px;" src="assets/img/pngwing.com.png">
                <img  alt="Visual studio" class="ml-2" style="width:130px;" src="assets/img/power-bi-vector-logo.svg">

            </div> 
             <!-- <div class="ml-2 col-sm-4 col-md-6"> 
                <img  alt="Android" class="" style="width:64px;height: 64px;" src="assets/img/android-seeklogo.com.svg">
                <img  alt="swift" class="ml-2" style="height: 64px;" src="assets/img/Apple_logo_black.svg">
                <img  alt="python" class="ml-2" style="width:64px;height: 64px;" src="assets/img/Python-logo-notext.svg">
          

            </div> -->
             
        </div> 

 
</div>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="productive-section ptb-100">
    <div class="container">
        <div class="row">
            <div
                class="col-lg-6 col-md-12 col-sm-12 image-overlay d-flex justify-content-center flex-column align-content-center align-items-center order-sm-2">
                <h1 style="
                        color: white;
                        margin-bottom: min(10vw, 50%);
                        margin-top: min(10vw, 50%);
                    ">
                    <strong>Services. Solutions. Success!</strong>
                </h1>
            </div>

            <div class="col-lg-6 col-md-12 order-sm-1">
                <div class="productive-content">
                    <h3>We exist to help you win!</h3>
                    <p>
                        Sibasi Ltd is an ICT services and solutions company
                        headquartered in Nairobi, Kenya with a global delivery
                        team.<br />
                        As a company, we have adopted the slogan:
                        <strong>Services. Solutions. Success!</strong><br />
                        Our slogan ties in with our ideals of providing
                        excellent technological services and solutions that are
                        geared towards the success of our client's goals.
                    </p>
                    <p>
                        Our mission is to provide <strong>Services and Solutions
                            Invested and Integrated in your Business for you to
                            Achieve Success and have an Impact!</strong>
                    </p>
                    <div class="banner-btn row">
                        <div class="banner-btn-1 col-auto ps-0">
                            <a routerLink="/partners" class="default-btn">Our Partners <span></span></a>
                        </div>
                        <div class="col-auto">
                            <a routerLink="/contact" class="productive-btn-one">Contact Us <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section ptb-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12" style="
                    background-color: #00b0ee;
                    min-height: inherit;
                    display: flex;
                    align-items: center;
                    display: -webkit-flex;
                    justify-content: center;
                ">
                <div class="productive-image" style="text-align: center">
                    <h1 style="
                            color: white;
                            margin-top: auto;
                            margin-bottom: auto;
                            margin-bottom: min(20vw, 50%);
                            margin-top: min(20vw, 50%);
                        ">
                        <strong>Our History</strong>
                    </h1>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h3>A brief history about Sibasi</h3>
                    <p>
                        The Sibasi story is one of innovation born in Kenya, destined for impact across Africa. It all
                        began in 2009, when a group of brilliant minds at Jomo Kenyatta University of Agriculture and
                        Technology (JKUAT) dreamt big during a hackathon. Their revolutionary solution, designed for the
                        then-nascent Android platform, was christened "Sibasi droid."
                    </p>
                    <p>
                        In 2017, Sibasi Ltd. was incorporated and has since grown into a leading IT
                        innovation force across Africa and beyond.
                        <br>
                        Our expertise now empowers organizations and institutions beyond Kenya's borders, providing
                        world-class solutions across multiple African nations including Uganda, Tanzania, Rwanda,
                        Malawi, Swaziland, South Sudan, Somalia, Namibia, and South Africa, as well as in Europe, the
                        Americas, and the Middle East.
                    </p>
                    <p>
                        Sibasi Ltd. is uniquely positioned to leverage its deep understanding of African markets with
                        cutting-edge global technologies, ensuring our clients achieve a significant competitive
                        advantage.
                        <br>
                        Sibasi Ltd. has emerged as the beacon of IT innovation in Africa and beyond, driven by a mission
                        to tackle local challenges with global impact. Our name, derived from this deep-rooted
                        commitment,
                        embodies our dedication to integrating transformative technologies into the fabric of
                        businesses, governments and non-profit organizations.
                    </p>
                    <p>
                        <i>Sibasi isn't just a company, <strong>it's a promise!</strong></i> We invest in and integrate
                        our solutions
                        seamlessly into your business. Why? Because your success is our success, and together, we'll
                        make a lasting impact.
                    </p>
                    <br />
                    <p>
                        <strong>At Sibasi, our mission is clear: to deliver unparalleled "Services and Solutions
                            Invested and Integrated in our customer's Business for them to achieve Success and have an
                            Impact!"</strong> We invest deeply in understanding and integrating with our clients'
                        businesses, ensuring every partnership yields measurable impact and sustainable growth.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/sibasi/sibasi-team-member.jpg" alt="image" style="width: 100%" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h3>Our Core Values</h3>
                    <p>We are guided by Core Values that include:</p>
                    <ul class="about-list">
                        <li>
                            <i class="flaticon-tick"></i> Excellence
                            <span>– We always deliver the best, fast and with
                                great quality</span>
                        </li>
                        <li>
                            <i class="flaticon-tick"></i> Professionalism
                            <span>– We have integrity, accountability, mutual
                                respect and we communicate efficiently with
                                whomever we interact with</span>
                        </li>
                        <li>
                            <i class="flaticon-tick"></i> Collaboration
                            <span>– We work together, supporting each other
                                because one person’s success is our
                                success</span>
                        </li>
                        <li>
                            <i class="flaticon-tick"></i> Innovation
                            <span>– We are all innovators at heart, always
                                looking for new ways and exceeding expectations
                                everytime</span>
                        </li>
                        <li>
                            <i class="flaticon-tick"></i> Have fun
                            <span>– We are very passionate about what we do, we
                                therefore bring the best form of ourselves and
                                we therefore enjoy our work and the results we
                                bring</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-section ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h3>What Our Clients Say</h3>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-single-item">
                <div class="">
                    <img src="assets/img/OWSD_International_Logo.png" alt="image" style="
                            display: inline-block;
                            margin: auto;
                            width: auto;
                            height: 100px;
                        " />
                </div>
                <div class="testimonial-info">
                    <h3>
                        Organization for Women in Science for the Developing
                        World
                    </h3>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>
                        Sibasi Ltd helped us meet our urgent need for the
                        website through their passionate team that was able to
                        provide the deliverables quickly, with exceptional
                        customer support.
                    </p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="">
                    <img src="assets/img/SCC_Logo_RGB_transparent.png" alt="image" style="
                            display: inline-block;
                            margin: auto;
                            width: auto;
                            height: 100px;
                        " />
                </div>
                <div class="testimonial-info">
                    <h3>Somali Cash Consortium</h3>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>
                        Sibasi delivered the reports on-time, or ahead of
                        schedule and has impressive turn-around times for
                        revisions. They have also been an invaluable resource in
                        helping us explore using new technology solutions. In
                        this aspect, it has felt more like a partnership than a
                        customer/supplier dynamic.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonial-shape">
        <img src="assets/img/shape/testimonial-shape-1.png" alt="main-image" />
    </div>
    <div class="testimonial-shape-img1">
        <img src="assets/img/shape/testimonial-shape-2.png" alt="image" />
    </div>
</section>

<div class="page-title-area item-bg3" style="background-image: url(assets/img/services-offered-img.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Our Partnerships</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About Us</a></li>
                        <li>Our Partnerships</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-details-area mt-4" style="margin-bottom: 50px;">
    <div class="container">
        <div class="services-details-overview ">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 order-sm-2">
                    <div class="services-details-image">
                        <img src="assets/img/sibasi/sibasi-microsoft-partner.png" alt="image" style="width:80%">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 order-sm-1">
                    <div class="services-details-desc">
                        <h3>We Partner for Your Success</h3>
                        <p>
                            <strong>Sibasi is a Microsoft Gold Partner.</strong>
                            <br/>
                            We are very excited to have qualified and joined many of the global, recognized Microsoft partners at the gold level.
                            <br />
                            This achievement is the top-level accreditation offered by Microsoft to partners who have
                            shown capability to deliver World-Class implementations for various Microsoft-based
                            solutions.
                            <br/>
                            Our customers are our reason for seeking this accreditation and we are very grateful for the
                            opportunity to continue serving them.
                            <br />
                            This is another small step on our journey towards achieving our core mission of providing
                            our customers with the best Services & Solutions that will help them achieve success and have an
                            impact.
                        </p>

                    </div>
                    <div class="banner-btn mt-3 row">
                        <div class="col-sm-12">
                            <a class="default-btn" routerLink="/contact">Get in touch <span></span></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<div
    class="page-title-area item-bg3"
    style="background-image: url(assets/img/motherboard.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Our Solutions</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>What We Do</li>
                        <li>Our Solutions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h3>Explore Our Solutions</h3>
            <br />
            <br />
            <p>
                We have a broad set of solutions for different types of
                industries and types of enterprises including Public and Private
                Businesses, Public Institutions, Governments and
                Non-Governmental Organizations.
            </p>
            <!-- We categorize these solutions
            into 2, namely: Our Innovations and Partner-based solutions. -->
        </div>
        <div class="row">
            <div
                class="col-lg-4 col-md-6 mb-4"
                *ngFor="let solutionOffered of solutionsOffered"
            >
                <div class="single-services-box h-100">
                    <div class="title-box d-flex">
                        <div class="icon col-atuo">
                            <i class="flaticon-big-data"></i>
                        </div>
                        <div class="col">
                            <h3>{{ solutionOffered?.title }}</h3>
                        </div>
                    </div>
                    <p>{{ solutionOffered?.shortDescription }}</p>
                    <div class="services-btn">
                        <a
                            [routerLink]="[
                                solutionOffered?.showRequestDemoBtn
                                    ? '/solution-details/' + solutionOffered?.id
                                    : '/' + solutionOffered?.id
                            ]"
                            class="services-btn-one"
                            >Read More <i class="flaticon-right"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

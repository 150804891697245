import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(private http: HttpClient) { }

    subscribeEmail(email: { email: string }) {
        return this.http.post(`${environment.api_url}newsLetter`, email);
    }


    contact(emailBody:
        {
            name: string,
            subject: string,
            solution: string,
            message: string,
            phone: string,
            email: string
        }) {
        return this.http.post(`${environment.api_url}contact`, emailBody);
    }

}

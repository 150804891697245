<div class=" "> 
    <div class=""> 
        <h2 class="heading"> Develop with A variety of tools </h2> 
        <p>At the core we are software engineers with expertise in web, mobile and server technologies</p>
     </div> 
        <div class="row" style="
        
        "> 
            <div class="column-md-4"> 
                <img  alt="Angular" class="" style="width:88px;" src="https://upload.wikimedia.org/wikipedia/commons/c/cf/Angular_full_color_logo.svg">
             </div>
              <div class=" column-md-8 ml-2" style=""> 
                  <h4 class="heading" style="font-size: 20px;margin-bottom: 0px;"> Angular </h4> 
                  <p>We use angular for robust projects</p>
            </div>
        </div> 

        <div class="row" style="
        
        "> 
            <div class="column-md-4"> 
                <img  alt="Node Js" class="" style="width:88px;" src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg">
             </div>
              <div class=" column-md-8 ml-2" style=""> 
                  <h4 class="heading" style="font-size: 20px;margin-bottom: 0px;"> Node js </h4> 
                  <p></p>
            </div>
        </div> 
</div>

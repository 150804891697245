<div
    class="page-title-area item-bg2"
    style="background-image: url(assets/img/sharepoint-14567.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>SharePoint</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <a routerLink="/solutions-offered">Solutions</a>
                        </li>
                        <li>SharePoint</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="image-sliders owl-carousel owl-theme">
                    <div class="project-details-image">
                        <img
                            src="assets/img/Microsoft_Office_SharePoint_(2018–present).svg"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="projects-details-desc">
                <h3>SharePoint - Document Management & Intranet Solutions</h3>
                <p>
                    SharePoint is a highly customizable, website-based
                    collaboration platform integrated with Microsoft Office to
                    empower business teams to work together.
                </p>
                <p>
                    We are experts at configuring, designing and developing
                    SharePoint based solutions for Intranets, Document
                    Management Systems as well as other solutions such as
                    Quality Management Systems.
                </p>
                <p>
                    SharePoint for intranets allows organizations to have their
                    own private online network that is only accessible to the
                    employees within the organization to help in collaboration
                    and information sharing as they work towards their goals.
                </p>
                <p>
                    We also implement SharePoint as a Document Management System
                    (DMS). Some of the advantages that are associated with
                    SharePoint as a DMS are;
                </p>
                <ul class="about-list">
                    <li>
                        <i class="flaticon-tick"></i> easy content organization
                    </li>
                    <li><i class="flaticon-tick"></i> security</li>
                    <li>
                        <i class="flaticon-tick"></i> scalable storage for your
                        documents
                    </li>
                    <li>
                        <i class="flaticon-tick"></i> a synced workplace for
                        collaboration
                    </li>
                    <li><i class="flaticon-tick"></i> sharing​</li>
                </ul>
                <p>
                    SharePoint also gives the client using the platform the
                    ability to control access to information and automate
                    workflow processes across business units.
                </p>
                <div class="banner-btn">
                    <a class="default-btn" routerLink="/contact"
                        >Request Demo<span></span
                    ></a>
                </div>
                <div class="row mt-4 solutions-offered">
                    <h3>Solutions built using this technology</h3>
                    <div class="row">
                        <div class="col-auto">
                            <a routerLink="/solution-details/edms">EDMS</a>
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/intranet_solution"
                                >Intranet</a
                            >
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/crm">CRM</a>
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/electronic_board"
                                >eBoard</a
                            >
                        </div>
                        <div class="col-auto">
                            <a
                                routerLink="/solution-details/contractsmanagement"
                                >Contracts</a
                            >
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/qms">QMS</a>
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/memomanagement"
                                >Memo</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class=" "> 
    <div class=""> 
        <h2 class="heading"> Develop with A variety of tools </h2> 
        <p>At the core we are software engineers with expertise in web, mobile and server technologies</p>
     </div> 
        <div class="row mt-3" style="
        text-align: center;
        "> 
            <div class="col-sm-4 col-md-6"> 
                <img  alt="Angular" class=""  style="width:64px; height: 64px;" src="https://upload.wikimedia.org/wikipedia/commons/c/cf/Angular_full_color_logo.svg">
                <img  alt="Node Js" class="ml-2" style="width:64px;height: 64px;" src="assets/img/nodejs-seeklogo.com.svg">
                <img  alt="Visual studio" class="ml-2" style="width:64px;height: 64px;" src="assets/img/visual-studio-seeklogo.com.svg">
            </div> 
             <div class="ml-2 col-sm-4 col-md-6"> 
                <img  alt="Android" class="" style="width:64px;height: 64px;" src="assets/img/android-seeklogo.com.svg">
                <img  alt="swift" class="ml-2" style="height: 64px;" src="assets/img/Apple_logo_black.svg">
                <img  alt="python" class="ml-2" style="width:64px;height: 64px;" src="assets/img/Python-logo-notext.svg">
          

            </div>
             
        </div> 

 
</div>

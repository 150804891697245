<div
    class="page-title-area"
    style="background-image: url(assets/img/success.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Success</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>What We Do</li>
                        <li>Our Success</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section mt-4">
    <div class="container">
        <div class="services-details-overview">
            <div class="row">
                <div class="col-lg-6 col-md-12 order-sm-2">
                    <div class="services-details-image">
                        <img
                            src="assets/img/sibasi/sibasi-ok.jpg"
                            alt="image"
                        />
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 order-sm-1">
                    <div class="services-details-desc">
                        <h3>For your Success!</h3>
                        <p>
                            We are inspired by our mission that gears towards
                            helping you achieve your Success and have an Impact!
                        </p>
                        <p>
                            Our effort, and expertise is available at your
                            disposal with an aim of providing you the right
                            technological tools, products and services which can
                            help you become more productive, make better
                            decisions quickly, consolidate and avail information
                            easily and overall help you achieve success.
                        </p>
                        <p>
                            Do you have a thought, a problem or you wish to hear
                            how we could help?
                        </p>
                    </div>
                    <div class="banner-btn row">
                        <div class="banner-btn-1 col-auto ps-0">
                            <a class="default-btn" routerLink="/contact"
                                >Get in touch <span></span
                            ></a>
                        </div>
                        <div class="col-auto">
                            <a routerLink="/about" class="productive-btn-one"
                                >About Us <span></span
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section ptb-50">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img
                        src="assets/img/sibasi/how-we-do-it.jpg"
                        alt="image"
                        style="height: 300px"
                    />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h3>Why we do it</h3>
                    <p>
                        We work hard to provide you with excellent Services &
                        Solutions that are geared to help you achieve Success!
                        <br />
                        We believe success for you could mean many different
                        things that can range from any of the following:
                    </p>
                    <ul class="about-list">
                        <li>
                            <i class="flaticon-tick"></i
                            ><span class="title">
                                Improving your service delivery</span
                            >
                        </li>
                        <li>
                            <i class="flaticon-tick"></i
                            ><span class="title">
                                Improving your productivity</span
                            >
                        </li>
                        <li>
                            <i class="flaticon-tick"></i
                            ><span class="title"> Increasing your profits</span>
                        </li>
                        <li>
                            <i class="flaticon-tick"></i
                            ><span class="title"> Lowering your costs</span>
                        </li>
                        <li>
                            <i class="flaticon-tick"></i
                            ><span class="title">
                                Better decision making driven by data</span
                            >
                        </li>
                        <li>
                            <i class="flaticon-tick"></i
                            ><span class="title">
                                Better monitoring & evaluation of your projects,
                                resources</span
                            >
                        </li>
                        <li>
                            <i class="flaticon-tick"></i
                            ><span class="title">
                                Overall better performance of your
                                organization</span
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-section ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h3>What Our Clients Say</h3>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-single-item">
                <div class="">
                    <img
                        src="assets/img/OWSD_International_Logo.png"
                        alt="image"
                        style="
                            display: inline-block;
                            margin: auto;
                            width: auto;
                            height: 100px;
                        "
                    />
                </div>
                <div class="testimonial-info">
                    <h3>
                        Organization for Women in Science for the Developing
                        World
                    </h3>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>
                        Sibasi Ltd helped us meet our urgent need for the
                        website through their passionate team that was able to
                        provide the deliverables quickly, with exceptional
                        customer support.
                    </p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="">
                    <img
                        src="assets/img/SCC_Logo_RGB_transparent.png"
                        alt="image"
                        style="
                            display: inline-block;
                            margin: auto;
                            width: auto;
                            height: 100px;
                        "
                    />
                </div>
                <div class="testimonial-info">
                    <h3>Somali Cash Consortium</h3>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>
                        Sibasi delivered the reports on-time, or ahead of
                        schedule and has impressive turn-around times for
                        revisions. They have also been an invaluable resource in
                        helping us explore using new technology solutions. In
                        this aspect, it has felt more like a partnership than a
                        customer/supplier dynamic.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonial-shape">
        <img src="assets/img/shape/testimonial-shape-1.png" alt="main-image" />
    </div>
    <div class="testimonial-shape-img1">
        <img src="assets/img/shape/testimonial-shape-2.png" alt="image" />
    </div>
</section>

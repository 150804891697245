import { Component, inject } from "@angular/core";
import { Portfolios } from "./portfolio.const";
import { IPortfolioFilter, IPortfolio } from "src/app/models/portfolio.model";
import { MetaTagService } from "../../../services/meta-tag.service";

@Component({
    selector: "app-portfolio",
    templateUrl: "./portfolio.component.html",
    styleUrls: ["./portfolio.component.scss"],
})
export class PortfolioComponent {
    portfolioFilters: IPortfolioFilter[] = [];
    selectedFilter: IPortfolioFilter = this.portfolioFilters[0];
    portfolios: IPortfolio[] = Portfolios;
    storedPortfolios: IPortfolio[] = Portfolios;
    private metaTagService = inject(MetaTagService);

    ngOnInit(): void {
        this.getPortfolioFilters();
        this.updatePageMetaTags();
    }

    getFirstAndLastHalfOfName(fullName: string): {
        firstHalf: string;
        lastHalf: string;
    } {
        // Split the full name into an array of words
        const words = fullName.split(" ");

        // Calculate the midpoint index
        const midpointIndex = Math.floor(words.length / 2);

        // If the number of words is odd, include the midpoint word in the first half
        let firstHalf: string[] | string = words.slice(
            0,
            midpointIndex + (words.length % 2)
        );

        // Last half includes words after the midpoint index
        let lastHalf: string[] | string = words.slice(
            midpointIndex + (words.length % 2)
        );

        // Join the words back together to form the first and last halves
        firstHalf = firstHalf.join(" ");
        lastHalf = lastHalf.join(" ");

        return {
            firstHalf: firstHalf,
            lastHalf: lastHalf,
        };
    }

    getPortfolioFilters(): void {
        const filters = this.storedPortfolios.map((x) => x.filters);
        const flattenedArr = this.flattenArray(filters);
        const uniqueArray = [...new Set(flattenedArr)];
        this.portfolioFilters = uniqueArray.map((y) => ({
            id: y,
            name: y,
        })) as IPortfolioFilter[];
    }

    flattenArray(arr) {
        return arr.reduce(
            (acc, val) =>
                Array.isArray(val)
                    ? acc.concat(this.flattenArray(val))
                    : acc.concat(val),
            []
        );
    }

    filterPortfoliosArray(category: any): void {
        if (this.selectedFilter === category) {
            this.selectedFilter = null;
            this.portfolios = this.storedPortfolios;
        } else {
            this.selectedFilter = category;
            this.portfolios = this.storedPortfolios.filter((x) => {
                const filters = x.filters;
                return filters.find((y) => y === category.id) ? true : false;
            });
        }
    }

    updatePageMetaTags(): void {
        this.metaTagService.updateMetaTags(
            "Sibasi Portfolio - Showcase of Our Expertise and Success",
            "Explore the diverse portfolio of Sibasi, highlighting our successful projects and innovative solutions. See how our expertise in technology has helped clients achieve their business goals and drive success.",
            "Sibasi portfolio, successful projects, technology solutions, client success, innovative solutions, business goals, expertise"
        );
    }
}

import { Component, inject, OnInit } from "@angular/core";
import { MetaTagService } from "../../../services/meta-tag.service";

@Component({
    selector: "app-about-us",
    templateUrl: "./about-us.component.html",
    styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
    private metaTagService = inject(MetaTagService);

    ngOnInit(): void {
        this.updatePageMetaTags();
    }

    updatePageMetaTags(): void {
        this.metaTagService.updateMetaTags(
            "About Sibasi - Microsoft Gold Partner and an ICT services and solutions company with a global delivery team",
            "Learn about Sibasi, the premier Microsoft Solutions Partner in East and Central Africa. Discover our journey, mission, and values that drive our success.",
            "Sibasi, Microsoft Gold Partner, Microsoft Solutions Partner, East Africa, Central Africa, technology solutions, IT services"
        );
    }
}

<footer class="footer-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>About Us</h3>
                    </div>
                    <p>
                        <strong>Sibasi Ltd</strong> is a technology company
                        specializing in consulting services, software
                        engineering and deployment services.
                    </p>

                    <div>
                        <img
                            src="assets/img/Animated Logo with Competencies.gif"
                            alt=""
                        />
                    </div>

                    <ul class="footer-social">
                        <li>
                            <a
                                target="_blank"
                                href="https://facebook.com/sibasiltd"
                                target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://twitter.com/sibasiltd"
                                target="_blank"
                                ><i class="fab fa-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/company/sibasiltd/"
                                target="_blank"
                                ><i class="fab fa-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.google.com/maps/place/Sibasi+Ltd/@-1.2197821,36.8717137,17z/data=!3m1!4b1!4m5!3m4!1s0x182f3e19076a52e9:0x145dbe08288f395b!8m2!3d-1.2197821!4d36.8739078"
                                target="_blank"
                                ><i class="fas fa-map-marked"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.instagram.com/sibasiltd/"
                                target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Important Links</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services-offered">Services</a></li>
                        <li>
                            <a routerLink="/solutions-offered">Solutions</a>
                        </li>
                        <li><a routerLink="/success">Success</a></li>
                        <li>
                            <a href="https://blog.sibasi.com" target="_blank"
                                >Blog</a
                            >
                        </li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Featured Solutions</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li>
                            <a routerLink="/solution-details/ebmsuite"
                                >EBM Suite (Business Management)</a
                            >
                        </li>
                        <li>
                            <a routerLink="/solution-details/electronic_board"
                                >eBoard (Board Management)</a
                            >
                        </li>
                        <!-- <li>
                            <a routerLink="/power-platform">Power Platform</a>
                        </li> -->
                        <li><a routerLink="/sharepoint">SharePoint</a></li>
                        <!-- <li>
                            <a routerLink="/data-analytics">Data Analytics</a>
                        </li> -->
                        <li><a routerLink="/dynamics">Dynamics 365</a></li>
                        <li>
                            <a routerLink="/monival">Monival (M&E Solution)</a>
                        </li>
                        <li>
                            <a routerLink="/web-and-mobile-development"
                                >Web & Mobile App Development</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Contact</h3>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-call-answer"></i>
                        <h3>Phone</h3>
                        <span
                            ><a href="tel:+254780742274"
                                >+254-780-SIBASI | +254-780-742274</a
                            ></span
                        >
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-envelope"></i>
                        <h3>Email</h3>
                        <span
                            ><a href="mailto:info@sibasi.com"
                                >info@sibasi.com</a
                            ></span
                        ><br/>
                        <span
                            ><a href="mailto:info@sibasi.com"
                                >info@sibasi.ltd</a
                            ></span
                        >
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-maps-and-flags"></i>
                        <h3>Address</h3>
                        <span
                            ><a
                                target="_blank"
                                href="https://www.google.com/maps/place/Sibasi+Ltd/@-1.2197821,36.8717137,17z/data=!3m1!4b1!4m5!3m4!1s0x182f3e19076a52e9:0x145dbe08288f395b!8m2!3d-1.2197821!4d36.8739078"
                                target="_blank"
                                >327B, Nyati Drive, Thome, Nairobi, Kenya</a
                            ></span
                        ><br />
                        <span>P.O. Box 37602 - 00100, GPO, Nairobi, Kenya</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <p>
                    Copyright 2011-{{ currentYear }}
                    <i class="far fa-copyright"></i> Sibasi Ltd. All Rights
                    Reserved.
                </p>
            </div>
            <div class="col-lg-6 col-md-6">
                <ul>
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li>
                        <a routerLink="/terms-and-conditions"
                            >Terms of Use</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>

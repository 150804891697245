<div
    class="page-title-area item-bg2"
    style="background-image: url(assets/img/bg_monival_graph.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ currentSolution?.title }}</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <a routerLink="/solutions-offered">Solutions</a>
                        </li>
                        <li>{{ currentSolution?.title }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="projects-details-desc">
                    <h3>{{ currentSolution?.title }}</h3>
                    <p
                        *ngFor="
                            let description of currentSolution?.descriptions
                        "
                    >
                        <i class="solutions-offered-tick flaticon-tick"></i>
                        <span [innerHTML]="description"></span>
                    </p>
                    <div class="row">
                        <div class="banner-btn row">
                            <div
                                class="banner-btn-1 col-auto ps-0"
                                *ngIf="currentSolution?.showRequestDemoBtn"
                            >
                                <a routerLink="/contact" class="default-btn"
                                    >Request Demo <span></span
                                ></a>
                            </div>
                            <div
                                class="col-auto"
                                *ngIf="currentSolution?.externalLink"
                            >
                                <a
                                    [href]="[currentSolution?.externalLink]"
                                    target="_blank"
                                    class="productive-btn-one"
                                    (click)="
                                        externalLinkClick(
                                            currentSolution?.externalLink
                                        )
                                    "
                                    >{{
                                        currentSolution?.externalLinkText ||
                                            "Visit Website"
                                    }}
                                    <span></span
                                ></a>
                            </div>
                        </div>

                        <!-- <div class="banner-btn" style="text-align: center;">
                            <a class="default-btn" href="assets/pdf/Monival Brochure by Sibasi Ltd.pdf" target="_blank">View brochure <span></span></a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div
                class="col-md-6 d-flex justify-content-center align-items-center"
            >
                <div class="project-details-image text-center">
                    <img
                        [src]="'assets/img/' + currentSolution?.images[0]"
                        alt="image"
                        width="1000"
                    />
                </div>
            </div>
            <div
                class="col-12 mt-4"
                *ngIf="currentSolution?.pricing?.length > 0"
            >
                <section class="pricing-area pt-50 pb-70">
                    <h5><b> Pricing </b></h5>
                    <div class="container pt-5">
                        <div class="row d-flex justify-content-center">
                            <div
                                class="col-lg-4 col-md-6"
                                *ngFor="let pricing of currentSolution?.pricing"
                            >
                                <div
                                    class="single-pricing-box h-100 d-flex flex-column"
                                >
                                    <div class="pricing-header">
                                        <h3>{{ pricing?.tier }}</h3>
                                        <p>{{ pricing?.description }}</p>
                                    </div>
                                    <div class="text-center">
                                        <small
                                            class="text-muted"
                                            *ngIf="pricing?.from"
                                            >from &nbsp;
                                        </small>
                                        <span class="price">{{
                                            pricing?.price
                                        }}</span>
                                        <span>{{
                                            pricing?.period
                                                ? "/ " + pricing?.period
                                                : ""
                                        }}</span>
                                    </div>
                                    <ul class="pricing-features">
                                        <li
                                            *ngFor="
                                                let feature of pricing?.features
                                            "
                                        >
                                            <i class="fas fa-check"></i>
                                            {{ feature }}
                                        </li>
                                    </ul>
                                    <div class="price-btn mt-auto">
                                        <a
                                            routerLink="/contact"
                                            class="price-btn-one"
                                            >Request Quote</a
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- offset-lg-0 offset-md-3 -->
                        </div>
                    </div>
                    <small class="text-muted mt-4">
                        * Exclusive of any applicable taxes.
                    </small>
                </section>
            </div>
        </div>
        <div class="row mt-4" *ngIf="currentSolution.id === 'licenses'">
            <div *ngIf="isLoading" class="loader">
                Loading Sibasi Licenses Request Form....
            </div>
            <div class="col-12">
                <iframe
                    #iframeElement
                    width="100%"
                    height="600"
                    src="https://siba.si/licenses"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="allowfullscreen"
                    (load)="onIframeLoad()"
                ></iframe>
            </div>
        </div>

        <div class="scrollable-wrapper mt-4">
            <h3>Other Solutions</h3>
            <ul class="nav nav-pills">
                <li
                    class="nav-item mb-2"
                    *ngFor="let solution of otherSolutionsOffered"
                >
                    <a
                        class="nav-link px-4 me-2"
                        aria-current="page"
                        [routerLink]="[
                            solution?.showRequestDemoBtn
                                ? '/solution-details/' + solution?.id
                                : '/' + solution?.id
                        ]"
                        >{{ solution.title }}</a
                    >
                </li>
            </ul>
        </div>
    </div>
</section>

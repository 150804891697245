<div
    class="page-title-area item-bg2"
    style="background-image: url(assets/img/hand-2548398.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">
                    <h3>PRIVACY POLICY</h3>
                    <div class="features-text">
                        Sibasi Ltd is committed to protecting your privacy and
                        being transparent about how we use your data. This
                        notice sets out the basis on which we collect and
                        process your personal data through your use of our
                        website, any of the software systems we provide,
                        registration for events and/or signing up for
                        newsletters and other marketing activities online. This
                        policy is guided by the laws of Kenya.
                    </div>

                    <h4>Information about us</h4>
                    <div class="features-text">
                        Sibasi Ltd is the controller responsible for your
                        personal data (referred to as "we", “us”, “our” in this
                        privacy notice). We are registered in Kenya. Our
                        registered office is at 327B, Thome, Nairobi, Kenya. Our
                        address is 37602-00100, GPO, Nairobi, Kenya. If you have
                        any questions about this privacy notice, the use of your
                        personal information or your dealings with our website
                        or any of the software systems we provide, you can
                        contact us at privacy&#64;sibasi.com.
                    </div>

                    <h4>What type of information is collected from you</h4>
                    <div class="features-text">
                        The information we collect depends on your interactions
                        with Sibasi Ltd and the choices you make with us,
                        including your privacy settings. The information we
                        collect can include any of the following:
                    </div>

                    <div class="features-text">
                        <p>
                            Personal information
                            <br />
                            Personal information is information that identifies
                            you as an individual or relates to an identifiable
                            individual, including: name, title, company name,
                            job title, postal address, telephone number or email
                            address. If you submit any Personal Information on
                            behalf of other people then you must have their
                            permission to do so in accordance with this Privacy
                            Policy.
                        </p>

                        <p>
                            Other information
                            <br />
                            Other Information is any information that does not
                            reveal your specific identity or does not directly
                            relate to an identifiable individual. This might
                            include, for instance, browser and device
                            information, information collected through cookies,
                            pixel tags and other technologies, demographic
                            information and other information provided by you or
                            aggregated information.
                        </p>

                        <p>
                            Location information
                            <br />
                            We collect information about your imprecise location
                            which includes, for example, a location derived from
                            your IP address or data that indicates where you are
                            located with less precision, such as at a city or
                            postal code level. Your IP address is a number that
                            is automatically assigned to the computer that you
                            are using by your Internet Service Provider (ISP).
                            Collecting IP addresses is standard practice and is
                            done automatically by many websites, applications
                            and other services.
                        </p>
                    </div>

                    <h4>How we collect information from you</h4>
                    <div class="features-text">
                        We may collect information from you in a variety of ways
                        including:
                        <p>
                            1. Personal information.
                            <br />
                            Through our website, solutions we provide (apps), or
                            partner websites: We may collect Personal
                            Information through our website, e.g., when you
                            request a product brochure, register for an event,
                            contact us, create an account or sign up, subscribe
                            to our newsletter or download content (e.g.
                            whitepapers).
                        </p>
                        <p>
                            2. Other information.
                            <br />
                            Through Your Browser or Device: Certain information
                            is collected by most browsers or automatically
                            through your device, such as your screen resolution,
                            operating system name and version, device
                            manufacturer and model, language, internet browser
                            type and version. Through Cookies and Other Similar
                            Technologies: Like many other websites, the Sibasi
                            Ltd website and software solutions use cookies and
                            web beacons. Web beacons and cookies are small
                            pieces of information sent by our servers to your
                            computer and stored on your hard drive to allow that
                            website or system to recognise you when you visit.
                            They collect statistical data about your browsing
                            actions and patterns.
                        </p>
                        <p>
                            It is possible to switch off cookies by setting your
                            browsers preferences. Turning cookies off may result
                            in a loss of functionality when using our website or
                            solutions we provide.
                        </p>
                        <p>
                            Sibasi Ltd web pages and software solutions may also
                            contain electronic images known as web beacons (also
                            called single-pixel gifs) that we use to help
                            deliver cookies on our websites, count users who
                            have visited those websites and deliver co-branded
                            products. We also include web beacons in our
                            promotional email messages or newsletters to
                            determine whether you open and act on them.
                        </p>
                    </div>

                    <h4>How We Protect Your Data</h4>
                    <div class="features-text">
                        <p>
                            1. Encryption.
                            <br />
                            We use encryption technologies to safeguard your
                            information during transmission and storage.
                        </p>
                        <p>
                            2. Confidentiality.
                            <br />
                            Security procedures are in place to protect the
                            confidentiality of your data, ensuring that only
                            authorized personnel can access it.
                        </p>
                        <p>
                            3. Monitoring and Audits.
                            <br />
                            We regularly review our security policies and
                            perform system audits to detect any potential
                            vulnerabilities.
                        </p>
                    </div>

                    <h4>What we use your personal data for</h4>
                    <div class="features-text">
                        The data protection law in the Kenya allows us to
                        collect, use, store and transfer personal information if
                        we have one or more of the following purposes for doing
                        so:
                        <br />
                        To fulfil a contract with you; or Where necessary to
                        comply with a legal or regulatory obligation; or Where
                        necessary for our legitimate interests (provided your
                        interests and fundamental rights do not override these
                        interests); or For other reasons where you have
                        expressly consented to those purposes. Below are the
                        ways we will use your data to fulfil your contract
                        and/or for our legitimate interests:
                    </div>

                    <h4>How We Use Google User Data</h4>
                    <div class="features-text">
                        If you provide access to your Google account information
                        via our application, we will use this data only for the
                        purposes outlined below:<br />
                        <p>
                            1. We will use your Google user data to provide you
                            with the services you requested, such as email
                            notifications, newsletters, and personalized
                            experiences within our platform.
                        </p>
                        <p>
                            2. We will use your Google information to sync your
                            calendar with ours and to create and manage Google
                            Meet meetings on your behalf, ensuring seamless
                            scheduling and collaboration.
                        </p>
                        <p>
                            3. We will not sell your data to third parties.
                            However, we may share your Google user data with
                            trusted partners who help us deliver our services,
                            such as hosting providers or customer support teams,
                            under strict confidentiality agreements.
                        </p>
                    </div>

                    <h4>How we use your personal information</h4>
                    <div class="features-text">
                        We may use your information to:
                        <br />
                        Send you information which you have requested and that
                        may be of interest to you. These may include information
                        about Sibasi Ltd, products and services we offer,
                        promotions of our associated companies or partners'
                        goods and services. Send you administrative information
                        such as notifying you of changes to our services, terms,
                        conditions and policies. To personalise your experience
                        on the website by presenting products and offers
                        tailored to you. To seek your views or comments on the
                        services we provide; To show you personalised product
                        and services advertisements on Microsoft, Google,
                        LinkedIn, Twitter and Facebook. The ads that you see may
                        be selected based on information we process about you,
                        such as your location or the content you view. For
                        example, if you view content on our website about the
                        Cloud, we may show you advertisements about Office 365.
                        We ask for your consent for such direct marketing when
                        you complete a registration with Sibasi Ltd.
                        <br />
                        We partner with various services including Google,
                        Facebook, X, Microsoft Clarity and Microsoft Advertising
                        to capture how you use and interact with our website
                        through behavioral metrics, heatmaps, and session replay
                        to improve and market our products/services.
                        <br />
                        Website and solutions provided by us' usage data is
                        captured using tracking technologies to determine the
                        popularity of products/services and online activity.
                        Additionally, we use this information for site
                        optimization, fraud/security purposes, and advertising.
                        <br />
                        For more information about how our partner organizations
                        (Google, Facebook, X, Microsoft) collect and use your
                        data, visit their respective Privacy Policy statements.
                    </div>
                    <h4>Who has access to your information?</h4>
                    <div class="features-text">
                        We do not sell or rent your personal information to
                        third parties. However, we may share your information
                        with: Service providers who help us operate our website,
                        software solutions and services; Payment processors;
                        Legal and regulatory authorities if required by law;
                        Other parties with your consent or at your direction.
                        <br />
                        Third Party Service Providers working on our behalf: We
                        share personal data among Sibasi Ltd-controlled
                        affiliates and subsidiaries.
                        <br />
                        We also share personal data with vendors or agents
                        working on our behalf for the purposes described in this
                        statement. For example, companies we've hired to provide
                        customer service support or assist in data cleansing may
                        need access to personal data to provide those functions.
                        We require all third parties engaged by us to respect
                        the security of your personal data and to treat it in
                        accordance with the law. Such third parties are only
                        allowed to process your personal data in accordance with
                        our instructions, and not for their own purposes.
                    </div>

                    <h4>
                        How to access &amp; control your personal information
                    </h4>
                    <div class="features-text">
                        For requests to access or delete your personal
                        information, contact Sibasi Ltd by emailing
                        privacy&#64;sibasi.com. We will respond to your request
                        within 120 days.
                    </div>

                    <h4>International data transfer</h4>
                    <div class="features-text">
                        Your personal data may be transferred to, and processed
                        in, countries other than the country in which you are
                        resident. These countries may have data protection laws
                        that are different from the laws of your country. We
                        ensure that such transfers comply with applicable legal
                        requirements.
                    </div>

                    <h4>How long we keep your personal data</h4>
                    <div class="features-text">
                        We will hold your personal information on our systems
                        for as long as is necessary for the relevant activity,
                        or as long as is set out in any relevant contract you
                        hold with us. Thereafter we may keep it for longer in
                        order to respond to any questions or complaints and to
                        maintain necessary records to satisfy legal, accounting
                        or reporting requirements. We may also keep your data
                        for research or statistical purposes.
                    </div>

                    <h4>Your rights</h4>
                    <div class="features-text">
                        You have the right to access, correct or request erasure
                        of your personal data and to request the transfer of the
                        data to you or a third party. You may object to the
                        processing of your data where we are relying on a
                        legitimate interest and may ask us to restrict it
                        processing.
                        <br />
                        Where we are relying on your consent to the processing
                        (such as direct marketing) you may withdraw your consent
                        at any time. We may need to request specific information
                        from you to help us confirm your identity and ensure
                        your right to access your personal data (or to exercise
                        any of your other rights). This is a security measure to
                        ensure that personal data is not disclosed to any person
                        who has no right to receive it.
                        <br />
                        We may also contact you to ask you for further
                        information in relation to your request to speed up our
                        response. However, we would appreciate the chance to
                        deal with any concerns you may have before you approach
                        the Information Commissioner's Office so please contact
                        us in the first instance.
                        <br />
                        If you have any questions or require any more
                        information, please contact us at privacy&#64;sibasi.com
                    </div>
                    <h4>Contact Us</h4>
                    <div class="features-text">
                        If you have any questions or concerns about this Privacy
                        Policy, please contact us at:
                        <br />
                        Sibasi Ltd, 37602-00100, GPO, Nairobi, Kenya,
                        privacy&#64;sibasi.com
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

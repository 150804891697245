<div
    class="page-title-area item-bg3"
    style="
        background-image: url(assets/img/sibasi/sibasi-delivers-excellent-ICT-services-through-an-amazing-team.jpg);
    "
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Our Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>What We Do</li>
                        <li>Our Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-details-area mt-4" style="margin-bottom: 50px">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 order-sm-2">
                    <div class="services-details-image">
                        <img
                            src="assets/img/sibasi/sibasi-services-coding.jpg"
                            alt="image"
                        />
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 order-sm-1">
                    <div class="services-details-desc">
                        <h3>We are here for you...</h3>
                        <p>
                            We offer a wide range of technological Services
                            through our expert-team. Our team comprises of
                            software engineers and business analysts who have a
                            wide range of experience providing core ICT services
                            and Consulting services for a wide range of
                            enterprise challenges and problems. The team is
                            capable of providing deployment, consulting and
                            management advisory as part of the ICT related
                            services as well as the Business related consulting
                            in a number of domains related to the software
                            solutions that we provide.<br />
                            This includes Customer Relationship Management
                            (CRM), Monitoring and Evaluation (M&E), Project
                            Management, Business Intelligence and Reporting,
                            migrating to the Cloud and other business related
                            areas.
                        </p>
                        <div class="features-text">
                            <h4>The team</h4>
                            <p>
                                Our team also includes software engineers,
                                designers and data scientists. This team can
                                engage in web and mobile development, graphic
                                and web app designing, data analytics and data
                                science to provide you insights into your
                                operations.
                            </p>
                        </div>
                    </div>
                    <div class="banner-btn row">
                        <div class="banner-btn-1 col-auto ps-0">
                            <a routerLink="/contact" class="default-btn"
                                >Request Demo <span></span
                            ></a>
                        </div>
                        <div class="col-auto">
                            <a routerLink="/contact" class="productive-btn-one"
                                >Contact Us <span></span
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="about-section">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12" *ngIf="false">
                        <div class="about-image">
                            <img
                                src="assets/img/vision.svg"
                                alt="image"
                                style="height: 300px"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="about-content">
                            <h3>Some of the services we offer</h3>
                            <p>We are experts in:</p>
                            <ul class="about-list">
                                <li
                                    *ngFor="
                                        let serviceOffered of servicesOffered
                                    "
                                >
                                    <i class="flaticon-tick"></i>
                                    <span class="title">{{
                                        serviceOffered?.title
                                    }}</span>
                                    <p class="mb-2">
                                        {{ serviceOffered?.description }}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <small class="text-muted mt-4">
                edX® and Open edX® are registered trademarks of edX LLC.
                MicroMasters and MicroBachelors are registered trademarks of The
                Center for Reimagining Learning. All Rights Reserved.
            </small>
        </section>
    </div>
</section>

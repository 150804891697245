<div class="page-title-area item-bg2" style="background-image: url(assets/img/hand-2548398.jpg)">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Terms of Use</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Terms of Use</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">
                    <h3>TERMS OF USE</h3>

                    <h4>Introduction</h4>
                    <div class="features-text">
                        Welcome to Sibasi.
                        <br />
                        These Terms of Use ("Terms") govern your use of the Sibasi website and the various solutions
                        provided by Sibasi, including various software-as-a-service (SaaS) offerings, Microsoft store
                        published solutions, Google Play published solutions, Apple store published solutions and any
                        software systems provided by Sibasi (collectively, the "Services"). By accessing or using our
                        Services, you agree to be bound by these Terms. If you do not agree with any part of these
                        Terms, you must not use our Services.
                    </div>

                    <h4>Who we are</h4>
                    <div class="features-text">
                        Sibasi is a technology company headquartered in Nairobi, Kenya, registration number PVT-Y2U93YZ.
                        Our address is 37602-00100, Nairobi, Kenya. You can contact us by email at info@sibasi.com.
                        Our primary domains are sibasi.com and sibasi.ltd.
                    </div>

                    <h4>Your Consent</h4>
                    <div class="features-text">
                        By accessing or using "Services" including our website, mobile applications,
                        social media applications, email exchanges, and/or any
                        related services (including newsletters, sales,
                        marketing) (collectively, the “Services”), you agree to
                        these terms of use (“Terms”). These Terms do not apply
                        to any of our separately branded products or
                        services.<br /><br />
                        Where your access and use are on behalf of another
                        person (such as a company), you agree to these Terms on
                        that person’s behalf and that, by agreeing to these
                        Terms on that person’s behalf, that person is bound by
                        these Terms. “You” means you or (if applicable) both you
                        and the other person on whose behalf you are acting.<br /><br />
                        Minimum age. You must be at least 13 years old or the minimum age required in your country to
                        consent to use the Services. If you are under 18 you must have your parent or legal guardian’s
                        permission to use the Services.
                        <br /><br />
                        Please read these Terms carefully. You must immediately
                        stop using our Services if you do not
                        agree to them.
                    </div>

                    <h4>Your Obligations</h4>
                    <div class="features-text">
                        You must provide true, current and complete information
                        in your dealings with and must promptly update that
                        information as required so that the information remains
                        true, current and complete.<br /><br />
                        Unless we agree otherwise, you must access our Services via standard web browsers only and
                        not by any other method (for example, scraping,
                        deep-linking, harvesting, data mining, use of a robot or
                        spider, automation, or any similar data gathering,
                        extraction or monitoring method.<br /><br />
                        We prefer that you ask for our consent before you link
                        to any part of our Services. We reserve the right, in our
                        sole discretion, to reject or withdraw any linking
                        deemed to be inappropriate, offensive, or associated
                        with any illegal activities, or for any other reason.<br /><br />
                        You may use our Services only for the purpose of which
                        it is provided. You must not use our Services for any
                        fraudulent or other unlawful activity or otherwise do
                        anything to damage or disrupt our Services.<br /><br />
                        You must not do, or attempt to do, anything that:<br /><br />
                        <span class="ms-4">
                            a. is prohibited by any laws which apply to our
                            website, other Services or to your use of them.<br /><br /></span>
                        <span class="ms-4">
                            b. we would consider inappropriate; or<br /><br />
                        </span>
                        <span class="ms-4">
                            c. which might bring us, our website or other
                            Services into disrepute;
                        </span>
                        <span class="ms-4">
                            d. uses the Services in any manner that could disable, overburden, damage, or impair the
                            Services or interfere with any other party’s use of the Services;
                        </span>
                        <span class="ms-4">
                            e. introduces any viruses, Trojan horses, worms, logic bombs, or other material that is
                            malicious or technologically harmful;
                        </span>
                        <span class="ms-4">
                            f. impersonate or attempt to impersonate Sibasi, a Sibasi employee, another user, or any
                            other person or entity;
                        </span>
                        <br /><br />
                        (collectively, “Prohibited Acts”).
                    </div>

                    <h4>Microsoft AppSource Terms</h4>
                    <div class="features-text">
                        Installation of any app hosted on the Microsoft
                        AppSource store and created by Sibasi is subject to
                        sharing of contact and email information to be used for
                        marketing of services and related products. By using the service you also agree to the related
                        terms from Microsoft. Marketing
                        services include but are not limited to email campaigns,
                        blog updates, product sales, company announcements, or
                        event dates and times. At any time, you have the choice
                        to opt out of our marketing services after installing
                        the app. You understand that any information provided is
                        handled and stored as per our Privacy Policy.
                    </div>

                    <h4>Apple Store Terms</h4>
                    <div class="features-text">
                        Installation of any app hosted on the Apple
                        store and created by Sibasi is subject to
                        sharing of contact and email information to be used for
                        marketing of services and related products. By using the service you also agree to the related
                        terms from Apple. Marketing
                        services include but are not limited to email campaigns,
                        blog updates, product sales, company announcements, or
                        event dates and times. At any time, you have the choice
                        to opt out of our marketing services after installing
                        the app. You understand that any information provided is
                        handled and stored as per our Privacy Policy.
                    </div>

                    <h4>Google Playstore Terms</h4>
                    <div class="features-text">
                        Installation of any app hosted on the Google
                        store and created by Sibasi is subject to
                        sharing of contact and email information to be used for
                        marketing of services and related products. By using the service you also agree to the related
                        terms from Google. Marketing
                        services include but are not limited to email campaigns,
                        blog updates, product sales, company announcements, or
                        event dates and times. At any time, you have the choice
                        to opt out of our marketing services after installing
                        the app. You understand that any information provided is
                        handled and stored as per our Privacy Policy.
                    </div>

                    <h4>Intellectual Property</h4>
                    <div class="features-text">
                        We (and, where applicable, our licensors or content
                        suppliers) own all proprietary and intellectual property
                        rights in our website and other Services (including all
                        information, data, text, graphics, artwork, photographs,
                        logos, icons, sound recordings, videos and look and
                        feel), and any network, system, software, data or
                        material that underlies or is connected to our website
                        and other Services.<br /><br />
                        Your use of our website and other Services, plus your
                        use of and access to any Content, does not grant or
                        transfer to you any rights, title or interest in
                        relation to our Website, other Service or Content. You
                        must not, without our prior written consent:<br /><br />
                        <span class="ms-4">
                            1. copy or use, in whole or in part, any Content.<br /><br /></span>
                        <span class="ms-4">
                            2. reproduce, modify, retransmit, distribute,
                            disseminate, sell, publish, broadcast or circulate
                            any Content to any third party; or<br /><br /></span>
                        <span class="ms-4">
                            3. breach any intellectual property rights connected
                            with our website, other Services or the Content,
                            including (without limitation) altering or modifying
                            any of the Content, causing any of the Content to be
                            framed or embedded in another Website or platform,
                            or creating derivative works from the Content.</span>
                    </div>

                    <h4>Indemnity</h4>
                    <div class="features-text">
                        You indemnify us against all Loss suffered or incurred as
                        a direct or indirect result of:<br /><br />
                        <span class="ms-4">
                            a. your failure to comply with these Terms,
                            including any failure of a person who accesses and
                            uses our website or other Services by using your
                            Password.<br /><br /></span>
                        The word “Loss” in these Terms includes loss of profit,
                        savings, revenue or data, and any other claim, damage,
                        loss, liability and cost, including legal costs on a
                        solicitor-client basis.
                    </div>

                    <h4>Liability</h4>
                    <div class="features-text">
                        To the maximum extent permitted by law:<br /><br />
                        <span class="ms-4">
                            a. you access and use our website and Services is at your own risk;
                            and<br /><br /></span>
                        <span class="ms-4">
                            b. we are not liable or responsible to you or any
                            other person for any Loss under or in connection
                            with these Terms, our website or other Services or
                            your access and use of (or inability to access or
                            use) our website or other Services provided by us.<br /><br /></span>

                        This exclusion applies regardless of whether our
                        liability or responsibility arises in contract, tort
                        (including negligence), equity, breach of statutory
                        duty, or otherwise.<br /><br />
                        This includes Loss suffered by you or any third party,
                        arising from or in connection with:<br /><br />
                        <span class="ms-4">
                            1. any inaccessibility of, interruption to or outage
                            of our Website or Services;<br /><br /></span>
                        <span class="ms-4">
                            2. any exposure to viruses or other forms of
                            interference which may damage your computer system
                            or expose you to fraud when you access or use our
                            website or other Services. (To avoid doubt, you are
                            responsible for ensuring the process by which you
                            access and use our website and other Services
                            protects you from this).</span>
                    </div>

                    <h4>Changes</h4>
                    <div class="features-text">
                        We may change these Terms from time to time by updating
                        them on our website and Services. Unless stated otherwise, any change
                        takes effect immediately. You are responsible for
                        ensuring you are familiar with the latest Terms. By
                        continuing to use our website and other Services, you
                        agree to the amended Terms. If you do not agree to the
                        changes, you must stop using our website and other
                        Services immediately. You may request additional processing in relation with our <a
                            routerLink="/privacy-policy">Privacy Policy</a>.<br /><br />
                    </div>
                    <div class="features-text">
                        The Terms were last updated in July 2024.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

import { Component, OnInit } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router) {}

    ngOnInit() {
        this.recallJsFuntions();
        this.initializeCookie();
    }

    recallJsFuntions() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                $(".preloader").fadeIn("slow");
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                $.getScript("../assets/js/main.js");
                $(".preloader").fadeOut("slow");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    initializeCookie(): void {
        let cc = window as any;
        cc.cookieconsent.initialise({
            palette: {
                popup: {
                    background: "#164969",
                },
                button: {
                    background: "#ffe000",
                    text: "#164969",
                },
            },
            theme: "classic",
            content: {
                message:
                    "This website uses cookies to ensure you get the best experience on our website.",
                dismiss: "Got it!",
                link: "Learn more",
                href: "/privacy-policy",
            },
        });
    }
}

<div class="solution-nav">
    <div
        class="page-title-area item-bg2"
        [style.background-image]="
            'url(' + 'assets/img/solutions/' + portfolio?.image + ')'
        "
    >
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h2>{{ portfolio?.name }}</h2>
                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li>
                                <a routerLink="/portfolio">Solutions</a>
                            </li>
                            <li>{{ portfolio?.name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <nav class="navbar navbar-expand-lg bg-white shadow-sm pb-0 sticky">
        <div class="container">
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <ng-container *ngFor="let section of sections">
                        <li
                            class="nav-item me-4"
                            *ngIf="checkIfSectionIsProvided(section.sectionId)"
                        >
                            <a
                                class="nav-link"
                                (click)="scrollToSection(section.sectionId)"
                                [ngClass]="{
                                    'active-link':
                                        activeSection === section.sectionId
                                }"
                                >{{ section.title }}</a
                            >
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </nav>

    <div class="container my-5" id="overview" *ngIf="portfolio?.overview">
        <div class="solution-overview">
            <div>
                <small class="text-primary"> OVERVIEW </small>
            </div>
            <div class="solution-heading">{{ portfolio?.overview.title }}</div>
            <p class="mt-2">
                {{ portfolio?.overview.description }}
            </p>
            <div class="scrollable-wrapper mt-4">
                <ul class="nav nav-pills">
                    <li
                        class="nav-item"
                        *ngFor="let category of portfolio?.overview.categories"
                    >
                        <a
                            class="nav-link px-4 me-2"
                            aria-current="page"
                            [ngClass]="
                                selectedOverviewCategory?.id === category.id
                                    ? 'active '
                                    : ''
                            "
                            (click)="setSelectedCategory(category.id)"
                            >{{ category.title }}</a
                        >
                    </li>
                </ul>
            </div>
            <div class="mt-4">
                <div class="row">
                    <div class="col-sm-4">
                        <div
                            class="accordion accordion-flush"
                            id="accordionFlushExample"
                        >
                            <div
                                class="accordion-item"
                                *ngFor="
                                    let subCategory of selectedOverviewCategory.subCategory;
                                    index as i
                                "
                            >
                                <h2 class="accordion-header">
                                    <button
                                        class="accordion-button"
                                        [ngClass]="i === 0 ? '' : 'collapsed'"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="
                                            '#flush-collapseOne' +
                                            subCategory.id
                                        "
                                        [attr.aria-expanded]="
                                            i === 0 ? 'true' : 'false'
                                        "
                                        [attr.aria-controls]="
                                            'flush-collapseOne' + subCategory.id
                                        "
                                        (click)="
                                            setSelectedSubCategory(
                                                subCategory.id
                                            )
                                        "
                                    >
                                        {{ subCategory.title }}
                                    </button>
                                </h2>
                                <div
                                    [id]="'flush-collapseOne' + subCategory.id"
                                    class="accordion-collapse collapse"
                                    [ngClass]="{ show: i === 0 }"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div class="accordion-body">
                                        {{ subCategory.description }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="accordion-item"
                                *ngIf="selectedOverviewCategory.link"
                            >
                                <h2 class="accordion-header">
                                    <button
                                        [ngClass]="
                                            selectedOverviewCategory.subCategory
                                                .length -
                                                1 ===
                                            0
                                                ? ''
                                                : 'collapsed'
                                        "
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="
                                            '#flush-collapseOne' + 'demo'
                                        "
                                        [attr.aria-expanded]="
                                            selectedOverviewCategory.subCategory
                                                .length -
                                                1 ===
                                            0
                                                ? 'true'
                                                : 'false'
                                        "
                                        [attr.aria-controls]="
                                            'flush-collapseOne' + 'demo'
                                        "
                                        (click)="setSelectedSubCategory('demo')"
                                    >
                                        View Demo
                                    </button>
                                </h2>
                                <div
                                    [id]="'flush-collapseOne' + 'demo'"
                                    class="accordion-collapse collapse"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div class="accordion-body">
                                        <span>
                                            Explore our
                                            {{ selectedOverviewCategory.title }}
                                            item in action.
                                        </span>

                                        <button
                                            type="button"
                                            class="btn btn-primary py-2 px-3 my-4"
                                            (click)="
                                                openExternalLink(
                                                    selectedOverviewCategory.link
                                                )
                                            "
                                        >
                                            Go to demo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div
                            class="d-flex align-items-center justify-content-center"
                        >
                            <img
                                [src]="
                                    'assets/img/solutions/' +
                                    selectedOverViewSubCategory.image
                                "
                                alt="Overview Image"
                                loading="lazy"
                                data-oc="oc85cb"
                                id="image-oc85cb"
                                class="shadow-sm p-4 rounded"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div
        class="solutions-cta-section mt-5"
        style="background-color: #0d1d45"
        id="economicImpact"
        *ngIf="portfolio?.economicImpact"
    >
        <div class="container text-center text-white py-5">
            <div class="solution-heading">
                {{ portfolio?.economicImpact.title }}
            </div>
            <p class="mt-2 text-white">
                {{ portfolio?.economicImpact.description }}
            </p>
            <div class="my-5">
                <button type="button" class="btn btn-light">
                    Read the Study
                </button>
            </div>
            <div class="d-flex justify-content-center">
                <div class="w-75">
                    <div class="row">
                        <div
                            class="col"
                            *ngFor="
                                let impact of portfolio?.economicImpact.impact
                            "
                        >
                            <div class="fs-3">{{ impact.value }}</div>
                            <small>{{ impact.description }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="py-4" id="faqs" *ngIf="selectedOverviewCategory.faqs">
        <div class="container">
            <div class="pricing-header mb-4 mx-auto text-center">
                <small class="text-primary"
                    >{{ selectedOverviewCategory.title }}
                </small>
                <div class="solution-heading">Frequently Asked Questions</div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div>
                        <div
                            class="projects-details-desc"
                            *ngFor="let faq of selectedOverviewCategory.faqs"
                        >
                            <h3>{{ faq.groupName }}</h3>
                            <div
                                class="features-text"
                                *ngFor="let question of faq.questions"
                            >
                                <h4>Q: {{ question.question }}</h4>
                                <p>A: {{ question.answer }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pricing-section" id="pricing" *ngIf="portfolio?.pricing">
        <div class="container">
            <div class="pricing-header mb-4 mx-auto text-center">
                <small class="text-primary">PRICING</small>
                <div class="solution-heading">
                    {{ portfolio?.pricing.title }}
                </div>
            </div>
            <div class="row justify-content-center">
                <div
                    class="col-md-4 col-sm-6"
                    *ngFor="let tier of portfolio?.pricing.tiers"
                >
                    <div class="card shadow border-0 p-3 mb-5 solution-card">
                        <div class="card-body">
                            <h5 class="card-title mb-3">
                                {{ tier.title }}
                            </h5>
                            <p class="card-text">
                                {{ tier.info }}
                            </p>
                            <div class="d-flex flex-column gap-0 mt-5">
                                <span class="fs-1">{{ tier.amount }}</span>
                                <small>
                                    {{ tier.period }}
                                </small>
                            </div>
                            <button
                                type="button"
                                class="btn btn-primary py-2 px-3 mb-5"
                                [routerLink]="['/contact']"
                            >
                                Buy now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="partner-section" id="partners" *ngIf="portfolio?.partners">
        <div class="container">
            <div
                class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-left"
            >
                <small class="text-primary">PARTNERS</small>
                <p class="solution-heading">{{ portfolio?.partners.title }}</p>
            </div>
            <div class="row row-cols-1 row-cols-md-3 g-4">
                <div
                    class="col"
                    *ngFor="let partner of portfolio?.partners.partner"
                >
                    <div class="card shadow border-0 p-2 mb-5 solution-card">
                        <img
                            [src]="'assets/img/solutions/' + partner.image"
                            class="card-img-top"
                            alt="consulting services"
                        />
                        <div class="card-body p-4">
                            <h5 class="card-title mb-3">{{ partner.name }}</h5>
                            <p class="card-text">
                                {{ partner.description }}
                            </p>
                            <div
                                class="d-flex align-items-center"
                                *ngIf="partner.link"
                            >
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm py-2 px-2 mt-5"
                                >
                                    <i
                                        class="fa fa-caret-right px-2"
                                        aria-hidden="true"
                                    ></i>
                                </button>

                                <a
                                    type="button"
                                    class="btn btn-link btn-sm mt-5"
                                    [href]="partner.link"
                                >
                                    Browse services
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="customer-section"
        id="testimonials"
        *ngIf="portfolio?.testimonials"
    >
        <div class="container">
            <div class="d-flex justify-content-between align-items-between">
                <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 text-left">
                    <small class="text-primary">CUSTOMER STORIES</small>
                    <p class="solution-heading">
                        {{ portfolio?.testimonials.title }}
                    </p>
                </div>
                <!-- <div class="d-flex align-items-center">
                    <button type="button" class="btn btn-primary">
                        See all customer stories
                    </button>
                </div> -->
            </div>
            <div class="row row-cols-1 row-cols-md-3 g-4">
                <div
                    class="col"
                    *ngFor="let testimony of portfolio?.testimonials.testimony"
                >
                    <div class="card shadow border-0 p-2 mb-5 solution-card">
                        <img
                            [src]="'assets/img/solutions/' + testimony.image"
                            class="card-img-top"
                            alt="consulting services"
                        />
                        <div class="card-body p-4">
                            <small>Brief</small>
                            <h5 class="card-title mb-3">
                                {{ testimony.name }}
                            </h5>
                            <p class="card-text">
                                {{ testimony.description }}
                            </p>
                            <div
                                class="d-flex align-items-center"
                                *ngIf="testimony.link"
                            >
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm py-2 px-2 mt-5"
                                >
                                    <i
                                        class="fa fa-caret-right px-2"
                                        aria-hidden="true"
                                    ></i>
                                </button>

                                <a
                                    type="button"
                                    class="btn btn-link btn-sm mt-5"
                                    [href]="testimony.link"
                                >
                                    Learn more
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="resources-section" id="resources" *ngIf="portfolio?.resources">
        <div class="container">
            <div class="d-flex justify-content-between align-items-between">
                <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 text-left">
                    <small class="text-primary">RESOURCES</small>
                    <p class="solution-heading">
                        {{ portfolio?.resources.title }}
                    </p>
                </div>
                <!-- <div class="d-flex align-items-center">
                    <button type="button" class="btn btn-primary">
                        See all customer stories
                    </button>
                </div> -->
            </div>
            <div class="row row-cols-1 row-cols-md-3 g-4">
                <div class="col" *ngFor="let resource of portfolio?.resources">
                    <div class="card shadow border-0 p-2 mb-5 solution-card">
                        <img
                            [src]="'assets/img/solutions/' + resource.image"
                            class="card-img-top"
                            alt="consulting services"
                        />
                        <div class="card-body p-4">
                            <small>{{ resource.type }}</small>
                            <h5 class="card-title mb-3">
                                {{ resource.title }}
                            </h5>
                            <p class="card-text">
                                {{ resource.description }}
                            </p>
                            <div
                                class="d-flex align-items-center"
                                *ngIf="resource.link"
                            >
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm py-2 px-2 mt-5"
                                >
                                    <i
                                        class="fa fa-caret-right px-2"
                                        aria-hidden="true"
                                    ></i>
                                </button>

                                <a
                                    type="button"
                                    class="btn btn-link btn-sm mt-5"
                                    [href]="resource.link"
                                >
                                    Explore More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cta-section" id="nextSteps" *ngIf="portfolio?.nextSteps">
        <div class="container">
            <div class="row row-cols-1 g-4">
                <div class="col" *ngFor="">
                    <div class="card shadow border-0 p-2 mb-5 solution-card">
                        <div class="card-body">
                            <div class="row">
                                <div
                                    class="col-md-6 order-md-1 order-2 d-flex flex-column justify-content-between"
                                >
                                    <small>NEXT STEPS</small>
                                    <span>
                                        <p
                                            class="card-title solution-heading mb-3"
                                        >
                                            {{ portfolio?.nextSteps.title }}
                                        </p>
                                        <p class="card-text">
                                            {{
                                                portfolio?.nextSteps.description
                                            }}
                                        </p>
                                    </span>
                                    <span>
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-sm py-2 px-2"
                                        >
                                            Try for free
                                        </button>
                                    </span>
                                </div>
                                <div class="col-md-6 order-md-2 order-1">
                                    <img
                                        [src]="
                                            'assets/img/solutions/' +
                                            portfolio?.nextSteps.image
                                        "
                                        class="card-img-right"
                                        alt="consulting services"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-3 g-4 mt-5">
                <div
                    class="col"
                    *ngFor="let nextStep of portfolio?.nextSteps.step"
                >
                    <div
                        class="card shadow border-0 p-2 mb-5 solution-card"
                        style="cursor: pointer"
                        [routerLink]="
                            nextStep.link ? nextStep.link : ['/contact']
                        "
                    >
                        <div class="card-body p-4">
                            <div class="mb-4">
                                <i
                                    class="text-primary border fs-3 p-3 rounded"
                                    [ngClass]="
                                        nextStep.icon
                                            ? nextStep.icon
                                            : 'fas fa-comment-dots '
                                    "
                                ></i>
                            </div>
                            <h5 class="card-title mb-3">
                                {{ nextStep.title }}
                            </h5>
                            <p class="card-text">
                                {{ nextStep.description }}
                            </p>
                            <div
                                class="d-flex align-items-center"
                                *ngIf="nextStep.link"
                            >
                                <!-- <button
                                    type="button"
                                    class="btn btn-primary btn-sm py-2 px-2 mt-3"
                                >
                                    <i
                                        class="fa fa-caret-right px-2"
                                        aria-hidden="true"
                                    ></i>
                                </button>

                                <a
                                    type="button"
                                    class="btn btn-link btn-sm mt-3"
                                    [href]="nextStep.link"
                                >
                                    {{nextStep.title}}
                                </a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ptb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="contact-image">
                                <img src="assets/img/contact.png" alt="image" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="contact-form">
                                <app-contact-form
                                    [solution]="portfolio?.name"
                                ></app-contact-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, inject, OnInit } from "@angular/core";
import { IService } from "src/app/models/service.model";
import { ServicesOfferedConst } from "./services-offered.const";
import { MetaTagService } from "../../../services/meta-tag.service";

@Component({
    selector: "app-services-offered",
    templateUrl: "./services-offered.component.html",
    styleUrls: ["./services-offered.component.scss"],
})
export class ServicesOfferedComponent implements OnInit {
    servicesOffered: IService[] = ServicesOfferedConst;
    private metaTagService = inject(MetaTagService);

    ngOnInit(): void {
        this.updatePageMetaTags();
    }

    updatePageMetaTags(): void {
        this.metaTagService.updateMetaTags(
            "Sibasi Services - Comprehensive IT Solutions",
            "Explore the wide range of IT services offered by Sibasi, including Business Intelligence, Cloud Computing, ERP Systems, E-Learning, HR Management, and more. Discover how our solutions can drive your business success.",
            "IT services, Business Intelligence, Cloud Computing, ERP Systems, E-Learning, HR Management, Sibasi, technology solutions"
        );
    }
}

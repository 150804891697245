<div
    class="page-title-area item-bg2"
    style="background-image: url(assets/img/bg_monival_graph.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>PORTFOLIO</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <a routerLink="/solutions-offered">Solutions</a>
                        </li>
                        <li>Portfolio</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container my-5">
    <div class="scrollable-wrapper">
        <ul class="nav nav-pills">
            <li class="nav-item" *ngFor="let filter of portfolioFilters">
                <a
                    class="nav-link px-4 me-2"
                    aria-current="page"
                    [ngClass]="selectedFilter?.id === filter.id ? 'active' : ''"
                    (click)="filterPortfoliosArray(filter)"
                    >{{ filter.name }}</a
                >
            </li>
        </ul>
    </div>
    <div class="grid">
        <div class="col-12">
            <div
                class="card my-5 rounded-5 solution-el"
                *ngFor="let portfolio of portfolios"
            >
                <div class="solution-container">
                    <div class="row">
                        <div
                            class="col-lg-6 col-12 solution-major-img"
                            [style.background-image]="
                                'url(' +
                                'assets/img/solutions/' +
                                portfolio?.image +
                                ')'
                            "
                        ></div>
                        <div class="col-lg-6 col-12">
                            <div class="solution-details p-5">
                                <h3 class="solution-heading">
                                    {{
                                        getFirstAndLastHalfOfName(
                                            portfolio?.name
                                        ).firstHalf
                                    }}
                                    <span class="text-primary">
                                        {{
                                            getFirstAndLastHalfOfName(
                                                portfolio?.name
                                            ).lastHalf
                                                ? getFirstAndLastHalfOfName(
                                                      portfolio?.name
                                                  ).lastHalf
                                                : ""
                                        }}
                                    </span>
                                </h3>
                                <img src="assets/img/star.svg" />
                                <p class="my-4">
                                    {{ portfolio?.shortDescription }}
                                </p>
                                <span
                                    *ngFor="
                                        let category of portfolio?.categories;
                                        let i = index
                                    "
                                    class="badge border border-secondary rounded-pill text-dark me-2 mt-2"
                                >
                                    {{ category }}
                                </span>

                                <!-- <div class="mt-4">
                                    <button
                                        type="button"
                                        class="btn btn-outline-primary me-3 solutions-action-btn"
                                        [routerLink]="
                                            '/portfolio-details/' +
                                            portfolio?.id
                                        "
                                    >
                                        VIEW DETAILS
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary solutions-action-btn"
                                        [routerLink]="['/contact']"
                                    >
                                        REQUEST A DEMO
                                    </button>
                                </div> -->

                                <div class="row">
                                    <div class="banner-btn row">
                                        <div class="banner-btn-1 col-auto ps-0">
                                            <a
                                                [routerLink]="
                                                    '/portfolio-details/' +
                                                    portfolio?.id
                                                "
                                                class="productive-btn-one"
                                                >View Details <span></span
                                            ></a>
                                        </div>
                                        <div class="col-auto">
                                            <a
                                                [routerLink]="['/contact']"
                                                class="default-btn"
                                                >Request a Demo <span></span
                                            ></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { inject, Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Injectable({
    providedIn: "root",
})
export class MetaTagService {
    private titleService = inject(Title);
    private metaService = inject(Meta);

    updateMetaTags(title: string, description: string, keywords: string): void {
        this.titleService.setTitle(title);
        this.metaService.updateTag({
            name: "description",
            content: description,
        });
        this.metaService.updateTag({ name: "keywords", content: keywords });
    }
}

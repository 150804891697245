import { Component, Input, ViewChild, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";
import { ContactService } from "../../../services/contact.service";
import {
    EHTTPMessageStatus,
    IHTTPMessage,
} from "src/app/models/HTTPMessage.model";

@Component({
    selector: "app-contact-form",
    standalone: true,
    templateUrl: "./contact-form.component.html",
    styleUrls: ["./contact-form.component.scss"],
    imports: [CommonModule, ReactiveFormsModule],
})
export class ContactFormComponent {
    @Input() solution!: string;
    contactForm: FormGroup;
    loader = false;
    conMessage!: IHTTPMessage;
    selectedTopic: string = "";
    EHTTPMessageStatus = EHTTPMessageStatus;

    // Services
    private contactService = inject(ContactService);
    private formBuilder = inject(FormBuilder);

    @ViewChild("iframeElement") iframeElement;
    isLoading = false;

    ngOnInit(): void {
        this.initializeContactForm();
    }

    ngAfterViewInit() {
        if (this.iframeElement) {
            this.iframeElement.nativeElement.onload =
                this.onIframeLoad.bind(this);
        }
    }

    onIframeLoad() {
        this.isLoading = false;
    }

    initializeContactForm(): void {
        this.contactForm = this.formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
            name: ["", Validators.required],
            phone: "",
            solution: [
                this.solution ? this.solution : "General",
                Validators.required,
            ],
            message: ["", Validators.required],
        });
    }

    onSubmitContact() {
        if (this.contactForm.valid) {
            this.loader = true;

            this.contactService.contact(this.contactForm.value).subscribe(
                (res) => {
                    this.loader = false;
                    this.conMessage = {
                        message: "Your message was sent successfully",
                        type: EHTTPMessageStatus.SUCCESS,
                    };
                },
                (err) => {
                    console.log(err);
                    this.loader = false;
                    this.conMessage = {
                        message:
                            "Sorry there seems to be an error on our end. You can send us your message at info@sibasi.ltd",
                        type: EHTTPMessageStatus.ERROR,
                    };
                },
                () => {}
            );
        } else {
            this.loader = false;
        }
    }

    listenToTopicSelectFormChange(event: any): void {
        this.selectedTopic = event.target.value;
        if (this.selectedTopic.toLowerCase() === "licenses") {
            this.isLoading = true;
            setTimeout(() => {
                if (this.iframeElement) {
                    this.iframeElement.nativeElement.onload =
                        this.onIframeLoad.bind(this);
                }
            });
        }
    }
}

<section class="subscribe-area" >
    <div class="container">
        <div class="row align-items-center w-auto">
            <div class="col-lg-8 col-md-8">
                <div class="subscribe-content">
                    <h2>Interested in finding out more?</h2>
                    <span class="sub-title">Leave us a message and we will get back as soon as possible (normally within 1 business day).</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                <div class="row" style="margin-left: 0px;">
                <div class="other-option w-auto" >
                    <a class="default-btn" routerLink="/contact" >Get in touch<span></span></a>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>

import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ISolution } from "src/app/models/solution.model";
import { SolutionsOfferedConst } from "../solutions-offered/solutions-offered.const";
import { MetaTagService } from "src/app/services/meta-tag.service";

declare var gtag;

@Component({
    selector: "app-solutions-offered-details",
    templateUrl: "./solutions-offered-details.component.html",
    styleUrls: ["./solutions-offered-details.component.scss"],
})
export class SolutionsOfferedDetailsComponent implements OnInit {
    solutionsOffered: ISolution[] = SolutionsOfferedConst;
    otherSolutionsOffered: ISolution[] = [];
    currentSolution: ISolution;

    @ViewChild("iframeElement") iframeElement;
    isLoading = false;

    private metaTagService = inject(MetaTagService);
    private activatedRoute = inject(ActivatedRoute);

    ngOnInit(): void {
        this.getSolutionIdFromParam();
    }

    ngAfterViewInit() {
        if (this.iframeElement) {
            this.iframeElement.nativeElement.onload =
                this.onIframeLoad.bind(this);
        }
    }

    getSolutionIdFromParam(): void {
        this.activatedRoute.params.subscribe({
            next: (params) => {
                const solutionId = params.solutionId || "licenses";
                if (solutionId === "licenses") this.isLoading = true;
                this.getSolutionDetails(solutionId);
            },
        });
    }

    getSolutionDetails(solutionId: string): void {
        this.currentSolution = this.solutionsOffered.find(
            (solution) => solution.id === solutionId
        );
        this.otherSolutionsOffered = this.solutionsOffered.filter(
            (x) => x.id !== solutionId
        );
        this.updatePageMetaTags(this.currentSolution);
    }

    externalLinkClick(link: string) {
        gtag("event", "conversion", {
            send_to: "AW-1015758125/ax-VCOKi9ogZEK36rOQD",
            value: 0.001,
            currency: "USD",
        });
    }

    updatePageMetaTags(solution: ISolution): void {
        this.metaTagService.updateMetaTags(
            `Sibasi Solution - ${solution.title}`,
            solution.shortDescription,
            solution.pricing
                ? solution.pricing[0].features.toString()
                : "Microsoft Licenses"
        );
    }

    onIframeLoad() {
        this.isLoading = false;
    }
}

<div
    class="page-title-area item-bg2"
    style="background-image: url(assets/img/mobile-app.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Web & Mobile Development</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <a routerLink="/solutions-offered">Solutions</a>
                        </li>
                        <li>Web & Mobile Development</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="image-sliders owl-carousel owl-theme">
                    <div class="project-details-image">
                        <img src="assets/img/flutter-logo.svg" alt="image" />
                    </div>
                    <div class="project-details-image">
                        <img
                            src="assets/img/android-seeklogo.com.svg"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="projects-details-desc">
                <h3>Web & Mobile Application Development</h3>
                <p>
                    We are experts at developing cross-platform, large and small
                    scale web & mobile applications.
                    <br />
                    Having experience at developing and deploying bespoke
                    applications for various organizations, we bring the best
                    software engineering practices to the development of
                    solutions.
                </p>
                <h4>Highlighted Technologies</h4>
                <div class="features-text">
                    <h5>Flutter</h5>
                    <p>
                        We user flutter, a Google UI toolkit for building
                        beautiful, natively compiled applications for mobile.
                    </p>
                    <p>
                        We have developed and deployed large scale flutter-based
                        applications on both Google Play Store and Apple Store.
                    </p>
                </div>
                <div class="features-text">
                    <h5>Android</h5>
                    <p>
                        Additionally we have android specific development skills
                        using kotlin or java
                    </p>
                    <p>
                        If your business requires a native specific application
                        we have you covered.
                    </p>
                </div>

                <div class="banner-btn" style="text-align: center">
                    <a class="default-btn" routerLink="/contact"
                        >Get in touch <span></span
                    ></a>
                </div>
                <div class="row my-4 solutions-offered">
                    <h3>Solutions built using this technology</h3>
                    <div class="row">
                        <div class="col-auto">
                            <a routerLink="/solution-details/monival"
                                >Monival</a
                            >
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/ebmsuite"
                                >EBM Suite</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img
                    src="assets/img/sibasi/logo-sibasi-white.png"
                    class="sibasi-logo white-logo"
                    alt="Sibasi Logo"
                />
                <img
                    src="assets/img/sibasi/logo-sibasi.png"
                    class="sibasi-logo black-logo"
                    alt="Sibasi Logo"
                />
            </a>

            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li
                        class="nav-item"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                    >
                        <a routerLink="/" class="nav-link"
                            >Home <i class="fas"></i
                        ></a>
                    </li>

                    <li
                        class="nav-item"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                    >
                        <a
                            routerLink="/about"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >About Us</a
                        >
                    </li>
                    <li class="nav-item">
                        <a routerLink="/services-offered" class="nav-link"
                            >What we do <i class="fas fa-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/services-offered"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Services</a
                                >
                            </li>
                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/solutions-offered"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Solutions</a
                                >
                                <ul class="dropdown-menu">
                                    <ng-container
                                        *ngFor="
                                            let solutionOffered of solutionsOffered
                                        "
                                    >
                                        <li
                                            class="nav-item"
                                            data-toggle="collapse"
                                            data-target=".navbar-collapse.show"
                                            *ngIf="
                                                solutionOffered?.menuItemName
                                            "
                                        >
                                            <a
                                                [routerLink]="[
                                                    '/solution-details',
                                                    solutionOffered?.id
                                                ]"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >{{
                                                    solutionOffered?.menuItemName
                                                }}</a
                                            >
                                        </li>
                                    </ng-container>
                                    <li
                                        class="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <a
                                            [routerLink]="[
                                                '/solutions-offered'
                                            ]"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >All Solutions</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/success"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Success</a
                                >
                            </li>
                        </ul>
                    </li>

                    <li
                        class="nav-item"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                    >
                        <a
                            routerLink="/portfolio"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Portfolio</a
                        >
                    </li>

                    <li
                        class="nav-item"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                    >
                        <a
                            href="https://blog.sibasi.com"
                            class="nav-link"
                            target="_blank"
                            >Blog</a
                        >
                    </li>
                </ul>

                <div
                    class="other-option"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                >
                    <a class="default-btn" routerLink="/contact"
                        >Contact Us <span></span
                    ></a>
                </div>
            </div>
        </nav>
    </div>
</div>

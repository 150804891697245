import { Component } from '@angular/core';
import { IPortfolioFaqs } from 'src/app/models/portfolio.model';
import { PortfolioFaqs } from '../faqs.const';

@Component({
    selector: 'app-solutions-faqs',
    templateUrl: './solutions-faqs.component.html',
    styleUrls: ['./solutions-faqs.component.scss']
})
export class SolutionsFaqsComponent {

    portfolioFaq: IPortfolioFaqs[] = PortfolioFaqs;
    selectedportfolioFaq = this.portfolioFaq[0];

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bigdata',
  templateUrl: './bigdata.component.html',
  styleUrls: ['./bigdata.component.scss']
})
export class BigdataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, inject, OnInit } from "@angular/core";
import { ISolution } from "src/app/models/solution.model";
import { SolutionsOfferedConst } from "./solutions-offered.const";
import { MetaTagService } from "../../../services/meta-tag.service";

@Component({
    selector: "app-solutions-offered",
    templateUrl: "./solutions-offered.component.html",
    styleUrls: ["./solutions-offered.component.scss"],
})
export class SolutionsOfferedComponent implements OnInit {
    solutionsOffered: ISolution[] = SolutionsOfferedConst;
    private metaTagService = inject(MetaTagService);

    ngOnInit(): void {
        this.updatePageMetaTags();
    }

    updatePageMetaTags(): void {
        this.metaTagService.updateMetaTags(
            "Sibasi Solutions - Innovative Technology for Business Success",
            "Discover innovative technology solutions from Sibasi, designed to transform your business. From AI and IoT to Business Intelligence and CRM, explore our offerings that drive efficiency and growth.",
            "Technology solutions, Business Transformation, AI, IoT, Business Intelligence, CRM, Sibasi, innovative technology"
        );
    }
}

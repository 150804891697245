import { Component, inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContactService } from "../../../services/contact.service";
import { MetaTagService } from "../../../services/meta-tag.service";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    // Services
    private contactService = inject(ContactService);
    private fb = inject(FormBuilder);
    private metaTagService = inject(MetaTagService);

    isValue: number = 1;
    subscribeForm: FormGroup;
    msg = false;

    ngOnInit(): void {
        this.subscribeForm = this.fb.group({
            email: ["", [Validators.required, Validators.email]],
        });
        this.updatePageMetaTags();
    }

    updatePageMetaTags(): void {
        this.metaTagService.updateMetaTags(
            "Sibasi Ltd | Services. Solutions. Success!",
            "Welcome to Sibasi Ltd. We are passionate about providing ICT Services and Solutions geared towards your Success! Our expertise ranges from Web apps and Mobile apps development, Deployment of solutions from partners such as Microsoft, Artificial Intelligence (AI), Business Process Automation (SharePoint & Power Apps), Customer Relationship Management (CRM), Data Analytics (Power BI), Business Intelligence and Cloud Computing. We are a Premier Microsoft Gold Partner in East and Central Africa.",
            "Technology Solutions, Business Transformation, ERP, Business Intelligence, E-Learning, HR Management, M&E Solution, EBM Suite"
        );
    }

    onSubmit() {
        console.log(this.subscribeForm.get("email").value);

        var email = {
            email: this.subscribeForm.get("email").value,
        };

        this.contactService.subscribeEmail(email).subscribe(
            (res) => {
                this.msg = true;
            },
            (err) => {},
            () => {}
        );
    }

    toggle1() {
        this.isValue = 1;
    }
    toggle2() {
        this.isValue = 2;
    }
    toggle3() {
        this.isValue = 3;
    }
    toggle4() {
        this.isValue = 4;
    }
}

<div
    class="page-title-area item-bg2"
    style="background-image: url(assets/img/keyboard.jpg)"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Power Platform</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <a routerLink="/solutions-offered">Solutions</a>
                        </li>
                        <li>Power platform</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="image-sliders owl-carousel owl-theme">
                    <div class="project-details-image">
                        <img src="assets/img/Power-Platform.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="projects-details-desc">
                <h3>Power Platform</h3>
                <p>
                    Power Platform is the combination of the robust power of
                    PowerApps, PowerBI, and Power Automate into one powerful
                    business application platform – providing quick and easy app
                    building, workflow automation and data insights built on
                    Dataverse, formerly known as the Common Data Model.
                </p>
                <p>
                    At Sibasi we use <a href="/data-analytics">Power BI</a> to
                    build informative reports and dashboards to display
                    important data about sales, customer service, and other
                    business functions. These dashboards and reports can be
                    shared within the organization to key consumers as well as
                    being published on websites, in SharePoint or Teams, and in
                    Apps.
                </p>
                <p>
                    We also leverage the power in Power Apps to build easy to
                    use applications for standard business needs. These range
                    from custom Canvas Apps to the Model-Driven Apps with
                    complex business logic and even the portal which can allow
                    external users to interact with the apps.
                </p>
                <p>
                    In addition to this, we use Power Automate (formerly
                    Microsoft Flow) and Logic Apps to create automated workflows
                    that enabling our clients to easily automate business
                    processes as well as the repetitive, time-consuming tasks,
                    hence providing a "better way to get things done.”
                </p>
                <div class="banner-btn" style="text-align: center">
                    <a class="default-btn" routerLink="/contact" href=""
                        >Request Demo<span></span
                    ></a>
                </div>
                <div class="row mt-4 solutions-offered">
                    <h3>Solutions built using this technology</h3>
                    <div class="row">
                        <div class="col-auto">
                            <a routerLink="/solution-details/crm">CRM</a>
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/electronic_board"
                                >eBoard</a
                            >
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/hr">HR</a>
                        </div>
                        <div class="col-auto">
                            <a
                                routerLink="/solution-details/contractsmanagement"
                                >Contracts</a
                            >
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/audit">Audit</a>
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/qms">QMS</a>
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/memomanagement"
                                >Memo</a
                            >
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/expensesclaims"
                                >Expense claim</a
                            >
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/assetmanagement"
                                >Asset management</a
                            >
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/ithelpdesk"
                                >IT Helpdesk</a
                            >
                        </div>
                        <div class="col-auto">
                            <a routerLink="/solution-details/lawyers_hub_system"
                                >Lawyer hub</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

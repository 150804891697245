import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-power-platform',
  templateUrl: './power-platform.component.html',
  styleUrls: ['./power-platform.component.scss']
})
export class PowerPlatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

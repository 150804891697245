<form
    id="contactForm"
    [formGroup]="contactForm"
    (ngSubmit)="onSubmitContact()"
    #myForm="ngForm"
    *ngIf="!conMessage?.message"
>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="form-group" *ngIf="!solution">
                <label for="cars" class="mb-2">Select a topic:</label>
                <select
                    name="solutions"
                    id="solutions"
                    class="form-control"
                    formControlName="solution"
                    (change)="listenToTopicSelectFormChange($event)"
                >
                    <option value="Licenses">Microsoft Licenses</option>
                    <option value="Cloud Migration">Migration to Cloud</option>
                    <option value="Dynamics 365 (CRM & ERP)">
                        Dynamics 365 (CRM & ERP)
                    </option>
                    <option value="Power Platform">
                        Microsoft Power Platform
                    </option>
                    <option value="SharePoint">
                        SharePoint (DMS/Intranet)
                    </option>
                    <option value="Data Analytics">
                        Data Analytics Business Reporting
                    </option>
                    <option value="Web or Mobile application development">
                        Web or Mobile Application Development
                    </option>
                    <option value="General" selected>General Enquiry</option>
                </select>
                <h5
                    *ngIf="
                        contactForm.get('solution').hasError('required') &&
                        myForm.submitted
                    "
                    class=""
                    style="color: red; font-weight: 100; font-size: 16px"
                >
                    This field is required*
                </h5>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="selectedTopic.toLowerCase() !== 'licenses'">
        <div class="col-lg-12 col-md-12">
            <div class="form-group">
                <input
                    type="text"
                    name="name"
                    id="name"
                    class="form-control"
                    placeholder="Name*"
                    formControlName="name"
                />
                <h5
                    *ngIf="
                        contactForm.get('name').hasError('required') &&
                        myForm.submitted
                    "
                    class=""
                    style="color: red; font-weight: 100; font-size: 16px"
                >
                    This field is required*
                </h5>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <input
                    type="email"
                    name="email"
                    id="email"
                    class="form-control"
                    placeholder="Email*"
                    formControlName="email"
                />
                <h5
                    *ngIf="
                        (contactForm.get('email').hasError('required') &&
                            myForm.submitted) ||
                        (contactForm.get('email').hasError('email') &&
                            myForm.submitted)
                    "
                    class=""
                    style="color: red; font-weight: 100; font-size: 16px"
                >
                    please provide a valid email
                </h5>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <input
                    type="number"
                    name="phone_number"
                    id="phone_number"
                    class="form-control"
                    placeholder="Phone"
                    formControlName="phone"
                    placeholder="+2547123456789"
                />
            </div>
        </div>

        <div class="col-lg-12 col-md-12">
            <div class="form-group">
                <textarea
                    name="message"
                    class="form-control"
                    id="message"
                    cols="30"
                    rows="6"
                    placeholder="Your Message"
                    formControlName="message"
                ></textarea>
                <h5
                    *ngIf="
                        contactForm.get('message').hasError('required') &&
                        myForm.submitted
                    "
                    class=""
                    style="color: red; font-weight: 100; font-size: 16px"
                >
                    This field is required*
                </h5>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <button type="submit" class="submit-btn" [disabled]="loader">
                Submit
                <div *ngIf="loader" class="spinner-border" role="status"></div>
            </button>
        </div>
    </div>

    <div *ngIf="isLoading" class="loader">
        Loading Sibasi Licenses Request Form....
    </div>

    <div class="row" *ngIf="selectedTopic.toLowerCase() === 'licenses'">
        <div class="col-12">
            <iframe
                #iframeElement
                width="100%"
                height="600"
                src="https://siba.si/licenses"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"
                (load)="onIframeLoad()"
            ></iframe>
        </div>
    </div>
</form>
<div
    *ngIf="conMessage?.message"
    class="mt-1s"
    style="padding: 4px"
    [ngClass]="{
        'alert-success': conMessage.type == EHTTPMessageStatus.SUCCESS,
        'alert-danger': conMessage.type == EHTTPMessageStatus.ERROR
    }"
>
    <h5>{{ conMessage.message }}</h5>
</div>

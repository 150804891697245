<div class="main-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h1>Welcome to Sibasi Ltd</h1>
                            <p
                                title="SIBASI means
                                Services and Solutions
                                Integrated and invested into your
                                Business for you to
                                Achieve your
                                Success and Impact"
                            >
                                We deliver Services and Solutions Integrated and
                                Invested into your Business for you to Achieve
                                Success and have an Impact. This is the Sibasi
                                Ltd mission!
                            </p>
                            <br />
                            <p class="">
                                We are a Microsoft Gold Partner specializing in
                                providing solutions and services for Business
                                Processes Automation, Data Analytics, Monitoring
                                and Evaluation (M&E), Enterprise Resource
                                Planning (ERP), Customer Relationship Management
                                (CRM), Intranets and Document Management (EDMS),
                                Web and Mobile Development, Artificial
                                Intelligence (AI).
                            </p>

                            <div class="banner-btn">
                                <a class="default-btn-one" routerLink="/about"
                                    >About Us <span></span
                                ></a>
                                <a class="default-btn" routerLink="/contact"
                                    >Contact Us <span></span
                                ></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img
                                src="assets/img/home-page/1.png"
                                class="wow zoomIn"
                                data-wow-delay="1s"
                                alt="image"
                            />
                            <img
                                src="assets/img/home-page/2.png"
                                class="wow fadeInUp"
                                data-wow-delay="1s"
                                alt="image"
                            />
                            <img
                                src="assets/img/home-page/3.png"
                                class="wow zoomIn"
                                data-wow-delay="1s"
                                alt="image"
                            />
                            <img
                                src="assets/img/home-page/4.png"
                                class="wow rotateIn"
                                data-wow-delay="1s"
                                alt="image"
                            />
                            <img
                                src="assets/img/home-page/main-image.png"
                                class="wow zoomIn"
                                data-wow-delay="1s"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h3>Services.Solutions.Success</h3>
            <br />
            <br />
            <p>
                At Sibasi Ltd we are focused on providing you with excellent
                services and the best solutions to ensure your success!
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-big-data"></i>
                    </div>
                    <h3>Services</h3>
                    <p>
                        We offer a wide range of Services related to the ICT
                        solutions we offer through our expert consultants and
                        specialists…
                    </p>
                    <div class="services-btn">
                        <a
                            routerLink="/services-offered"
                            class="services-btn-one"
                            >Read More <i class="flaticon-right"></i
                        ></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box" style="min-height: 271.67px">
                    <div class="icon">
                        <i class="flaticon-data-analytics"></i>
                    </div>
                    <h3>Solutions</h3>
                    <p>
                        We bring you technology solutions that are targeted to
                        help you achieve success. Our solutions are broad-based
                        for…
                    </p>
                    <div class="services-btn">
                        <a
                            routerLink="/solutions-offered"
                            class="services-btn-one"
                            >Read More <i class="flaticon-right"></i
                        ></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-document"></i>
                    </div>
                    <h3>Success</h3>
                    <p>
                        We are inspired and geared towards helping you achieve
                        your Success! We know that the definition of success
                        varies from…
                    </p>
                    <div class="services-btn">
                        <a routerLink="/success" class="services-btn-one"
                            >Read More <i class="flaticon-right"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="70">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Customers</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="150">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Projects</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="20">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Products</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="30">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Consultants</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="tab-section ptb-100">
    <div class="container">
        <div class="tab solutions-list-tab">
            <ul class="tabs">
                <li>
                    <a href="#"><span>Development</span></a>
                </li>
                <li>
                    <a href="#"><span>Deployment</span></a>
                </li>
                <li>
                    <a href="#"><span>Consulting</span></a>
                </li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Experts at Software Engineering</h3>
                                <p>
                                    At the core we are software engineers with
                                    expertise in web, mobile and server
                                    technologies. <br />
                                    We are proficient in a number of software
                                    languages including .Net, Dart, Java,
                                    Javascript/Typescript and Python. <br />
                                    We also make use of various frameworks and
                                    libraries including Angular, ReactJS, .Net
                                    Core and Flutter for cross-platform mobile
                                    app development.
                                </p>

                                <div
                                    class="row mt-3 mb-3 d-flex align-items-center"
                                    style="text-align: center"
                                >
                                    <img
                                        alt="Node Js"
                                        class="ml-2 col-auto"
                                        style="height: 56px"
                                        src="assets/img/Angular_full_color_logo.svg"
                                    />
                                    <img
                                        alt="Visual studio"
                                        class="ml-2 col-auto"
                                        style="height: 64px"
                                        src="assets/img/Node.js_logo.svg"
                                    />

                                    <img
                                        alt="Visual studio"
                                        class="ml-2 col-auto"
                                        style="height: 56px"
                                        src="assets/img/flutter-logo.svg"
                                    />
                                    <img
                                        alt="Visual studio"
                                        class="ml-2 col-auto"
                                        style="height: 56px"
                                        src="assets/img/android-seeklogo.com.svg"
                                    />
                                    <img
                                        alt="Visual studio"
                                        class="ml-2 col-auto"
                                        style="height: 56px"
                                        src="assets/img/Python-logo-notext.svg"
                                    />
                                </div>
                                <div class="tab-btn">
                                    <a
                                        class="default-btn"
                                        routerLink="/services-offered"
                                        >Read More <span></span
                                    ></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img
                                    src="assets/img/sibasi/sibasi-collaboration.png"
                                    alt="image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Experts at Setup & Configuration</h3>
                                <p>
                                    At Sibasi, we are experts at deployment and
                                    customization of a number of solutions. Some
                                    of the solutions we specialize in
                                    customizing and deploying include the
                                    Monitoring and Evaluation software, Monival;
                                    Microsoft software such as Office 365 for
                                    cloud hosted emails and other workloads,
                                    SharePoint for Document Management and
                                    Intranet solutions, Dynamics 365 for
                                    Customer Relationship Management (CRM) and
                                    Enterprise Resource Planning (ERP),
                                    Microsoft Azure cloud workloads setup,
                                    configuration & management. <br />
                                    We also assist organizations with on-going
                                    maintenance for the these type of solutions.
                                </p>
                                <div
                                    class="row mt-3 mb-3 d-flex align-items-center"
                                    style="text-align: center"
                                >
                                    <img
                                        alt="Sharepoint"
                                        class="ml-2 col-auto"
                                        style="width: 180px"
                                        src="assets/img/logo_sharepoint.png"
                                    />
                                    <img
                                        alt="Business central"
                                        class="ml-2 col-auto"
                                        style="width: 180px"
                                        src="assets/img/logo_businesscentral.png"
                                    />

                                    <img
                                        alt="Dynamics 365"
                                        class="ml-2 col-auto"
                                        style="width: 180px"
                                        src="assets/img/logo_dynamics365.png"
                                    />
                                    <img
                                        alt="Microsoft 365"
                                        class="ml-2 col-auto"
                                        style="width: 180px"
                                        src="assets/img/logo_microsoft365.png"
                                    />
                                    <img
                                        alt="Monival"
                                        class="ml-2 col-auto"
                                        style="width: 180px"
                                        src="assets/img/logo_monival.png"
                                    />
                                    <img
                                        alt="Open edX"
                                        class="ml-2 col-auto"
                                        style="width: 180px"
                                        src="assets/img/logo_openedx.png"
                                    />
                                </div>
                                <div class="tab-btn">
                                    <a
                                        class="default-btn"
                                        routerLink="/services-offered"
                                        >Read More <span></span
                                    ></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img
                                    src="assets/img/sibasi/sibasi-deployment.jpg"
                                    alt="image"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>
                                    Consultancy along with Technological
                                    Solutions
                                </h3>
                                <p>
                                    Our team comprises of experienced experts
                                    who provide consultancy based on technical
                                    know-how and working with customers across
                                    various industries. Some of the areas of
                                    expertise include Cloud Computing,
                                    Architecting Web and Mobile Applications,
                                    Data Science & Analytics, Business
                                    Intelligence and Reporting as well as Data
                                    Migrations, Artificial Intelligence,
                                    Integration and Warehousing.
                                </p>
                                <div
                                    class="row mt-3 d-flex align-items-center"
                                    style="text-align: center"
                                >
                                    <img
                                        alt="Node Js"
                                        class="ml-2 col-auto"
                                        style="width: 150px"
                                        src="assets/img/Microsoft_Azure_Logo.svg"
                                    />
                                    <img
                                        alt="Visual studio"
                                        class="ml-2 col-auto"
                                        style="width: 90px"
                                        src="assets/img/pngwing.com.png"
                                    />
                                    <img
                                        alt="Visual studio"
                                        class="ml-2 col-auto"
                                        style="width: 180px"
                                        src="assets/img/power-bi-vector-logo.png"
                                    />
                                </div>
                                <div class="tab-btn">
                                    <a
                                        class="default-btn"
                                        routerLink="/services-offered"
                                        >Read More <span></span
                                    ></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img
                                    src="assets/img/sibasi/sibasi-consulting.jpg"
                                    alt="image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section></section>

<section class="data-service-section pb-100">
    <div class="container">
        <div class="section-title">
            <h3>Some Interesting Areas We Thrive In...</h3>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="data-services-item">
                    <div
                        class="single-data-service-box"
                        routerLink="/services-offered"
                    >
                        <div class="icon">
                            <i class="flaticon-big-data"></i>
                        </div>
                        <h3>
                            <a
                                routerLink="/services-offered"
                                title="Sibasi are experts in Data Analytics, Business Intelligence Artificial Intelligence across Africa!"
                                >Data & Artificial Intelligence (AI)<i
                                    class="flaticon-right"
                                ></i
                            ></a>
                        </h3>
                    </div>

                    <div
                        class="single-data-service-box"
                        routerLink="/services-offered"
                    >
                        <div class="icon">
                            <i class="flaticon-science"></i>
                        </div>
                        <h3>
                            <a routerLink="/services-offered"
                                >Microsoft & Dynamics 365
                                <i class="flaticon-right"></i
                            ></a>
                        </h3>
                    </div>
                    <div
                        class="single-data-service-box"
                        routerLink="/services-offered"
                    >
                        <div class="icon">
                            <i class="flaticon-document"></i>
                        </div>
                        <h3>
                            <a routerLink="/services-offered"
                                >Cloud Computing <i class="flaticon-right"></i
                            ></a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="data-service-image">
                    <img src="assets/img/data-service.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="data-services-item">
                    <div
                        class="single-data-service-box"
                        routerLink="/services-offered"
                    >
                        <div class="icon">
                            <i class="flaticon-data-analytics"></i>
                        </div>
                        <h3>
                            <a routerLink="/services-offered"
                                >SharePoint Experts
                                <i class="flaticon-right"></i
                            ></a>
                        </h3>
                    </div>
                    <div
                        class="single-data-service-box"
                        routerLink="/services-offered"
                    >
                        <div class="icon">
                            <i class="flaticon-chart"></i>
                        </div>
                        <h3>
                            <a routerLink="/services-offered"
                                >Software Architects & DevOps<i
                                    class="flaticon-right"
                                ></i
                            ></a>
                        </h3>
                    </div>
                    <div
                        class="single-data-service-box"
                        routerLink="/solution-details/monival"
                    >
                        <div class="icon">
                            <i class="flaticon-data"></i>
                        </div>
                        <h3>
                            <a routerLink="/solution-details/monival"
                                >Monitoring and Evaluation
                                <i class="flaticon-right"></i
                            ></a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-area greyscale-outer">
    <div class="clients">
        <h3 class="mt-2" style="text-align: center; margin-bottom: 20px">
            Our clients
        </h3>
    </div>
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme greyscale">
            <div class="">
                <a
                    ><img
                        src="assets/img/AX-Over 60 Years signature.jpg"
                        alt="image"
                /></a>
            </div>
            <div class="partner-item">
                <a><img src="assets/img/Biovision.png" alt="image" /></a>
            </div>
            <div class="">
                <a><img src="assets/img/concern_logo.png" alt="image" /></a>
            </div>
            <div class="partner-item">
                <a><img src="assets/img/TDB_logo.png" alt="image" /></a>
            </div>
            <div class="">
                <a><img src="assets/img/growthafrica.png" alt="image" /></a>
            </div>
            <div class="">
                <a><img src="assets/img/icdc_logo.png" alt="image" /></a>
            </div>
        </div>
    </div>
</div>
<app-calltoaction></app-calltoaction>

<div
    class="page-title-area item-bg1"
    style="
        background-image: url(assets/img/modern-laptop-and-smartphone-on-black-desk-picjumbo-com.jpg);
    "
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-box pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" style="min-height: inherit">
                <a
                    target="_blank"
                    href="https://www.google.com/maps/place/Sibasi+Ltd/@-1.2197821,36.8717137,17z/data=!3m1!4b1!4m5!3m4!1s0x182f3e19076a52e9:0x145dbe08288f395b!8m2!3d-1.2197821!4d36.8739078"
                >
                    <div class="single-contact-box" style="height: 250px">
                        <i class="fa fa-map-marker"></i>
                        <div class="content-title">
                            <h3>Address</h3>
                            <p>
                                327B, Nyati Drive, Thome, Nairobi, Kenya
                                <br/>
                                P.O. Box 37602 - 00100, GPO, Nairobi, Kenya
                            </p>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-md-6">
                <a target="_blank" href="mailto:info@sibasi.com">
                    <div class="single-contact-box" style="height: 250px">
                        <i class="fa fa-envelope"></i>
                        <div class="content-title">
                            <h3>Email</h3>
                            <a href="mailto:info@sibasi.com">info@sibasi.com</a>
                            <a href="mailto:info@sibasi.com">info@sibasi.ltd</a>
                        </div>
                    </div>
                </a>
            </div>
            <div
                class="col-lg-4 col-md-6 offset-md-3 offset-lg-0"
                style="min-height: inherit"
            >
                <div class="single-contact-box" style="height: 250px">
                    <i class="fa fa-phone"></i>
                    <div class="content-title">
                        <h3>Phone</h3>
                        <a href="tel:+254-780-742274">+254-780-SIBASI</a>
                        <a href="tel:+254-780-742274">+254-780-742274</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="contact-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/img/contact.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <app-contact-form></app-contact-form>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="subscribe-section ptb-100">
    <div class="container">
        <div class="subscribe-content-area mb-0">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-image">
                        <img src="assets/img/subscribe.png" alt="image" />
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-content">
                        <h2>Want to hear from us?</h2>
                        <p>
                            Send us your email and we will keep you posted on
                            the latest offers and updates.
                        </p>
                    </div>
                    <form
                        class="newsletter-form"
                        [formGroup]="subscribeForm"
                        (ngSubmit)="onSubmit()"
                        *ngIf="!showmsg"
                        #subForm="ngForm"
                    >
                        <input
                            type="email"
                            class="input-newsletter"
                            placeholder="Enter your email"
                            name="EMAIL"
                            formControlName="email"
                        />
                        <div
                            *ngIf="
                                (subscribeForm.get('email').hasError('email') &&
                                    subForm.submitted) ||
                                (subscribeForm
                                    .get('email')
                                    .hasError('required') &&
                                    subForm.submitted)
                            "
                            class="mt-1"
                            style="padding: 4px"
                        >
                            <h5
                                style="
                                    color: red;
                                    font-weight: 100;
                                    font-size: 16px;
                                "
                            >
                                Oops, please provide a valid email!
                            </h5>
                        </div>
                        <button type="submit">Send</button>
                    </form>
                    <div
                        *ngIf="showmsg"
                        class="mt-1s"
                        style="padding: 4px"
                        [ngClass]="{
                            'alert-success': msg == 1,
                            'alert-danger': msg == 2
                        }"
                    >
                        <h5>{{ subMessage }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

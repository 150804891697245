import { Component, inject, OnInit } from "@angular/core";
import { MetaTagService } from "../../../services/meta-tag.service";

@Component({
    selector: "app-success",
    templateUrl: "./success.component.html",
    styleUrls: ["./success.component.scss"],
})
export class SuccessComponent implements OnInit {
    private metaTagService = inject(MetaTagService);

    ngOnInit(): void {
        this.updatePageMetaTags();
    }

    updatePageMetaTags(): void {
        this.metaTagService.updateMetaTags(
            "Sibasi Success Stories - Driving Business Excellence",
            "Explore success stories and case studies from Sibasi, showcasing our expertise in delivering transformative technology solutions. Learn how our clients achieved business excellence and growth through our innovative services.",
            "Success stories, case studies, technology solutions, business excellence, growth, Sibasi, client success"
        );
    }
}

import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { ContactService } from "../../../services/contact.service";

@Component({
    selector: "app-subscribe",
    templateUrl: "./subscribe.component.html",
    styleUrls: ["./subscribe.component.scss"],
})
export class SubscribeComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private contactService: ContactService
    ) {}

    subscribeForm: FormGroup;

    ngOnInit(): void {
        //   this.subscribeForm = new FormGroup({
        //     email: new FormControl(null, Validators.required),
        // });
    }

    onSubmit() {
        //console.log(this.subscribeForm.get('email').value)
        // this.contactService.subscribeEmail(this.subscribe.get('email').value).subscribe(
        //   (res)=>{
        //   },
        //   (err)=>{},
        //   ()=>{}
        // )
    }
}
